import { IconLearnTheory } from '@/components/ui/icons/IconLearnTheory';
import { colorTokens } from '@/components/ui/colorTokens';
import { IconSelectUsers } from '@/components/ui/icons/IconSelectUsers';
import { IconGroup } from '@/components/ui/icons/IconGroup';
import { IconCheckCircle } from '@/components/ui/icons/IconCheckCircle';
import { IconFeedback } from '@/components/ui/icons/IconFeedback';
import { EnglishIcon } from '@/components/ui/icons/EnglishIcon';
import { IconVacancies } from '@/components/ui/icons/IconVacancies';
import { LeadType } from '@/controllers/graphql/generated';
import {
  CourseAdvantage,
  ModalStep,
} from '@/components/platform/PlatformConsultation/typedefs';
import { FCImage } from '@/components/ui/IconEmojies/typedefs';
import { IconEmojiCalendar } from '@/components/ui/IconEmojies/IconEmojiCalendar';
import { IconEmojiOneOClock } from '@/components/ui/IconEmojies/IconEmojiOneOClock';

export const NO_ACTIVE_COURSES_TRANSLATE = 'platform_consultation.no_active_ft_courses';

export const CONSULTATION_MODAL_DATA_QA = {
  MODAL: 'modal',
  COURSE_FEATURES: 'course-features',
  COURSE_ADVANTAGES: 'course-advantages',
};

export const FT_FEATURES_TRANSLATES = [
  'platform_consultation.ft_admission',
  'platform_consultation.ft_schedule',
  'platform_consultation.ft_payment',
];

export const FLEX_FEATURES_TRANSLATES = [
  'platform_consultation.flex_format',
  'platform_consultation.flex_schedule',
  'platform_consultation.flex_refund',
];

export const FEATURES_PER_LEAD_PER_STEP: Record<
  LeadType,
  Record<ModalStep, string[]>
> = {
  [LeadType.FtLead]: {
    [ModalStep.MainTrack]: FT_FEATURES_TRANSLATES,
    [ModalStep.AlternativeTrack]: FLEX_FEATURES_TRANSLATES,
  },
  [LeadType.FlexLead]: {
    [ModalStep.MainTrack]: FLEX_FEATURES_TRANSLATES,
    [ModalStep.AlternativeTrack]: FT_FEATURES_TRANSLATES,
  },
};

export const FLEX_COURSE_ADVANTAGES: CourseAdvantage[] = [
  {
    title: 'platform_consultation.advantages.modules',
    icon: IconLearnTheory,
    iconColor: colorTokens['icon-accent-lime'],
  },
  {
    title: 'platform_consultation.advantages.mentors',
    icon: IconSelectUsers,
    iconColor: colorTokens['icon-accent-magenta'],
  },
  {
    title: 'platform_consultation.advantages.community',
    icon: IconGroup,
    iconColor: colorTokens['icon-warning'],
  },
  {
    title: 'platform_consultation.advantages.tech-checks',
    icon: IconCheckCircle,
    iconColor: colorTokens['icon-info'],
  },
  {
    title: 'platform_consultation.advantages.employment',
    icon: IconFeedback,
    iconColor: colorTokens['icon-accent-purple'],
  },
  {
    title: 'platform_consultation.advantages.job-tracker',
    icon: IconVacancies,
    iconColor: colorTokens['icon-accent-teal'],
  },
];

export const FT_COURSE_ADVANTAGES: CourseAdvantage[] = [
  ...FLEX_COURSE_ADVANTAGES,
  {
    title: 'platform_consultation.advantages.english',
    icon: EnglishIcon,
    iconColor: colorTokens['icon-brand'],
  },
];

export const COURSE_ADVANTAGES_PER_STEP: Record<
  LeadType,
  Record<ModalStep, CourseAdvantage[]>
> = {
  [LeadType.FtLead]: {
    [ModalStep.MainTrack]: FT_COURSE_ADVANTAGES,
    [ModalStep.AlternativeTrack]: FLEX_COURSE_ADVANTAGES,
  },
  [LeadType.FlexLead]: {
    [ModalStep.MainTrack]: FLEX_COURSE_ADVANTAGES,
    [ModalStep.AlternativeTrack]: FT_COURSE_ADVANTAGES,
  },
};

export const PLATFORM_CONSULTATION_BUTTONS = {
  requestConsultation: {
    translate: 'platform_consultation.get_consultation_btn',
    dataQa: 'get-consultation-button',
  },
  consultationRequested: {
    translate: 'platform_consultation.consultation_requested_btn',
    dataQa: 'consultation-requested-button',
  },
  checkFt: {
    translate: 'platform_consultation.check_ft_btn',
    dataQa: 'check-ft-button',
  },
  checkFlex: {
    translate: 'platform_consultation.check_flex_btn',
    dataQa: 'check-flex-button',
  },
  backFlex: {
    translate: 'platform_consultation.back_to_flex_btn',
    dataQa: 'back-to-flex-button',
  },
  backFt: {
    translate: 'platform_consultation.back_to_ft_btn',
    dataQa: 'back-to-ft-button',
  },
  switchFt: {
    translate: 'platform_consultation.switch_to_ft_btn',
    dataQa: 'switch-to-ft-button',
  },
  newApplication: {
    translate: 'platform_consultation.new_application_btn',
    dataQa: 'new-application-button',
  },
};

export const FLEX_COURSE_TITLE = 'platform_consultation.join_flex';
export const FT_COURSE_TITLE = 'platform_consultation.join_ft';

export const FT_MODAL_TITLE_PER_STEP: Record<
  LeadType,
  Record<ModalStep, string>
> = {
  [LeadType.FtLead]: {
    [ModalStep.MainTrack]: FT_COURSE_TITLE,
    [ModalStep.AlternativeTrack]: FLEX_COURSE_TITLE,
  },
  [LeadType.FlexLead]: {
    [ModalStep.MainTrack]: FLEX_COURSE_TITLE,
    [ModalStep.AlternativeTrack]: FT_COURSE_TITLE,
  },
};

export const MODAL_TITLE_ICON_PER_STEP: Record<
  LeadType,
  Record<ModalStep, FCImage>
> = {
  [LeadType.FtLead]: {
    [ModalStep.MainTrack]: IconEmojiCalendar,
    [ModalStep.AlternativeTrack]: IconEmojiOneOClock,
  },
  [LeadType.FlexLead]: {
    [ModalStep.MainTrack]: IconEmojiOneOClock,
    [ModalStep.AlternativeTrack]: IconEmojiCalendar,
  },
};
