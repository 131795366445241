export const ALLOWED_MARKDOWN_ELEMENTS = [
  'p',
  'span',
  'div',
  'pre',
  'code',
  'mark',
  'details',
  'summary',
  'a',
  'img',
  'ul',
  'ol',
  'li',
  'table',
  'thead',
  'tbody',
  'tfoot',
  'tr',
  'th',
  'td',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  's',
  'em',
  'b',
  'stong',
  'svg',
  'ins',
  'del',
  'sub',
  'sup',
  'hr',
  'blockquote',
  'dl',
  'dt',
  'dd',
  'strong',
  'small',
  'samp',
  'kbd',
  'var',
  'cite',
  'q',
  'abbr',
  'address',
  'i',
  'figure',
  'figcaption',
  'caption',
  'br',
];

export const ALLOWED_MARKDOWN_ELEMENTS_WITH_IFRAME = [...ALLOWED_MARKDOWN_ELEMENTS, 'iframe'];

export const CODE_BLOCKS_REGEX = /```[\s\S]*?```/g;
export const HEADINGS_WITH_HASH_REGEX = /^#+ .+$/gm;
export const TITLE_AS_LINK_REGEX = /\[(.*?)\]\(http[^)]+\)/;
export const EMPHASIZED_TEXT_REGEX = /`([^`\n]+)`/g;
export const ITALIC_TEXT_REGEX = /^\*{1,3}([^\s*][^*]*[^\s*])\*{1,3}$/;
