import React, { FC, useMemo } from 'react';
import { IconCamera } from '@/components/ui/icons/IconCamera';
import { MESSAGE_TYPES, MESSAGES } from '@/lib/constants/messages';
import { Loader } from '@/components/ui/Loader';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { AuthUserDocument } from '@/controllers/user/graphql/generated/authUser.query.generated';
import { emptyFunction } from '@/lib/helpers/functional';
import { useUpdateAvatarMutation } from '@/controllers/user/graphql/generated/updateAvatar.mutation.generated';
import styles from './UpdateAvatarButton.module.scss';

const validateFile = (file: File) => file.size <= 1024 * 1024 * 2
  && ['image/x-png', 'image/png', 'image/jpeg'].includes(file.type);

interface Props {
  avatarSize?: number;
  onCompleted?: () => void;
}

export const UpdateAvatarButton: FC<Props> = ({
  avatarSize = 32,
  onCompleted = emptyFunction,
}) => {
  const { showMessage } = useFlashMessage();
  const { t } = useTranslation([I18N_CODES.validates]);

  const showError = () => {
    showMessage({
      type: MESSAGE_TYPES.error,
      heading: 'Whoops!!!',
      text: t(`${I18N_CODES.validates}:validate_file`, {
        extensions: '.jpg, .jpeg, .png',
        size: '2mb',
      }),
    });
  };

  const [updateAvatar, { loading }] = useUpdateAvatarMutation({
    onCompleted: () => {
      showMessage(MESSAGES.profile.avatarChanged);
      onCompleted();
    },
    onError: () => {
      showError();
    },
  });

  const { x, y } = useMemo(() => {
    const r = avatarSize / 2;

    return {
      x: r * Math.cos(45 * (Math.PI / 180)),
      y: r * Math.sin(45 * (Math.PI / 180)),
    };
  }, [avatarSize]);

  return (
    <div>
      <label className={styles.photoIconWrapper} style={{ '--x': `${x}px`, '--y': `${y}px` } as React.CSSProperties} data-qa='update-user-avatar-button'>
        <IconCamera className={styles.photoIcon} />

        <input
          type="file"
          required
          accept="image/x-png, image/jpeg"
          className={styles.fileInput}
          onChange={async ({ target: { validity, files } }) => {
            const file = files?.[0];

            if (!file) {
              return;
            }

            if (validity.valid && validateFile(file)) {
              await updateAvatar({
                variables: {
                  file,
                },
                refetchQueries: [
                  { query: AuthUserDocument },
                ],
                awaitRefetchQueries: true,
              });
            } else {
              showError();
            }
          }}
        />
      </label>
      <Loader
        loading={loading}
        size={avatarSize / 4}
        className={styles.loader}
      />
    </div>
  );
};
