import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconCareer: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M21 20H12V15H21V20Z" stroke="currentColor" strokeWidth="2" />
    <path d="M15 12H14V13V14V15H15H18H19V14V13V12H18H15Z" stroke="currentColor" strokeWidth="2" />
    <circle cx="10" cy="8" r="3" stroke="currentColor" strokeWidth="2" />
    <path d="M3 21C3 17.134 6.13401 14 10 14" stroke="currentColor" strokeWidth="2" />
  </BaseIcon>
);
