import React, { FC } from 'react';
import { ControlsDescription } from '@/components/platform/GlobalSearch/components/BottomBar/ControlsDescription';
import { useKeyboardControls } from '@/components/platform/GlobalSearch/hooks/useKeyboardControls';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import styles from './BottomBar.module.scss';

interface Props {
  totalCount?: number;
}

export const BottomBar: FC<Props> = ({ totalCount }) => {
  const { t } = useTranslation([I18N_CODES.globalSearch]);

  useKeyboardControls();

  return (
    <section className={styles.bottomBar}>
      {Boolean(totalCount) && (
        <p className={styles.totalCountLine}>
          <span className={styles.totalCountNumber}>
            {totalCount}
          </span>

          {t(`${I18N_CODES.globalSearch}:total_matches_count`, {
            count: totalCount,
          })}
        </p>
      )}

      <div className={styles.descriptionWrapper}>
        <ControlsDescription />
      </div>
    </section>
  );
};
