import { useCallback, useMemo } from 'react';
import { NotificationsIndicator } from '@/components/common/NotificationsIndications/helpers/NotificationsIndicator';
import { useChatUnreadsCounterData } from '@/components/platform/Chat/hooks/useChatUnreadsCounterData';

export const useNotificationsIndicator = () => {
  const {
    notificationsCount,
    hasAnyUnreadMessages,
  } = useChatUnreadsCounterData();

  const notificationsIndicator = useMemo(() => (
    new NotificationsIndicator()
  ), []);

  const updateFavicon = useCallback(() => {
    notificationsIndicator.updateFavicon({
      numberToDisplay: notificationsCount,
      hasAnyNotifications: hasAnyUnreadMessages,
    });
  }, [hasAnyUnreadMessages, notificationsCount, notificationsIndicator]);

  const hasTitleIndication = notificationsCount > 0 || hasAnyUnreadMessages;

  const updateTitle = useCallback(() => {
    notificationsIndicator.updateTitle(
      hasTitleIndication,
    );
  }, [hasTitleIndication, notificationsIndicator]);

  return { updateFavicon, updateTitle };
};
