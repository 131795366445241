import React, { FC, memo } from 'react';
import { ProfileDropdownActionItemI } from '@/components/platform/ProfileDropdown/typedefs';
import { cn } from '@/lib';
import {
  ProfileDropdownItemWrapper,
} from '@/components/platform/ProfileDropdown/ProfileDropdownItem/components/ProfileDropdownItemWrapper';
import styles from '../ProfileDropdownItem.module.scss';

type Props = {
  item: ProfileDropdownActionItemI;
};

export const ProfileDropdownActionItem: FC<Props> = memo(
  ({ item }) => {
    const {
      onAction,
      icon,
      text,
      isHiddenOnMobile,
      dataQA,
      iconOnHover,
    } = item;

    return (
      <ProfileDropdownItemWrapper
        dataQA={dataQA}
        isHiddenOnMobile={isHiddenOnMobile}
      >
        <button
          title={text}
          onClick={onAction}
          className={cn(styles.itemWrapper, styles.itemButton)}
        >
          <span className={styles.itemIcon}>
            {icon}
          </span>

          <span className={styles.itemText}>
            {text}
          </span>

          {iconOnHover && (
            <span className={styles.itemIconOnHover}>
              {iconOnHover}
            </span>
          )}
        </button>
      </ProfileDropdownItemWrapper>
    );
  },
);
