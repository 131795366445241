import { useCallback } from 'react';
import { useSearchChatsLazyQuery } from '@/components/platform/GlobalSearch/graphql/generated/searchChats.query.generated';
import { SearchableChatFragment } from '@/components/platform/GlobalSearch/graphql/generated/searchableChat.fragment.generated';

export const useChatFilterOptions = () => {
  const [loadChats, { data, loading }] = useSearchChatsLazyQuery();

  const getChatOptions = useCallback((inputValue = '') => {
    loadChats({ variables: { query: inputValue } });
  }, [loadChats]);

  const chatOptions = data?.searchByQuery.matches
    .filter((match) => match.entity.__typename === 'SearchableChat')
    .map((match) => ({
      value: String((match.entity as SearchableChatFragment).chat.id),
      label: (match.entity as SearchableChatFragment).chat.name,
    }));

  return {
    getChatOptions,
    chatOptions,
    loading,
  };
};
