import React, { memo, FC } from 'react';
import { useSidebarState } from '@/controllers/apollo/apollo.modules/sidebar/sidebar.hooks';
import styles from '@/components/platform/Header/components/MobileSidebarToggleButton/MobileSidebarToggleButton.module.scss';
import { SidebarToggleComponent } from '@/components/platform/SidebarHeader/components';
import { CloseMobileViewButton } from '@/components/platform/Header/components/CloseMobileViewButton/CloseMobileViewButton';

interface Props {
  isBurgerVisible: boolean;
  shouldRenderBackButton: boolean;
}

export const MobileSidebarToggleButton: FC<Props> = memo(({
  isBurgerVisible,
  shouldRenderBackButton,
}) => {
  const [{ sidebarOpen }, toggleSidebar] = useSidebarState();

  return (
    <div className={styles.toggleWrapper} data-qa="mobile-sidebar-toggle-button">
      {shouldRenderBackButton
        ? <CloseMobileViewButton />
        : (
          <SidebarToggleComponent
            isBurgerVisible={isBurgerVisible}
            sidebarOpen={sidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        )}
    </div>
  );
});
