import { useLocalStorage } from '@/hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';

export const usePlatformConsultationRequestedAt = () => {
  const [
    platformConsultationRequestedAt,
    setPlatformConsultationRequestedAt,
  ] = useLocalStorage<number | null>(
    LOCAL_STORAGE_KEYS.limitedAccessConsultationRequestedAt,
    null,
  );

  return {
    platformConsultationRequestedAt,
    setPlatformConsultationRequestedAt,
  };
};
