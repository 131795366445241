import { useReadAllUserNotificationsMutation } from '@/components/platform/Notifications/graphql/generated/ReadAllAuthUserNotifications.mutation.generated';
import { MESSAGE_TYPES } from '@/lib/constants/messages';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';

type UseReadAllNotificationsResult = {
  readAllUserNotifications: () => void;
  readAllNotificationsLoading: boolean;
};

export const useReadAllNotifications = (): UseReadAllNotificationsResult => {
  const { t } = useTranslation([I18N_CODES.platformNotifications]);
  const flashMessage = useFlashMessage();

  const [
    readAllUserNotifications,
    { loading: readAllNotificationsLoading },
  ] = useReadAllUserNotificationsMutation({
    onError: () => flashMessage.showMessage({
      type: MESSAGE_TYPES.error,
      heading: t((`${I18N_CODES.platformNotifications}:read_all_notifications_error_heading`)),
      text: t((`${I18N_CODES.platformNotifications}:read_all_notifications_error_text`)),
    }),
  });

  return {
    readAllUserNotifications,
    readAllNotificationsLoading,
  };
};
