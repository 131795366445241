import React, {
  FC, memo, useCallback, useMemo,
} from 'react';
import { useRouter } from '@/middleware/i18n';
import { GlobalSearchMatchFragment } from '@/components/platform/GlobalSearch/graphql/generated/globalSearchMatch.fragment.generated';
import { SearchResultWrapper } from '@/components/platform/GlobalSearch/components/SearchableEntities/SearchResultWrapper';
import { SearchableChatFragment } from '@/components/platform/GlobalSearch/graphql/generated/searchableChat.fragment.generated';
import { IconChat } from '@/components/ui/icons/IconChat';
import { SearchableEntityTitleMarkdown } from '@/components/platform/GlobalSearch/components/SearchableEntities/SearchableEntityTitleMarkdown';
import { ROUTES } from '@/controllers/router/router.contants';
import { GlobalSearchEvents } from '@/components/platform/GlobalSearch/analytics/globalSearch.events';
import { SearchableEntityType } from '@/controllers/graphql/generated';
import styles from './SearchableChat.module.scss';

interface Props {
  matchedChat: GlobalSearchMatchFragment;
}

export const SearchableChat: FC<Props> = memo(({
  matchedChat,
}) => {
  const router = useRouter();

  const { chat } = (matchedChat.entity as SearchableChatFragment);

  const name = useMemo(() => {
    if (matchedChat.highlights.title?.[0]) {
      return matchedChat.highlights.title[0];
    }

    return chat.name;
  }, [matchedChat.highlights.title, chat.name]);

  const onClick = useCallback(() => {
    GlobalSearchEvents.sendEvent(
      GlobalSearchEvents.events.searchResultClicked,
      { searchableType: SearchableEntityType.Chat },
    );

    router.push(ROUTES.chat(chat.id).index);
  }, [chat.id, router]);

  return (
    <SearchResultWrapper onClick={onClick}>
      <div className={styles.titleWrapper}>
        <IconChat className={styles.icon} />

        <SearchableEntityTitleMarkdown title={name} />
      </div>
    </SearchResultWrapper>
  );
});
