import React, { FC, memo } from 'react';
import { IconChevronUp } from '@/components/ui/icons/IconChevronUp';
import { IconChevronDown } from '@/components/ui/icons/IconChevronDown';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import styles from './ControlsDescription.module.scss';

export const ControlsDescription: FC = memo(() => {
  const { t } = useTranslation([I18N_CODES.globalSearch]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.controlsSection}>
        <div className={styles.arrows}>
          <IconChevronDown className={styles.icon} />

          <IconChevronUp className={styles.icon} />
        </div>

        <span>
          {t(`${I18N_CODES.globalSearch}:controls_description.or`)}
        </span>

        <div className={styles.button}>
          Tab
        </div>

        <span>
          {t(`${I18N_CODES.globalSearch}:controls_description.navigate`)}
        </span>
      </div>

      <div className={styles.controlsSection}>
        <div className={styles.button}>
          Return
        </div>

        <span>
          {t(`${I18N_CODES.globalSearch}:controls_description.select`)}
        </span>
      </div>
    </div>
  );
});
