import { useEffect } from 'react';
import { KeyboardKey } from '@/constants/keyboard';
import { last } from '@/lib/helpers/array';

const findNextElement = (
  elements: HTMLButtonElement[],
  currentIndex: number | null,
): HTMLButtonElement | null => {
  if (currentIndex === null) {
    return elements[0] || null;
  }

  if (currentIndex + 1 >= elements.length) {
    return last(elements) ?? null;
  }

  return elements[currentIndex + 1] ?? null;
};

const findPreviousElement = (
  elements: HTMLButtonElement[],
  currentIndex: number | null,
): HTMLButtonElement | null => {
  if (currentIndex === null) {
    return elements[elements.length - 1] || null;
  }

  if (currentIndex - 1 < 0) {
    return elements[0] ?? null;
  }

  return elements[currentIndex - 1] ?? null;
};

export const useKeyboardControls = () => {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      const elements = Array.from(document.querySelectorAll<HTMLButtonElement>('button[data-focusable]'));

      if (!elements.length) {
        return;
      }

      const focusedElement = document.activeElement;

      const focusedElementIndex = focusedElement
        ? elements.findIndex((element) => element === focusedElement)
        : null;

      if (event.key === KeyboardKey.ArrowUp) {
        const previousElement = findPreviousElement(
          elements,
          focusedElementIndex,
        );

        if (previousElement) {
          previousElement.focus();
        }
      }

      if (event.key === KeyboardKey.ArrowDown) {
        const nextElement = findNextElement(
          elements,
          focusedElementIndex,
        );

        if (nextElement) {
          nextElement.focus();
        }
      }
    };

    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, []);
};
