import { useState, useEffect, useCallback } from 'react';

export function useDebounce<T>(
  value: T,
  delay: number,
): [T | null, () => void] {
  const [debouncedValue, setDebouncedValue] = useState<T | null>(value);

  const clearValue = useCallback(() => {
    setDebouncedValue(null);
  }, []);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay],
  );

  return [debouncedValue, clearValue];
}
