import React, { FC } from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { typography } from '@/components/ui/typography';
import { UserStreakBaseFragment } from '@/components/platform/Streaks/graphql/generated/UserStreaks.base.fragment.generated';
import styles from './ModalHeader.module.scss';

interface Props {
  activeStreak?: UserStreakBaseFragment | null;
}

export const ModalHeader: FC<Props> = ({
  activeStreak,
}) => {
  const { t } = useTranslation([I18N_CODES.common]);
  const headerDataQa = activeStreak
    ? `streak_count_header-${activeStreak?.activeDaysCount}`
    : 'no_streak_header';

  return (
    <div className={styles.container}>
      <span
        className={typography.platformH2}
        data-qa={headerDataQa}
        dangerouslySetInnerHTML={{
          __html: activeStreak
            ? t(`${I18N_CODES.common}:streak_count_header`, { count: activeStreak?.activeDaysCount })
            : t(`${I18N_CODES.common}:no_streak_header`),
        }}
      />

      <div
        className={typography.platformTextSmall}
        data-qa="streak_count_description"
      >
        {activeStreak
          ? t(`${I18N_CODES.common}:streak_count_description`)
          : t(`${I18N_CODES.common}:no_streak_description`)}
      </div>
    </div>
  );
};
