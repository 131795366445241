import React, { memo, FC } from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { IconCheck } from '@/components/ui/icons/IconCheck';
import { CONSULTATION_MODAL_DATA_QA } from '@/components/platform/PlatformConsultation/constants';
import styles from './TrackDescription.module.scss';

type Props = {
  trackFeatures: string[];
};

export const TrackDescription: FC<Props> = memo((props) => {
  const {
    trackFeatures,
  } = props;
  const { t } = useTranslation([I18N_CODES.common]);

  return (
    <section data-qa='track-description-section'>
      <h2 className={cn(typography.platformH2, 'mb-12')}>
        {t(`${I18N_CODES.common}:platform_consultation.peculiarities.title`)}
      </h2>

      <ul data-qa={CONSULTATION_MODAL_DATA_QA.COURSE_FEATURES}>
        {trackFeatures.map((feature) => (
          <li key={feature} className={styles.item}>
            <IconCheck />

            <span>{t(`${I18N_CODES.common}:${feature}`)}</span>
          </li>
        ))}
      </ul>
    </section>
  );
});
