import React, {
  AnchorHTMLAttributes,
  FC,
  memo,
  useCallback,
  useMemo,
} from 'react';
import { cn } from '@/lib/classNames';
import { GroupMentions } from '@/components/ui/ChatInput/ParticipantMention/ParticipantMention.typedefs';
import { EditableMateMarkdown } from '@/components/platform/Markdown';
import { useGetMentionedParticipantName } from '@/components/platform/Chat/components/ChatMarkdown/hooks/useGetMentionedParticipantName';
import { extractParticipantId } from '@/components/ui/ChatInput/InputField/InputField.utils/extractMentions';
import { CopyAsHTML } from '@/components/platform/Chat/components/CopyAsHTML';
import { processRTETextToChatMarkdown } from '@/components/ui/ChatInput/ChatInput.helpers';
import { useParticipantsContext } from '@/components/platform/Chat/contexts/ParticipantsContext';
import styles from './ChatMarkdown.module.scss';

interface Props {
  text: string;
  shouldHighlightMentions?: boolean;
  withHTML?: boolean;
}

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

export const ChatMarkdown: FC<Props> = memo(({
  text,
  shouldHighlightMentions = true,
  withHTML = false,
}) => {
  const { participant, onMentionClick } = useParticipantsContext();

  const getMentionedParticipantName = useGetMentionedParticipantName(text);
  const processText = useMemo(() => processRTETextToChatMarkdown(text), [text]);

  const AnchorComponent = useCallback(
    ({ children, href }: AnchorProps): JSX.Element => {
      const participantId = extractParticipantId(href);

      const isMention = Boolean(participantId);
      const isOwnMention = participantId === GroupMentions.All
        || Number(participantId) === participant?.id;
      const highlightMentions = (
        isMention && !isOwnMention && shouldHighlightMentions
      );
      const highlightOwnMentions = (
        isOwnMention && shouldHighlightMentions
      );
      const dataQa = isOwnMention
        ? 'own-mention-link'
        : 'mention-link';

      return (
        <a
          href={href}
          target={href?.includes('http')
            ? '_blank'
            : '_self'}
          rel="noreferrer"
          onClick={onMentionClick(isMention, participantId)}
          className={cn(
            { [styles.mention]: highlightMentions },
            { [styles.ownMention]: highlightOwnMentions },
          )}
          data-qa={dataQa}
        >
          {isMention
            ? getMentionedParticipantName(participantId)
            : children}
        </a>
      );
    },
    [
      getMentionedParticipantName,
      onMentionClick,
      participant,
      shouldHighlightMentions,
    ],
  );

  return (
    <CopyAsHTML>
      <EditableMateMarkdown
        source={processText}
        className={styles.chatMarkdown}
        skipHtml={!withHTML}
        components={{
          a: AnchorComponent,
        }}
      />
    </CopyAsHTML>
  );
});
