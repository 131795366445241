import flow from 'lodash/flow';
import { uriTransformer } from 'react-markdown';
import {
  EMPHASIZED_TEXT_REGEX,
  ITALIC_TEXT_REGEX,
  TITLE_AS_LINK_REGEX,
} from '@/components/platform/Markdown/Markdown.constants';
import { rawImageLoader } from '@/components/ui/Image/utils/imageLoader';

export const transformImageUri = (uri: string): string => (
  uriTransformer(rawImageLoader({ src: uri }))
);
export const hasTextAsLink = (text: string) => TITLE_AS_LINK_REGEX.test(text);
export const hasEmphasized = (text: string) => EMPHASIZED_TEXT_REGEX.test(text);
export const hasItalic = (text: string) => ITALIC_TEXT_REGEX.test(text);

export const replaceTextAsLink = (text: string):string => (
  hasTextAsLink(text)
    ? text.replace(TITLE_AS_LINK_REGEX, '$1')
    : text
);
export const replaceEmphasizedText = (text: string): string => (
  hasEmphasized(text)
    ? text.replace(EMPHASIZED_TEXT_REGEX, '$1')
    : text
);
export const replaceItalicText = (text: string): string => (
  hasItalic(text)
    ? text.replace(ITALIC_TEXT_REGEX, '$1')
    : text
);

export const formatTitleForMarkdown = (title: string): string => {
  const formatText = flow([
    replaceTextAsLink,
    replaceEmphasizedText,
    replaceItalicText,
  ]);

  return formatText(title);
};
