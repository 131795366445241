export enum Permission {
  Impersonate = 'IMPERSONATE_LOGIN',
  ReceiveTaskReview = 'TASK_REVIEW::RECEIVE',
  PerformTaskReview = 'TASK_REVIEW::PERFORM',
  StudentsGroups = 'STUDENTS_GROUPS_ACCESS',
  CourseUsers = 'COURSE_USERS_ACCESS',
  CourseUsersTopic = 'COURSE_USER_TOPIC_ACCESS',
  EnglishDashboardUsers = 'ENGLISH_DASHBOARD_USERS_ACCESS',
  ViewTaskMentorSolution = 'TASK_MENTOR_SOLUTION_ACCESS',
  None = '*',
  CorporateEventsRead = 'CORPORATE_EVENTS::READ',
  TestTaskAttemptsRead = 'TEST_TASK_ATTEMPTS::READ',
  CourseUserTopicsRead = 'COURSE_USER_TOPICS::READ',
  CourseUserTopicsWrite = 'COURSE_USER_TOPICS::WRITE',
  TestTaskLinksWrite = 'TEST_TASK_LINKS::WRITE',
  GroupEventWrite = 'GROUP_EVENT::WRITE',
  GroupEventRead = 'GROUP_EVENT::READ',
  JoinScheduleEventsIgnoringStudentStatus = 'JOIN_SCHEDULE_EVENT::ACCESS',
  TechCheckEventWrite = 'TECH_CHECK_EVENT::WRITE',
  CalendarEventSkeletonWrite = 'COURSE_EVENT_SKELETON::WRITE',
  CalendarEventSkeletonRead = 'COURSE_EVENT_SKELETON::READ',
  TasksRead = 'TASKS::READ',
  CourseTopicTasksRead = 'COURSE_TOPIC_TASKS::READ',
  EventsCreate = 'EVENTS::CREATE',
  TechCheckRoomAdmin = 'TECH_CHECK_ROOM::ADMIN',
  AllSkeletonsRead = 'ALL_SKELETONS::READ',
  RemoveSkeleton = 'EVENT_SKELETON::REMOVE',
  UpdateSkeleton = 'EVENT_SKELETON::UPDATE',
  ReadPermissions = 'PERMISSIONS::READ',
  UpdatePermissions = 'PERMISSIONS::WRITE',
  ManageServiceUsers = 'SERVICE_USER::WRITE',
  UpdateUserById = 'UPDATE_USER_BY_ID::UPDATE',
  SendMessageBusEvents = 'MESSAGE_BUS_EVENTS::PUT',
  BirdEnglishLesson = 'BIRD_ENGLISH_LESSON_ACCESS',
  TurtleEnglishLesson = 'TURTLE_ENGLISH_LESSON_ACCESS',
  FishEnglishLesson = 'FISH_ENGLISH_LESSON_ACCESS',
  CrabEnglishLesson = 'CRAB_ENGLISH_LESSON_ACCESS',
  AllTechCheckEventsAttend = 'ALL_TECH_CHECK_EVENTS::ATTEND',
  CourseProgressBasedTechCheckEventsAttend = 'COURSE_PROGRESS_BASED_TECH_CHECK_EVENTS::ATTEND',
  TestTasksRead = 'TEST_TASKS::READ',
  CourseTopicsRead = 'COURSE_TOPICS::READ',
  UserUpdate = 'USER::WRITE',
  UserRead = 'USER::READ',
  ChatRead = 'CHAT::READ',
  ChatMessagesRead = 'CHAT_MESSAGES::READ',
  ChatGuidelineMessageRead = 'CHAT_GUIDELINE_MESSAGE::READ',
  ChatGuidelineMessageWrite = 'CHAT_GUIDELINE_MESSAGE:WRITE',
  ChatGuidelineRead = 'CHAT_GUIDELINE::READ',
  ChatGuidelineWrite = 'CHAT_GUIDELINE::WRITE',
  TechCheckQuestionsWrite = 'TECH_CHECK_QUESTIONS::WRITE',
  TechCheckQuestionsAsk = 'TECH_CHECK_QUESTIONS::ASK',
  TechCheckTopicsRead = 'TECH_CHECK_TOPICS::READ',
  TechCheckTopicsWrite = 'TECH_CHECK_TOPICS::WRITE',
  TechCheckQuestionCheckphrasesWrite = 'TECH_CHECK_QUESTION_CHECKPHRASES::WRITE',
  StudyModule = 'MODULE::STUDY',
  TestTaskWrite = 'TEST_TASK::WRITE',
  TechCheckSessionsRead = 'TECH_CHECK_SESSIONS::READ',
  TechCheckSessionsWrite = 'TECH_CHECK_SESSIONS::WRITE',
  SubscriptionPurchaseLinkRead = 'SUBSCRIPTION_PURCHASE_LINK::READ',
  SubscriptionPurchaseLinkWrite = 'SUBSCRIPTION_PURCHASE_LINK::WRITE',
  SubscriptionUpdate = 'SUBSCRIPTION::WRITE',
  GrantedSubscriptionRead = 'CREATE_GRANTED_SUBSCRIPTION::READ',
  GrantedSubscriptionWrite = 'CREATE_GRANTED_SUBSCRIPTION::WRITE',
  TechCheckTemplatesRead = 'TECH_CHECK_TEMPLATES::READ',
  TechCheckTemplatesWrite = 'TECH_CHECK_TEMPLATES::WRITE',
  BankCurrencyRateWrite = 'BANK_CURRENCY_RATE::WRITE',
  LoanTransactionsRead = 'LOAN_TRANSACTIONS::READ',
  LoanTransactionsUpdate = 'LOAN_TRANSACTIONS::UPDATE',
  ViewPrivateCompaniesField = 'VIEW_PRIVATE_COMPANIES_FIELDS::READ',
  UpdatePrivateCompaniesField = 'UPDATE_PRIVATE_COMPANIES_FIELDS::WRITE',
  CourseAchievementsWrite = 'COURSE_ACHIEVEMENTS::WRITE',
  UpdateQnAQuestionStatus = 'QnA_QUESTION_STATUS::WRITE',
  DeleteQnAQuestions = 'QnA_QUESTIONS::WRITE',
  EventLocationRead = 'EVENT_LOCATION::READ',
  LearnVideoWrite = 'LEARN_VIDEOS::WRITE',
  LearnVideoRead = 'LEARN_VIDEOS::READ',
  AdminTools = 'ADMIN_TOOLS::USE',
  GroupDeadlineRead = 'GROUP_DEADLINE::READ',
  GroupDeadlineWrite = 'GROUP_DEADLINE::WRITE',
  DeleteAccountActionsRead = 'DELETE_ACCOUNT_ACTIONS::READ',
  DeleteAccountActionsWrite = 'DELETE_ACCOUNT_ACTIONS::WRITE',
  AutoTechCheckRead = 'AUTO_TECH_CHECK::READ',
  AutoTechCheckWrite = 'AUTO_TECH_CHECK::WRITE',
  AutoTechCheckAnswer = 'AUTO_TECH_CHECK::ANSWER',
  AutoTechCheckReview = 'AUTO_TECH_CHECK::REVIEW',
  ReParseVacancies = 'RE_PARSE_VACANCIES::WRITE',
  TestTaskPreviewsRead = 'TEST_TASK_PREVIEWS::READ',
  TestTaskPreviewsWrite = 'TEST_TASK_PREVIEWS::WRITE',
  NotificationsWrite = 'NOTIFICATIONS::WRITE',
  VacanciesWrite = 'VACANCIES::WRITE',
  VacanciesUse = 'VACANCIES::USE',
  ProfessionsViewAccess = 'PROFESSIONS::VIEW',
  ContentChangesRevert = 'CONTENT_CHANGES::REVERT',
  ProfessionWrite = 'PROFESSION::WRITE',
  CourseWrite = 'COURSE::WRITE',
  TopicWrite = 'TOPIC::WRITE',
  CalendarTemplateWrite = 'CALENDAR_TEMPLATE::WRITE',
  SandboxWrite = 'SANDBOX::WRITE',
  ChatWrite = 'CHAT::WRITE',
  Timetracker = 'TIMETRACKER::USE',
  PersonalDataRead = 'PERSONAL_DATA::READ',
  DailyTasksRead = 'DAILY_TASKS::READ',
  GroupDashboardView = 'GROUP_DASHBOARD::VIEW',
  StudentsGroupsManage = 'STUDENTS_GROUPS::MANAGE',
  ContractorsRateRead = 'CONTRACTORS_RATE::READ',
  ContractorsRead = 'CONTRACTORS::READ',
  ContractorsWrite = 'CONTRACTORS::WRITE',
  TestLinearLearningFlow = 'LINEAR_FLOW::TEST',
  ReviewPluginHomeworkDirectLinkAccess = 'REVIEW_PLUGIN_HOMEWORK_DIRECT_LINK::ACCESS',
  EnglishCourse = 'ENGLISH_ACCESS',
  ChatMessagesWrite = 'CHAT_MESSAGE::WRITE',
  PaymentRefundWrite = 'PAYMENT_REFUND::WRITE',
}
