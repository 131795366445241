import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export enum TitleBlockHarnessSelectors {
  DataQa = 'achievements-block_title-block',
  Title = 'achievements-block_title-block_title',
  ShowAllButton = 'achievements-block_title-block_show-all-button',
}

export class TitleBlockHarness extends ComponentHarness {
  dataQa = TitleBlockHarnessSelectors.DataQa;

  get title() {
    return this.getElementByDataQa(TitleBlockHarnessSelectors.Title);
  }

  get showAllButton() {
    return this.getElementByDataQa(TitleBlockHarnessSelectors.ShowAllButton);
  }

  assertRendered() {
    this.assertExists();

    this.title
      .should('be.visible')
      .and('have.text', 'achievements_block_title');

    this.showAllButton
      .should('be.visible')
      .and('have.text', 'achievements_block_link');
  }

  clickShowAllButton() {
    this.showAllButton.click();
  }
}
