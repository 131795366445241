import React, {
  FC, memo,
} from 'react';
import { typography } from '@/components/ui/typography';
import { cn } from '@/lib/classNames';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { OnClick } from '@/components/ui/OnClick/OnClick';
import styles from './ReportProblemCard.module.scss';

interface Props {
  title: string;
  onClick: () => void;
  LeftIcon: FCIcon;
  RightIcon?: FCIcon;
  subtitle?: string;
  className?: string;
  renderRightButton?: () => JSX.Element;
  dataQa?: string;
}

export const ReportProblemCard: FC<Props> = memo(({
  title,
  onClick,
  LeftIcon,
  RightIcon,
  subtitle,
  className,
  renderRightButton,
  dataQa = 'report-problem-card',
}) => (
  <OnClick
    onClick={onClick}
  >
    <div className={cn(styles.card, className)} data-qa={dataQa}>
      <div className={styles.container}>
        <div className={styles.iconContainer}>
          {LeftIcon && <LeftIcon className={styles.icon} />}
        </div>

        <div className={styles.textContainer}>
          <div className={styles.titleContainer}>
            <h4 className={typography.platformH4}>
              {title}
            </h4>
          </div>

          {subtitle && (
            <p className={cn(
              typography.platformTextSecondary,
              styles.subtitle,
            )}
            >
              {subtitle}
            </p>
          )}
        </div>
      </div>

      {renderRightButton && renderRightButton()}

      {(!renderRightButton && RightIcon) && (
        <RightIcon className={styles.icon} />
      )}
    </div>
  </OnClick>
));
