import { useLastUnreadUserNotificationQuery } from '@/components/platform/Notifications/graphql/generated/LastUnreadUserNotification.query.generated';
import { useNewNotification } from '@/components/platform/Notifications/hooks/useNewNotification';
import { useNotificationsUpdated } from '@/components/platform/Notifications/hooks/useNotificationsUpdated';

export const useLastUnreadNotification = () => {
  const { data, loading } = useLastUnreadUserNotificationQuery();

  const unreadNotificationsCount = data?.authUser?.notifications
    .unreadCount ?? 0;
  const lastUnreadNotification = unreadNotificationsCount > 0
    ? data?.authUser?.notifications.edges[0]
    : null;

  useNewNotification();
  useNotificationsUpdated();

  return {
    unreadNotificationsCount,
    lastUnreadNotification,
    loading,
  };
};
