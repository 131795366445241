import { atomDark, vs } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { usePlatformTheme } from '@/components/common/ThemeContext';
import {
  useIsFeaturesEnabled,
} from '@/controllers/features/features.hooks/useIsFeaturesEnabled';
import { Features } from '@/controllers/features/features.constants';
import { ColorTheme } from '@/components/common/ThemeContext/typedefs';
import {
  markdownMateThemeDark,
} from '@/components/platform/Markdown/theme/markdownMateDarkTheme';
import {
  markdownMateTheme,
} from '@/components/platform/Markdown/theme/markdownMateTheme';

const getVsCodeTheme = (theme: ColorTheme) => {
  switch (theme) {
    case ColorTheme.Dark:
    case ColorTheme.KnowelyDark:
      return atomDark;
    case ColorTheme.Light:
    case ColorTheme.KnowelyLight:
    default:
      return vs;
  }
};

const getMateTheme = (theme: ColorTheme) => {
  switch (theme) {
    case ColorTheme.Dark:
    case ColorTheme.KnowelyDark:
      return markdownMateThemeDark;
    case ColorTheme.Light:
    case ColorTheme.KnowelyLight:
    default:
      return markdownMateTheme;
  }
};

export const useMarkdownTheme = () => {
  const { colorTheme } = usePlatformTheme();
  const isMateThemeEnabled = useIsFeaturesEnabled(Features.MarkdownMateTheme);

  const themeGetter = isMateThemeEnabled
    ? getMateTheme
    : getVsCodeTheme;

  return themeGetter(colorTheme);
};
