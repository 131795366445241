import React, { FC, useEffect } from 'react';
import { cn } from '@/lib';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { typography } from '@/components/ui/typography';
import { IconFireBest } from '@/components/ui/icons/IconFireBest';
import { useBestUserStreakLazyQuery } from '@/components/platform/Streaks/graphql/generated/bestUserStreak.query.generated';
import styles from './BestStreak.module.scss';

type Props = {
  isActive?: boolean;
};

export const BestStreak: FC<Props> = ({
  isActive = true,
}) => {
  const { t } = useTranslation([I18N_CODES.common]);

  const [
    getBestUserStreak,
    {
      data,
      loading,
    },
  ] = useBestUserStreakLazyQuery();

  useEffect(() => {
    if (!isActive) {
      return;
    }

    if (!data?.bestUserStreak?.activeDaysCount) {
      getBestUserStreak();
    }
  }, [
    data,
    getBestUserStreak,
    isActive,
  ]);

  if (!data?.bestUserStreak?.activeDaysCount || loading) {
    return null;
  }

  const activeDaysCount = data?.bestUserStreak?.activeDaysCount || 0;

  return (
    <div className={styles.container}>
      <span
        className={
          cn(typography.platformTextSecondary, styles.daysHint)
        }
      >
        {t(`${I18N_CODES.common}:best_streak`)}
      </span>

      <span
        className={cn(typography.platformH4, styles.daysAmount)}
        data-qa={`best_streak_${activeDaysCount}`}
      >
        <IconFireBest />

        {t(`${I18N_CODES.common}:best_streak_count`, {
          count: activeDaysCount || 0,
        })}
      </span>
    </div>
  );
};
