import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconWallet: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M22 7H23V17H22V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V7ZM20 17H14C12.6739 17 11.4021 16.4732 10.4645 15.5355C9.52678 14.5979 9 13.3261 9 12C9 10.6739 9.52678 9.40215 10.4645 8.46447C11.4021 7.52678 12.6739 7 14 7H20V5H4V19H20V17ZM21 15V9H14C13.2044 9 12.4413 9.31607 11.8787 9.87868C11.3161 10.4413 11 11.2044 11 12C11 12.7956 11.3161 13.5587 11.8787 14.1213C12.4413 14.6839 13.2044 15 14 15H21ZM14 11H17V13H14V11Z" fill="currentColor" />
  </BaseIcon>
);
