import React, { FC } from 'react';
import { ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import { Chubbs } from '@/components/ui/Chubbs/Chubbs';
import {
  ChubbsApplyingLight,
} from '@/components/ui/Chubbs/ChubbsApplying/ChubbsApplying.light';
import {
  ChubbsApplyingDark,
} from '@/components/ui/Chubbs/ChubbsApplying/ChubbsApplying.dark';

export const ChubbsApplying: FC<ChubbsProps> = React.memo((props) => (
  <Chubbs
    LightChubbs={ChubbsApplyingLight}
    DarkChubbs={ChubbsApplyingDark}
    {...props}
  />
));
