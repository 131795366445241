import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export class TimeTrackerHarness extends ComponentHarness {
  dataQa = 'time-tracker';

  modalDataQa = `${this.dataQa}-modal`;

  startButtonDataQa = `${this.dataQa}-start-button`;

  workingTimeStatsDataQa = `${this.dataQa}-working-time-stats`;

  lastTrackedTimeDataQa = `${this.dataQa}-last-tracked-time-block`;

  editLastTrackedTimeButtonDataQa = `${this.dataQa}-editLast-tracked-time-button`;

  idleTimeAlertDataQa = `${this.dataQa}-idle-time-alert`;

  get timeTracker() {
    return cy.get(`[data-qa="${this.dataQa}"]`);
  }

  get modal() {
    return cy.get(`[data-qa="${this.modalDataQa}"]`);
  }

  get startButton() {
    return cy.get(`[data-qa="${this.startButtonDataQa}"]`);
  }

  get workingTimeStats() {
    return cy.get(`[data-qa="${this.workingTimeStatsDataQa}"]`);
  }

  get lastTrackedTime() {
    return cy.get(`[data-qa="${this.lastTrackedTimeDataQa}"]`);
  }

  get editLastTrackedTimeButton() {
    return cy.get(`[data-qa="${this.editLastTrackedTimeButtonDataQa}"]`);
  }

  get idleTimeAlert() {
    return cy.get(`[data-qa="${this.idleTimeAlertDataQa}"]`);
  }
}
