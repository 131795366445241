import { useCallback, useMemo } from 'react';
import { TIMER_BLINK_OFF, TIMER_BLINK_ON } from '@/components/platform/TimeTracker/TimeTracker.constants';

type UseTimerDocTitleOptions = {
  time: number;
  isTimerStarted: boolean;
  formattedCurrentWorkingTime: string;
};

type UseTimerDocTitleResult = string | null;

export const useTimerDocTitle = (
  options: UseTimerDocTitleOptions,
): UseTimerDocTitleResult => {
  const {
    time,
    isTimerStarted,
    formattedCurrentWorkingTime,
  } = options;

  const getDocumentTitle = useCallback(() => {
    if (typeof window === 'undefined') {
      return null;
    }

    if (!document) {
      return null;
    }

    const metaOgTitle = document.querySelector('meta[property="og:title"]');

    const originalTitle = metaOgTitle?.getAttribute('content') ?? (
      document.title.includes(' ~ ')
        ? document.title.split(' ~ ')[1]
        : document.title
    );

    let documentTimer = '';

    if (isTimerStarted) {
      const timerBlinkIcon = Math.trunc(time / 1000) % 2
        ? TIMER_BLINK_ON
        : TIMER_BLINK_OFF;

      documentTimer = `${timerBlinkIcon} ${formattedCurrentWorkingTime} ~ `;
    }

    return `${documentTimer}${originalTitle}`;
  }, [formattedCurrentWorkingTime, time, isTimerStarted]);

  return useMemo(() => getDocumentTitle(), [getDocumentTitle]);
};
