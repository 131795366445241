export const markdownMateTheme = {
  'code[class*="language-"]': {
    color: '#2A2F3C', // default foreground
    background: '#FFFFFF', // default background
    fontFamily: '"Consolas", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace',
    direction: 'ltr',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    fontSize: '.9em',
    lineHeight: '1.2em',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
  },
  'pre[class*="language-"]': {
    color: '#2A2F3C', // default foreground
    background: '#FFFFFF', // default background
    fontFamily: '"Consolas", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace',
    direction: 'ltr',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    fontSize: '.9em',
    lineHeight: '1.2em',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
    padding: '1em',
    margin: '.5em 0',
    overflow: 'auto',
    border: '1px solid #dddddd',
    backgroundColor: '#FFFFFF',
  },
  'pre > code[class*="language-"]': {
    fontSize: '1em',
  },
  'pre[class*="language-"]::-moz-selection': {
    background: '#ADD6FF',
  },
  'pre[class*="language-"] ::-moz-selection': {
    background: '#ADD6FF',
  },
  'code[class*="language-"]::-moz-selection': {
    background: '#ADD6FF',
  },
  'code[class*="language-"] ::-moz-selection': {
    background: '#ADD6FF',
  },
  'pre[class*="language-"]::selection': {
    background: '#ADD6FF',
  },
  'pre[class*="language-"] ::selection': {
    background: '#ADD6FF',
  },
  'code[class*="language-"]::selection': {
    background: '#ADD6FF',
  },
  'code[class*="language-"] ::selection': {
    background: '#ADD6FF',
  },
  ':not(pre) > code[class*="language-"]': {
    padding: '.2em',
    paddingTop: '1px',
    paddingBottom: '1px',
    background: '#f8f8f8',
    border: '1px solid #dddddd',
  },
  comment: {
    color: '#939BAE',
    fontStyle: 'italic',
  },
  keyword: {
    color: '#6D49E9',
  },
  identifier: {
    color: '#2A2F3C',
  },
  string: {
    color: '#D11F61',
  },
  number: {
    color: '#44990A',
  },
  delimiter: {
    color: '#2A2F3C',
  },
  type: {
    color: '#0A9982',
  },
  function: {
    color: '#2A2F3C',
  },
  variable: {
    color: '#6D49E9',
  },
  constant: {
    color: '#057CCC',
  },
  class: {
    color: '#0A9982',
  },
  interface: {
    color: '#0A9982',
  },
  namespace: {
    color: '#0A9982',
  },
  module: {
    color: '#0A9982',
  },
  property: {
    color: '#6D49E9',
  },
  parameter: {
    color: '#6D49E9',
  },
  operator: {
    color: '#2A2F3C',
  },
  punctuation: {
    color: '#2A2F3C',
  },
  invalid: {
    color: '#FFFFFF',
    background: '#F44747',
  },
  deprecated: {
    color: '#FFFFFF',
    background: '#FFD700',
  },
  annotation: {
    color: '#939BAE',
  },
  boolean: {
    color: '#6D49E9',
  },
  builtin: {
    color: '#D11F61',
  },
  escape: {
    color: '#D11FB0',
  },
  format: {
    color: '#D11FB0',
  },
  regexp: {
    color: '#D11FB0',
  },
  tag: {
    color: '#0A9982',
  },
  'attr-name': {
    color: '#D11F61',
  },
  'attr-value': {
    color: '#2A2F3C',
  },
  'attr-value.number.css': {
    color: '#4F3BFB',
  },
  'attr-value.unit.css': {
    color: '#4F3BFB',
  },
  meta: {
    color: '#2A2F3C',
  },
  'attr-value.html': {
    color: '#4F3BFB',
  },
  'string.sql': {
    color: '#D11F61',
  },
  'number.sql': {
    color: '#E64C81',
  },
  'predefined.sql': {
    color: '#0A9982',
  },
  'line-numbers .line-numbers-rows': {
    borderRightColor: '#a5a5a5',
  },
  '.line-numbers-rows > span:before': {
    color: '#2B91AF',
  },
  '.line-highlight': {
    background: 'linear-gradient(to right, rgba(193, 222, 241, 0.2) 70%, rgba(221, 222, 241, 0))',
  },
};
