import React, {
  FC,
  useCallback,
  useRef,
  ReactNode,
} from 'react';
import styles from '@/components/platform/Markdown/components/common.module.scss';

type StatelessDetailsProps = {
  isInitiallyOpened?: boolean;
  children?: ReactNode;
  dataQa?: string;
};

export const StatelessDetails: FC<StatelessDetailsProps> = ({
  children,
  isInitiallyOpened = false,
  dataQa = 'stateless-details',
}) => {
  const isOpen = useRef(isInitiallyOpened);

  const toggle = useCallback(() => {
    isOpen.current = !isOpen.current;
  }, []);

  return (
    <details
      className={styles.details}
      open={isOpen.current}
      onToggle={toggle}
      data-qa={dataQa}
    >
      {children}
    </details>
  );
};
