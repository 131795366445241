import { UserRole } from '@/controllers/user/user.typedefs';
import { AuthUserFragment } from '@/controllers/user/graphql/generated/AuthUser.fragment.generated';

/** @deprecated Use useHasAccess() instead
 * Details here: https://app.clickup.com/24383048/v/dc/q83j8-12520/q83j8-217804
 * */
export const isExternalMentorDeprecated = (
  user: AuthUserFragment | null,
) => user
  && user.userRole === UserRole.ExternalMentor;
