import React, {
  FC, MouseEvent, useEffect, useState,
} from 'react';
import Head from 'next/head';
import { NoSSR } from '@/components/common/NoSSR';
import { cn } from '@/lib';
import { Button, ButtonMode } from '@/components/ui/Button';
import { IconStop } from '@/components/ui/icons/IconStop';
import { typography } from '@/components/ui/typography';
import { IconPlayFill } from '@/components/ui/icons/IconPlayFill';
import { useClickOutside } from '@/hooks/useClickOutside';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { formatTime } from '@/components/platform/TimeTracker/TimeTracker.helpers';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import { MESSAGE_TYPES } from '@/lib/constants/messages';
import { AnalyticsEvents } from '@/controllers/analytics/analytics.events';
import { Divider, DividerReferrals } from '@/components/ui/Divider/Divider';
import { ToolTip, VerticalPositionMode } from '@/components/ui/ToolTip';
import { IconTimer } from '@/components/ui/icons/IconTimer';
import { LastTrackedTime } from '@/components/platform/TimeTracker/LastTrackedTime';
import {
  useGetLastWorkingTimeQuery,
} from '@/components/platform/TimeTracker/graphql/generated/getLastWorkingTime.query.generated';
import { useSharedTimer } from '@/components/platform/TimeTracker/hooks/useSharedTimer';
import styles from '@/components/platform/TimeTracker/TimeTracker.module.scss';
import { WorkingTimeResult } from '@/components/platform/TimeTracker/TimeTracker.typedefs';
import { TIMETRACKER_CHANNEL_NAME } from '@/components/platform/TimeTracker/TimeTracker.constants';
import { useShowWarningOnTabClose } from '@/components/platform/TimeTracker/hooks/useShowWarningOnTabClose';
import { useTimerDocTitle } from '@/components/platform/TimeTracker/hooks/useTimerDocTitle';
import { ContractorTimelineButton } from '@/components/platform/TimeTracker/ContractorTimelineButton';
import { TimeTrackerHarness } from '@/components/platform/TimeTracker/TimeTracker.harness';
import {
  useRouteChangeCallback,
} from '@/controllers/router/router.hooks/useRouteChangeCallback';
import { useGetCurrentWorkingTimeQuery } from '@/components/platform/ContractorsManagement/graphql/generated/getCurrentWorkingTime.query.generated';
import { useGetTeacherWorkingTimesQuery } from '@/components/platform/ContractorsManagement/graphql/generated/getTeacherWorkingTimes.query.generated';
import { useStartWorkingTimeMutation } from '@/components/platform/ContractorsManagement/graphql/generated/startWorkingTime.mutation.generated';
import { useStopWorkingTimeMutation } from '@/components/platform/ContractorsManagement/graphql/generated/stopWorkingTime.mutation.generated';
import { useUpdateWorkingTimeCache } from './hooks/useUpdateWorkingTimeCache';

export const TimeTracker: FC = React.memo(() => {
  const { t } = useTranslation([I18N_CODES.common]);
  const { showMessage } = useFlashMessage();

  const {
    time,
    isTimerStarted,
    startTimer,
    stopTimer,
  } = useSharedTimer();

  const [workingStats, setWorkingStats] = useState<
    WorkingTimeResult | null
  >(null);

  useShowWarningOnTabClose(isTimerStarted);

  const { ref, active, setActive } = useClickOutside(false);

  const {
    data: teacherWorkingTimesData,
    loading: teacherWorkingTimesLoading,
  } = useGetTeacherWorkingTimesQuery();

  const {
    data: currentWorkingTimeData,
    refetch: refetchCurrentWorkingTime,
  } = useGetCurrentWorkingTimeQuery();

  const {
    data: lastWorkingTimeData,
  } = useGetLastWorkingTimeQuery();

  const {
    updateCurrentWorkingTimeCache,
    handleRefetchTime,
  } = useUpdateWorkingTimeCache();

  const [
    startWork, { loading: startWorkLoading },
  ] = useStartWorkingTimeMutation();

  const [
    stopWork, { loading: stopWorkLoading },
  ] = useStopWorkingTimeMutation();

  const isButtonDisabled = teacherWorkingTimesLoading
    || startWorkLoading || stopWorkLoading;

  const teacherWorkingTimes = teacherWorkingTimesData?.getTeacherWorkingTimes;
  const currentWorkingTime = currentWorkingTimeData?.getCurrentWorkingTime;
  const lastWorkingTime = lastWorkingTimeData?.getLastWorkingTime;
  const mentorId = lastWorkingTime?.teacherId || 0;

  const shouldStartTimer = !!(currentWorkingTime && !isTimerStarted);
  const shouldStopTimer = !currentWorkingTime && isTimerStarted;

  useEffect(
    () => {
      if (teacherWorkingTimes) {
        setWorkingStats(teacherWorkingTimes);
      }

      if (shouldStartTimer) {
        startTimer(currentWorkingTime?.startedAt);
      }

      if (shouldStopTimer) {
        stopTimer();
      }
    },
    [
      currentWorkingTime,
      teacherWorkingTimes,
      shouldStartTimer,
      shouldStopTimer,
      startTimer,
      stopTimer,
    ],
  );

  useRouteChangeCallback(() => {
    setActive(false);
  });

  const handleTimerButtonClick = async (
    event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    event.stopPropagation();

    try {
      if (time) {
        await stopWork();

        updateCurrentWorkingTimeCache(null);

        handleRefetchTime();

        AnalyticsEvents.sendEvent(
          AnalyticsEvents.events.TimeTrackerWorkingTimeTracked,
        );
      } else {
        await startWork();

        const currentWorkingTimeRefetched = await refetchCurrentWorkingTime();

        if (typeof BroadcastChannel === 'undefined') {
          return;
        }

        const channel = new BroadcastChannel(TIMETRACKER_CHANNEL_NAME);

        channel.postMessage({
          type: 'currentWorkingTimeSync',
          currentWorkingTime: (
            currentWorkingTimeRefetched.data?.getCurrentWorkingTime
          ),
        });
      }
    } catch (e: any) {
      showMessage({
        type: MESSAGE_TYPES.error,
        heading: t(`${I18N_CODES.common}:time_tracker_error_title`),
        text: t(`${I18N_CODES.common}:${e.message}`),
      });
    }
  };

  const formattedCurrentWorkingTime = formatTime(
    isTimerStarted
      ? time
      : 0,
  );

  const TimerIcon = isTimerStarted
    ? IconStop
    : IconPlayFill;

  const documentTitle = useTimerDocTitle({
    isTimerStarted,
    time,
    formattedCurrentWorkingTime,
  });

  const isEditButtonDisabled = !!currentWorkingTime;

  const {
    dataQa,
    modalDataQa,
    workingTimeStatsDataQa,
  } = new TimeTrackerHarness();

  return (
    <NoSSR>
      {documentTitle && isTimerStarted && (
        <Head>
          <title>
            {documentTitle}
          </title>
        </Head>
      )}

      <div
        className={styles.timeTrackerWrapper}
        ref={ref}
      >
        <ToolTip
          text='Timetracker'
          isVisibleOnHover={!active}
          verticalPosition={VerticalPositionMode.Bottom}
        >
          <Button
            LeftIcon={IconTimer}
            text={formattedCurrentWorkingTime}
            mode={ButtonMode.TransparentLight}
            onClick={() => setActive(!active)}
            data-qa={dataQa}
          />
        </ToolTip>

        <div
          data-qa={modalDataQa}
          className={cn(
            styles.timeTrackerModal,
            { [styles.timeTrackerModalActive]: active },
          )}
        >
          <div className='p-16'>
            <div className={cn(styles.currentTimeBlock, 'mb-32')}>
              <Button
                size={Button.size.Large}
                LeftIcon={TimerIcon}
                onClick={handleTimerButtonClick}
                disabled={isButtonDisabled}
                isLoading={isButtonDisabled}
                data-qa="start-stop-time-button"
              />

              <div className={styles.largeTimer}>
                {formattedCurrentWorkingTime}
              </div>
            </div>

            <LastTrackedTime
              lastTrackedTime={lastWorkingTime}
              onRefetchTime={handleRefetchTime}
              isButtonDisabled={isEditButtonDisabled}
            />

            <div
              className={styles.timeStats}
              data-qa={workingTimeStatsDataQa}
            >
              <div>
                <span className={cn(typography.platformH5, styles.statsTitle)}>
                  {t(`${I18N_CODES.common}:header_time_tracker_today`)}
                </span>

                <div
                  className={typography.platformTextMain}
                  data-qa="time-tracked-today"
                >
                  {formatTime(workingStats?.dailyStats || 0)}
                </div>
              </div>

              <div>
                <span className={cn(typography.platformH5, styles.statsTitle)}>
                  {t(`${I18N_CODES.common}:header_time_tracker_this_week`)}
                </span>

                <div
                  className={typography.platformTextMain}
                  data-qa="time-tracked-this-week"
                >
                  {formatTime(workingStats?.weeklyStats || 0)}
                </div>
              </div>

              <div>
                <span className={cn(typography.platformH5, styles.statsTitle)}>
                  {t(`${I18N_CODES.common}:header_time_tracker_this_month`)}
                </span>

                <div
                  className={typography.platformTextMain}
                  data-qa="time-tracked-this-month"
                >
                  {formatTime(workingStats?.monthlyStats || 0)}
                </div>
              </div>
            </div>
          </div>

          <ContractorTimelineButton mentorId={mentorId} />
        </div>

        <Divider referral={DividerReferrals.Vertical} />
      </div>
    </NoSSR>
  );
});
