import React, { FC, useCallback } from 'react';
import i18Next from '@/middleware/i18n';
import { AchievementItem } from '@/components/platform/Achievements/AchievementItem';
import { ROUTES } from '@/controllers/router/router.contants';
import { AchievementsEvents } from '@/controllers/achievements/achievements.events';
import { useAchievementsToDisplay } from '@/components/platform/Learn/CoursePageContent/components/AchievementsBlock/LatestAchievements/hooks/useAchievementsToDisplay';
import styles from '@/components/platform/Learn/CoursePageContent/components/AchievementsBlock/LatestAchievements/LatestAchievements.module.scss';
import { ICON_SIZE, SHADOW_PROPS } from '@/components/platform/Learn/CoursePageContent/components/AchievementsBlock/AchievementsBlock.constants';
import { LatestAchievementsHarnessSelectors } from '@/components/platform/Learn/CoursePageContent/components/AchievementsBlock/LatestAchievements/LatestAchievements.harness';
import { isMobileSafari } from '@/lib/helpers/isMobileSafari';
import { isDesktopSafari } from '@/lib/helpers/isDesktopSafari';
import { cn } from '@/lib';

export const LatestAchievements: FC = () => {
  const {
    userAchievementsToDisplay,
  } = useAchievementsToDisplay();

  const router = i18Next.useRouter();

  const handleAchievementClick = useCallback((
    selectedId?: number,
  ) => {
    AchievementsEvents.sendEvent(
      AchievementsEvents.events.blockAchievementItemClicked,
      {},
    );

    router.push(
      {
        pathname: ROUTES.achievements,
        ...(selectedId && {
          query: {
            selectedId,
          },
        }),
      },
      ROUTES.achievements,
    );
  }, [
    router,
  ]);

  const isSafariBrowser = isMobileSafari() || isDesktopSafari();

  return (
    <div
      data-qa={LatestAchievementsHarnessSelectors.DataQa}
      className={cn(
        styles.wrapper,
        {
          [styles['wrapper--safari-fix']]: isSafariBrowser,
        },
      )}
    >
      {userAchievementsToDisplay.map(({
        id,
        customIcon,
        achievement,
        achievementId,
      }, i) => (
        <AchievementItem
          key={id || i}
          className={styles.achievementItem}
          achievement={achievement}
          userAchievement={achievement?.userAchievement}
          showName={false}
          showToolTip={false}
          showNewBadge={false}
          showRarityBadge={false}
          showCategory={false}
          customIcon={customIcon}
          onClick={() => handleAchievementClick(achievementId)}
          iconWidth={ICON_SIZE}
          iconHeight={ICON_SIZE}
          shadowProps={SHADOW_PROPS}
          withShadow
        />
      ))}
    </div>
  );
};
