import { useMemo } from 'react';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { DOMAIN_SLUG } from '@/middleware/i18n/i18n.config';
import { isFlexStudent, isFulltimeStudent } from '@/controllers/user/user.utils';
import {
  useGetUserLeadTypeQuery,
} from '@/components/platform/PlatformConsultation/graphql/generated/getUserLeadType.query.generated';
import { LeadType } from '@/controllers/graphql/generated';
import { exists } from '@/lib/helpers/functional';

type Result = {
  canShowBecomeStudentButton: boolean;
  leadType: LeadType | null;
};

export const useBecomeStudentButton = (): Result => {
  const {
    PlatformConsultation,
    isEnabled,
  } = useFeatures();

  const [authUser] = useAuthUser({ ssr: true });
  const { data, loading } = useGetUserLeadTypeQuery();
  const leadType = useMemo(() => (
    loading
      ? null
      : data?.getUserLeadType ?? LeadType.FlexLead
  ), [data, loading]);

  const {
    isSubscriptionProductDomain,
    isKnowelyProduct,
  } = useSubDomainContext();

  const canShowBecomeStudentButton = useMemo(() => (
    isEnabled(PlatformConsultation)
      && !isSubscriptionProductDomain
      && !isKnowelyProduct
      && exists(authUser)
      && !isFlexStudent(authUser)
      && !isFulltimeStudent(authUser)
      && authUser.domain?.slug !== DOMAIN_SLUG.BR
  ), [
    isEnabled,
    PlatformConsultation,
    isSubscriptionProductDomain,
    isKnowelyProduct,
    authUser,
  ]);

  return {
    canShowBecomeStudentButton,
    leadType,
  };
};
