import React, { FC, useCallback } from 'react';
import { Tag } from '@/components/ui/Tag';
import { WarningIcon } from '@/components/ui/icons/WarningIcon';
import { cn } from '@/lib';
import { Button } from '@/components/ui/Button';
import { Dropdown } from '@/components/common/Dropdown';
import { Divider, DividerReferrals } from '@/components/ui/Divider/Divider';
import { Selectors } from '@/lib/selectors';
import { typography } from '@/components/ui/typography';
import { useUserSubscriptionForSubscriptionProduct } from '@/components/platform/SubscriptionProduct/hooks/useUserSubscriptionForSubscriptionProduct';
import { useRouter, useTranslation } from '@/middleware/i18n';
import { ROUTES } from '@/controllers/router/router.contants';
import { DAY } from '@/constants';
import { I18N_CODES } from '@/lib/constants/general';
import { useSubscriptionProductPayment } from '@/components/platform/SubscriptionProduct/hooks/useShouldRenderPaymentIssuePill';
import { emptyFunction } from '@/lib/helpers/functional';
import styles from './PaymentIssueHeaderPill.module.scss';

export const PaymentIssueHeaderPill: FC = () => {
  const { t } = useTranslation([I18N_CODES.common]);

  const dropdownRenderTrigger = useCallback((onClick) => (
    <div
      onClick={onClick}
      data-qa="renew-subscription-alert"
      role='button'
      onKeyDown={emptyFunction}
      tabIndex={0}
    >
      <Tag
        className={styles.tag}
        text={t(`${I18N_CODES.common}:payment_issue_pill_tag_text`)}
        IconElement={WarningIcon}
        color={Tag.color.Danger}
      />
    </div>
  ), [t]);

  const router = useRouter();
  const { lastUserSubscription } = useUserSubscriptionForSubscriptionProduct();

  const terminationDate = lastUserSubscription?.nextPaymentDate
    && new Date(Number(lastUserSubscription?.nextPaymentDate) + DAY)
      .toLocaleDateString(
        'en-US',
        {
          month: 'short',
          day: 'numeric',
        },
      );

  const dropdownRenderContent = useCallback(({ isActive }) => (
    <div
      data-qa="renew-subscription-modal"
      className={cn(
        styles.modal,
        { [Selectors.DropdownOpened]: isActive },
      )}
    >
      {isActive && (
        <>
          <div className={cn(typography.platformTextSecondary, 'mb-16')} data-qa="renew-subscription-modal-text">
            {t(`${I18N_CODES.common}:payment_issue_pill_modal_text`, { terminationDate })}
          </div>

          <Button
            mode={Button.mode.Secondary}
            data-qa="renew-subscription-modal-button"
            text={t(`${I18N_CODES.common}:payment_issue_pill_button_text`)}
            onClick={() => router.replace(ROUTES.subscriptions.index)}
          />
        </>
      )}
    </div>
  ), [router, t, terminationDate]);

  const { shouldRenderPaymentIssue } = useSubscriptionProductPayment();

  if (!lastUserSubscription || !shouldRenderPaymentIssue) {
    return null;
  }

  return (
    <>
      <Dropdown
        isFullWidth
        renderContent={dropdownRenderContent}
        renderTrigger={dropdownRenderTrigger}
      />

      <Divider referral={DividerReferrals.Vertical} />
    </>
  );
};
