import React, { FC } from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconFireworkBase: FC<any> = (props) => {
  const { color1, color2 } = props;

  return (
    <BaseIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 34.1667 32.5)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 33.3333 31.6665)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 32.5 30.8335)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 31.6667 30)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 30.8333 29.1665)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 30 28.3335)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 29.1667 27.5)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 28.3333 26.6665)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 27.5 25.8335)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 26.6667 25)" fill={color1} />
      <rect x="5.83325" y="32.5" width="1.66667" height="1.66667" fill={color1} />
      <rect x="6.66675" y="31.6665" width="1.66667" height="1.66667" fill={color1} />
      <rect x="7.5" y="30.8335" width="1.66667" height="1.66667" fill={color1} />
      <rect x="8.33325" y="30" width="1.66667" height="1.66667" fill={color1} />
      <rect x="9.16675" y="29.1665" width="1.66667" height="1.66667" fill={color1} />
      <rect x="10" y="28.3335" width="1.66667" height="1.66667" fill={color1} />
      <rect x="10.8333" y="27.5" width="1.66667" height="1.66667" fill={color1} />
      <rect x="11.6667" y="26.6665" width="1.66667" height="1.66667" fill={color1} />
      <rect x="12.5" y="25.8335" width="1.66667" height="1.66667" fill={color1} />
      <rect x="13.3333" y="25" width="1.66667" height="1.66667" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 15 13.3335)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 14.1665 12.5)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 13.3333 11.667)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 12.5 10.8335)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 11.6665 10)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 10.8333 9.16699)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 10 8.3335)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 9.1665 7.5)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 8.33325 6.66699)" fill={color1} />
      <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 7.5 5.8335)" fill={color1} />
      <rect x="25" y="13.3335" width="1.66667" height="1.66667" fill={color1} />
      <rect x="25.8335" y="12.5" width="1.66667" height="1.66667" fill={color1} />
      <rect x="26.6667" y="11.667" width="1.66667" height="1.66667" fill={color1} />
      <rect x="27.5" y="10.8335" width="1.66667" height="1.66667" fill={color1} />
      <rect x="28.3335" y="10" width="1.66667" height="1.66667" fill={color1} />
      <rect x="29.1667" y="9.16699" width="1.66667" height="1.66667" fill={color1} />
      <rect x="30" y="8.3335" width="1.66667" height="1.66667" fill={color1} />
      <rect x="30.8335" y="7.5" width="1.66667" height="1.66667" fill={color1} />
      <rect x="31.6667" y="6.66699" width="1.66667" height="1.66667" fill={color1} />
      <rect x="32.5" y="5.8335" width="1.66667" height="1.66667" fill={color1} />
      <rect x="19.1667" y="13.3335" width="1.66667" height="1.66667" fill={color2} />
      <rect x="19.1667" y="11.6665" width="1.66667" height="1.66667" fill={color2} />
      <rect x="19.1667" y="10" width="1.66667" height="1.66667" fill={color2} />
      <rect x="19.1667" y="8.3335" width="1.66667" height="1.66667" fill={color2} />
      <rect x="19.1667" y="6.6665" width="1.66667" height="1.66667" fill={color2} />
      <rect x="19.1667" y="5" width="1.66667" height="1.66667" fill={color2} />
      <rect x="19.1667" y="3.3335" width="1.66667" height="1.66667" fill={color2} />
      <rect x="19.1667" y="1.6665" width="1.66667" height="1.66667" fill={color2} />
      <rect x="19.1667" width="1.66667" height="1.66667" fill={color2} />
      <rect x="19.1667" y="38.3335" width="1.66667" height="1.66667" fill={color2} />
      <rect x="19.1667" y="36.6665" width="1.66667" height="1.66666" fill={color2} />
      <rect x="19.1667" y="35" width="1.66667" height="1.66667" fill={color2} />
      <rect x="19.1667" y="33.3335" width="1.66667" height="1.66667" fill={color2} />
      <rect x="19.1667" y="31.6665" width="1.66667" height="1.66667" fill={color2} />
      <rect x="19.1667" y="30" width="1.66667" height="1.66667" fill={color2} />
      <rect x="19.1667" y="28.3335" width="1.66667" height="1.66667" fill={color2} />
      <rect x="19.1667" y="26.6665" width="1.66667" height="1.66667" fill={color2} />
      <rect x="19.1667" y="25" width="1.66667" height="1.66667" fill={color2} />
      <rect
        x="13.3333"
        y="20.8335"
        width="1.66667"
        height="1.66667"
        transform="rotate(-90 13.3333 20.8335)"
        fill={color2}
      />
      <rect
        x="11.6667"
        y="20.8335"
        width="1.66667"
        height="1.66667"
        transform="rotate(-90 11.6667 20.8335)"
        fill={color2}
      />
      <rect x="10" y="20.8335" width="1.66667" height="1.66667" transform="rotate(-90 10 20.8335)" fill={color2} />
      <rect
        x="8.33325"
        y="20.8335"
        width="1.66667"
        height="1.66667"
        transform="rotate(-90 8.33325 20.8335)"
        fill={color2}
      />
      <rect
        x="6.66675"
        y="20.8335"
        width="1.66667"
        height="1.66667"
        transform="rotate(-90 6.66675 20.8335)"
        fill={color2}
      />
      <rect x="5" y="20.8335" width="1.66667" height="1.66667" transform="rotate(-90 5 20.8335)" fill={color2} />
      <rect
        x="3.33325"
        y="20.8335"
        width="1.66667"
        height="1.66667"
        transform="rotate(-90 3.33325 20.8335)"
        fill={color2}
      />
      <rect
        x="1.66675"
        y="20.8335"
        width="1.66667"
        height="1.66667"
        transform="rotate(-90 1.66675 20.8335)"
        fill={color2}
      />
      <rect y="20.8335" width="1.66667" height="1.66667" transform="rotate(-90 0 20.8335)" fill={color2} />
      <rect
        x="38.3333"
        y="20.8335"
        width="1.66667"
        height="1.66667"
        transform="rotate(-90 38.3333 20.8335)"
        fill={color2}
      />
      <rect
        x="36.6667"
        y="20.8335"
        width="1.66667"
        height="1.66666"
        transform="rotate(-90 36.6667 20.8335)"
        fill={color2}
      />
      <rect x="35" y="20.8335" width="1.66667" height="1.66667" transform="rotate(-90 35 20.8335)" fill={color2} />
      <rect
        x="33.3333"
        y="20.8335"
        width="1.66667"
        height="1.66667"
        transform="rotate(-90 33.3333 20.8335)"
        fill={color2}
      />
      <rect
        x="31.6667"
        y="20.8335"
        width="1.66667"
        height="1.66667"
        transform="rotate(-90 31.6667 20.8335)"
        fill={color2}
      />
      <rect x="30" y="20.8335" width="1.66667" height="1.66667" transform="rotate(-90 30 20.8335)" fill={color2} />
      <rect
        x="28.3333"
        y="20.8335"
        width="1.66667"
        height="1.66667"
        transform="rotate(-90 28.3333 20.8335)"
        fill={color2}
      />
      <rect
        x="26.6667"
        y="20.8335"
        width="1.66667"
        height="1.66667"
        transform="rotate(-90 26.6667 20.8335)"
        fill={color2}
      />
      <rect x="25" y="20.8335" width="1.66667" height="1.66667" transform="rotate(-90 25 20.8335)" fill={color2} />
    </BaseIcon>
  );
};
