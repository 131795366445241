import { createContext } from 'react';

export enum IconStyling {
  DarkGray = 'dark-gray',
  LightGray = 'light-gray',
}

export interface LikeRatingContextValue {
  rating?: number | null;
  iconStyling?: IconStyling;
}

export const LikeRatingContext = createContext<LikeRatingContextValue>({
  rating: 0,
  iconStyling: IconStyling.DarkGray,
});
