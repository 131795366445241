import { useEffect, useMemo } from 'react';
import { extractMentions } from '@/components/ui/ChatInput/InputField/InputField.utils/extractMentions';
import { GroupMentions } from '@/components/ui/ChatInput/ParticipantMention/ParticipantMention.typedefs';
import { useParticipantsContext } from '@/components/platform/Chat/contexts/ParticipantsContext';

export const useLoadMentionedParticipants = (text: string) => {
  const { participantsMap, loadParticipant } = useParticipantsContext();

  const mentions = useMemo(() => extractMentions(text), [text]);

  useEffect(() => {
    if (!mentions.length) {
      return;
    }

    mentions.forEach((mention) => {
      // Student mention is supposed to be replaced with actual student name,
      // otherwise it should not be loaded
      if (
        mention.participantId !== GroupMentions.All
        && mention.participantId !== GroupMentions.Student
      ) {
        loadParticipant(Number(mention.participantId));
      }
    });
  }, [loadParticipant, mentions]);

  return { participantsMap };
};
