import React, {
  FC, useMemo, useState,
} from 'react';
import streakCharmFlames from '@/images/generated/icons.general.streakCharmFlames.s3Key';
import { IconImage } from '@/components/ui/Image/IconImage';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';
import {
  ACTIVE_STREAK_FIRE_DAYS_MAX,
  SHOW_STREAK_DAYS,
  STREAK_SCREEN_CONGRATS_TRANSLATE_CODES,
} from '@/components/platform/Streaks/Streaks.constants';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { FireDay, FireDayProps } from '@/components/platform/Streaks/components/StreakFireDay';
import { getRandomArrayElement } from '@/lib/helpers/array';
import { getDateByDays } from '@/lib/helpers/dates/getDateByDays';
import {
  UserStreakBaseFragment,
} from '@/components/platform/Streaks/graphql/generated/UserStreaks.base.fragment.generated';
import { cn } from '@/lib';
import styles from './StreaksGamificationModalContent.module.scss';

export type StreakModalPayload = Omit<UserStreakBaseFragment, 'userId'>;

type Props = {
  streak: StreakModalPayload;
  onClose: () => void;
};

export const StreaksGamificationModalContent: FC<Props> = ({
  streak,
  onClose,
}) => {
  const {
    activeDaysCount,
    usedFrozenDays,
    lastActiveDay,
  } = streak;

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const { t } = useTranslation([I18N_CODES.common]);

  const days: FireDayProps[] = useMemo(() => {
    const dayToIterateFrom = activeDaysCount >= ACTIVE_STREAK_FIRE_DAYS_MAX
      ? getDateByDays(lastActiveDay, -(ACTIVE_STREAK_FIRE_DAYS_MAX - 1))
      : getDateByDays(lastActiveDay, -(activeDaysCount - 1));

    return Array
      .from({ length: SHOW_STREAK_DAYS }, (_, i) => {
        const iteratedDate = new Date(getDateByDays(dayToIterateFrom, i));

        const label = new Intl
          .DateTimeFormat('en-US', { weekday: 'short' })
          .format(iteratedDate)
          .slice(0, 2);

        return {
          label,
          isFrozen: usedFrozenDays.some((frozen) => (
            iteratedDate.getTime() === new Date(frozen).getTime()
          )),
          isDisabled: (
            iteratedDate.getTime() > new Date(lastActiveDay).getTime()
          ),
        };
      });
  }, [activeDaysCount, lastActiveDay, usedFrozenDays]);

  const randomCongratsCode = useMemo(
    () => getRandomArrayElement(STREAK_SCREEN_CONGRATS_TRANSLATE_CODES),
    [],
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <section className={styles.counterWrapper}>
          <div className={styles.counterOutline}>
            <span
              className={styles.counter}
              data-length={activeDaysCount.toString().length}
              data-qa='streaks-modal-active-days-counter'
            >
              {activeDaysCount}
            </span>
          </div>

          <div className={styles.charmFlame}>
            <IconImage
              className={cn(styles.charmFlameImage, {
                [styles.charmFlameImageLoaded]: isImageLoaded,
              })}
              onLoadingComplete={() => setIsImageLoaded(true)}
              layout='fixed'
              imageData={streakCharmFlames}
              width={84}
              height={97}
            />
          </div>
        </section>

        <h2 className={styles.heading}>
          {t(`${I18N_CODES.common}:streak_days.message`)}
        </h2>

        <section className={styles.daysWrapper}>
          <div className={styles.daysContainer}>
            {days.map(({
              label, isFrozen, isDisabled,
            }) => (
              <FireDay
                key={label}
                label={label}
                isDisabled={isDisabled}
                isFrozen={isFrozen}
              />
            ))}
          </div>

          <div className={styles.divider} />

          <div className={styles.daysTextContainer}>
            <p className={styles.daysText}>
              {t(
                `${I18N_CODES.common}:${randomCongratsCode}`,
                { days: activeDaysCount + 1 },
              )}
            </p>
          </div>
        </section>
      </div>

      <div className={styles.footer}>
        <Button
          onClick={onClose}
          mode={ButtonMode.Primary}
          size={ButtonSize.Large}
          hasFullWidth
          text={t(`${I18N_CODES.common}:streak_activated_day_message_button`)}
          dataQa='streaks-modal-continue-button'
        />
      </div>
    </div>
  );
};
