import { useCallback, useEffect } from 'react';
import { LogLevels } from '@mate-academy/logger';
import { KeyboardKey } from '@/constants/keyboard';
import { errorHandler } from '@/core/ErrorHandler';
import { logger } from '@/core/Logger';

interface HookArguments {
  shortcutKey: string;
  invokeFunction?: (...args: any[]) => any;
  isDisabled?: boolean;
  desiredFirstKeys?: KeyboardKey[];
}

const wasDesiredKeyPressed = (
  event: KeyboardEvent,
  pressedKey: KeyboardKey,
) => {
  switch (pressedKey) {
    case KeyboardKey.Ctrl:
      return event.ctrlKey;
    case KeyboardKey.Meta:
      return event.metaKey;
    case KeyboardKey.Shift:
      return event.shiftKey;

    default:
      errorHandler.captureMessage(
        `not supported key was passed: ${pressedKey}, add button support by yourself`,
        {
          logLevel: LogLevels.Warning,
          logger: logger.child(wasDesiredKeyPressed.name),
        },
      );

      return false;
  }
};

export const useKeyboardShortcut = (args: HookArguments) => {
  const {
    shortcutKey,
    invokeFunction,
    isDisabled,
    desiredFirstKeys,
  } = args;

  const keyDownListener = useCallback((keyDownEvent: KeyboardEvent) => {
    const {
      key,
    } = keyDownEvent;

    const isDesiredFirstKeyPressed = !desiredFirstKeys?.length
      ? true
      : desiredFirstKeys.some(
        (desiredKey) => wasDesiredKeyPressed(keyDownEvent, desiredKey),
      );

    const isDesiredSecondKeyPressed = shortcutKey === key;

    const shouldTriggerInvokeFunction = isDesiredFirstKeyPressed
      && isDesiredSecondKeyPressed;

    if (shouldTriggerInvokeFunction && invokeFunction) {
      keyDownEvent.preventDefault();
      invokeFunction();
    }
  }, [desiredFirstKeys, invokeFunction, shortcutKey]);

  useEffect(() => {
    if (!isDisabled) {
      window.addEventListener('keydown', keyDownListener);
    }

    return () => {
      window.removeEventListener('keydown', keyDownListener);
    };
  }, [keyDownListener, isDisabled, invokeFunction]);
};
