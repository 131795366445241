import React, { FC, useCallback } from 'react';
import { cn } from '@/lib/classNames';
import { SearchableEntityType } from '@/controllers/graphql/generated';
import { useGlobalSearchContext } from '@/components/platform/GlobalSearch/GlobalSearchContext';
import { IconLearnTheory } from '@/components/ui/icons/IconLearnTheory';
import { Button } from '@/components/ui/Button';
import { IconPracticeTasks } from '@/components/ui/icons/IconPracticeTasks';
import { IconPlayFill } from '@/components/ui/icons/IconPlayFill';
import { IconChat } from '@/components/ui/icons/IconChat';
import { IconCommentStatus } from '@/components/ui/icons/IconCommentStatus';
import { typography } from '@/components/ui/typography';
import { getTranslateCodeByCategory } from '@/components/platform/GlobalSearch/helpers';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { useIsFeaturesEnabled } from '@/controllers/features/features.hooks/useIsFeaturesEnabled';
import { SubDomainFeatures } from '@/controllers/features/subDomainFeature.config';
import styles from './CategoriesSelector.module.scss';

const categories = [
  SearchableEntityType.Theory,
  SearchableEntityType.Task,
  SearchableEntityType.Video,
  SearchableEntityType.Chat,
  SearchableEntityType.ChatMessage,
] as const;

const categoryIcons = {
  [SearchableEntityType.Theory]: IconLearnTheory,
  [SearchableEntityType.Task]: IconPracticeTasks,
  [SearchableEntityType.Video]: IconPlayFill,
  [SearchableEntityType.Chat]: IconChat,
  [SearchableEntityType.ChatMessage]: IconCommentStatus,
};

const chatCategories = [
  SearchableEntityType.Chat,
  SearchableEntityType.ChatMessage,
];

export const CategoriesSelector: FC = () => {
  const { t } = useTranslation([I18N_CODES.globalSearch]);
  const { setSelectedCategory } = useGlobalSearchContext();
  const isLmsContentSearchEnabled = useIsFeaturesEnabled(
    SubDomainFeatures.LmsContentSearch,
  );

  const onCategoryClick = useCallback(
    (category: SearchableEntityType) => () => {
      setSelectedCategory(category);
    },
    [setSelectedCategory],
  );

  const filteredCategories = isLmsContentSearchEnabled
    ? categories
    : categories
      .filter((category) => chatCategories.includes(category));

  return (
    <section className={styles.categoriesSelector}>
      <p className={cn(typography.platformH5, styles.heading)}>
        {t(`${I18N_CODES.globalSearch}:category_selector.hint`)}
      </p>

      <ul className={styles.categoriesList}>
        {filteredCategories.map((category) => (
          <li key={category}>
            <Button
              mode={Button.mode.TransparentLight}
              text={t(getTranslateCodeByCategory(category))}
              onClick={onCategoryClick(category)}
              LeftIcon={categoryIcons[category]}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};
