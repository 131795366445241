import { useCallback, useMemo, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import { useSearchByQueryLazyQuery } from '@/components/platform/GlobalSearch/graphql/generated/searchByQuery.query.generated';
import { GlobalSearchFilters, GlobalSearchSortOption, SearchableEntityType } from '@/controllers/graphql/generated';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { getDeduplicationKey } from '@/components/platform/GlobalSearch/helpers';

export interface SearchParams {
  query: string;
  category: SearchableEntityType | null;
  filters?: GlobalSearchFilters;
  sortBy?: GlobalSearchSortOption;
}

export const useSearchByQuery = () => {
  const { language } = useSubDomainContext();

  const [
    searchLazyQuery,
    {
      data, loading, fetchMore, called,
    },
  ] = useSearchByQueryLazyQuery();

  const uniqueMatches = useMemo(() => {
    if (!data?.searchByQuery.matches) {
      return [];
    }

    return uniqBy(
      data.searchByQuery.matches,
      (match) => getDeduplicationKey(match.type, match.id),
    );
  }, [data?.searchByQuery.matches]);

  const response = {
    matches: uniqueMatches,
    totalMatches: data?.searchByQuery.totalMatches || 0,
    offset: data?.searchByQuery.offset || 0,
  };

  const offsetForNextRequest = response.offset;
  const hasMore = response.totalMatches > response.matches.length;

  const search = useCallback(
    ({
      query,
      category,
      filters,
      sortBy,
    }: SearchParams) => {
      searchLazyQuery({
        variables: {
          query,
          lang: language,
          types: category
            ? [category]
            : [],
          filters,
          sortBy,
        },
      });
    },
    [searchLazyQuery, language],
  );

  const [moreLoading, setMoreLoading] = useState(false);

  const loadMore = useCallback(async (onCompleted) => {
    if (!fetchMore || loading || !hasMore) {
      return;
    }

    setMoreLoading(true);

    try {
      await fetchMore({
        variables: {
          offset: offsetForNextRequest,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          if (onCompleted) {
            onCompleted();
          }

          return {
            ...prev,
            searchByQuery: {
              ...prev.searchByQuery,
              offset: fetchMoreResult.searchByQuery.offset,
              matches: [
                ...prev.searchByQuery.matches,
                ...fetchMoreResult.searchByQuery.matches,
              ],
            },
          };
        },
      });
    } finally {
      setMoreLoading(false);
    }
  }, [fetchMore, loading, hasMore, offsetForNextRequest]);

  return {
    response,
    search,
    loadMore,
    hasMore,
    called,
    loading,
    moreLoading,
  };
};
