import React, {
  FC, useMemo, memo, useCallback,
} from 'react';
import { useMessageSendingTimeRenderer } from '@/components/platform/GlobalSearch/hooks/useMessageSendingTimeRenderer';
import { useRouter } from '@/middleware/i18n';
import { SearchResultWrapper } from '@/components/platform/GlobalSearch/components/SearchableEntities/SearchResultWrapper';
import { IconCommentStatus } from '@/components/ui/icons/IconCommentStatus';
import { SearchableMessageFragment } from '@/components/platform/GlobalSearch/graphql/generated/searchableMessage.fragment.generated';
import { ChatMarkdown } from '@/components/platform/Chat/components/ChatMarkdown';
import { GlobalSearchMatchFragment } from '@/components/platform/GlobalSearch/graphql/generated/globalSearchMatch.fragment.generated';
import { MessageType } from '@/components/platform/Chat/chat.typedefs';
import { getMessageUrl } from '@/components/platform/Chat/chat.helpers';
import { GlobalSearchEvents } from '@/components/platform/GlobalSearch/analytics/globalSearch.events';
import { SearchableEntityType } from '@/controllers/graphql/generated';
import styles from './SearchableMessage.module.scss';

interface Props {
  matchedMessage: GlobalSearchMatchFragment;
}

export const SearchableMessage: FC<Props> = memo(
  ({ matchedMessage }) => {
    const router = useRouter();

    const renderSendingTime = useMessageSendingTimeRenderer();

    const { message } = (matchedMessage.entity as SearchableMessageFragment);

    const senderName = message.sender.username;
    const { chat } = message;

    const threadId = (
      message.__typename === MessageType.OpenQuestionAnswer
        || message.__typename === MessageType.Message
    )
      ? message.threadId
      : null;

    const chatTitle = threadId
      ? `Thread in ${chat.name}`
      : chat.name;

    const text = useMemo(() => {
      if (matchedMessage.highlights.body) {
        return matchedMessage.highlights.body.join('...');
      }

      return message.text;
    }, [matchedMessage.highlights.body, message.text]);

    const onClick = useCallback(() => {
      GlobalSearchEvents.sendEvent(
        GlobalSearchEvents.events.searchResultClicked,
        { searchableType: SearchableEntityType.ChatMessage },
      );

      router.push(getMessageUrl(message));
    }, [message, router]);

    return (
      <SearchResultWrapper onClick={onClick}>
        <div className={styles.wrapper}>
          <header className={styles.header}>
            <div>
              <IconCommentStatus className={styles.icon} />
            </div>

            <span className={styles.senderName}>
              {senderName}
            </span>

            <span className={styles.chatTitle}>
              {`- ${chatTitle}`}
            </span>

            <span className={styles.sendingTime}>
              {renderSendingTime(message.createdAt)}
            </span>
          </header>

          <main>
            <ChatMarkdown
              text={text}
              withHTML
            />
          </main>
        </div>
      </SearchResultWrapper>
    );
  },
);
