import React, {
  FC, useCallback, useMemo, useRef,
} from 'react';
import { GlobalSearchSortOption } from '@/controllers/graphql/generated';
import { Button } from '@/components/ui/Button';
import { IconOptionsWithArrow } from '@/components/ui/icons/IconOptionsWithArrow';
import { DropdownMenu } from '@/components/ui/DrowdownMenu';
import { useIsOpenStateDeprecated } from '@/hooks/useIsOpenStateDeprecated';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { GlobalSearchEvents } from '@/components/platform/GlobalSearch/analytics/globalSearch.events';

interface Props {
  selectedSortOption: GlobalSearchSortOption;
  setSelectedSortOption: (option: GlobalSearchSortOption) => void;
}

export const SortByButton: FC<Props> = ({
  selectedSortOption,
  setSelectedSortOption,
}) => {
  const { t } = useTranslation([I18N_CODES.globalSearch]);

  const parentRef = useRef<HTMLDivElement>(null);

  const [isMenuOpened, closeMenu, openMenu] = useIsOpenStateDeprecated();

  const optionClickHandler = useCallback(
    (sortOption: GlobalSearchSortOption) => () => {
      setSelectedSortOption(sortOption);

      GlobalSearchEvents.sendEvent(
        GlobalSearchEvents.events.sortOptionChanged,
        { sortOption },
      );

      closeMenu();
    },
    [setSelectedSortOption, closeMenu],
  );

  const isHighlighted = useCallback(
    (option: GlobalSearchSortOption) => option === selectedSortOption,
    [selectedSortOption],
  );

  const getTitle = useCallback((sortOption: GlobalSearchSortOption): string => {
    switch (sortOption) {
      case GlobalSearchSortOption.Newest:
        return t(`${I18N_CODES.globalSearch}:sort_option.newest`);
      case GlobalSearchSortOption.Oldest:
        return t(`${I18N_CODES.globalSearch}:sort_option.oldest`);
      case GlobalSearchSortOption.MostRelevant:
      default:
        return t(`${I18N_CODES.globalSearch}:sort_option.most_relevant`);
    }
  }, [t]);

  const items = useMemo(() => (
    [
      GlobalSearchSortOption.MostRelevant,
      GlobalSearchSortOption.Newest,
      GlobalSearchSortOption.Oldest,
    ].map((option) => ({
      text: getTitle(option),
      onClick: optionClickHandler(option),
      isHighlighted: isHighlighted(option),
    }))
  ), [isHighlighted, optionClickHandler, getTitle]);

  return (
    <div ref={parentRef}>
      <Button
        onClick={openMenu}
        mode={Button.mode.Secondary}
        size={Button.size.Large}
        LeftIcon={IconOptionsWithArrow}
      />

      {isMenuOpened && (
        <DropdownMenu
          items={items}
          onClose={closeMenu}
          parentNode={parentRef.current}
        />
      )}
    </div>
  );
};
