import { LatestAchievementsHarness } from '@/components/platform/Learn/CoursePageContent/components/AchievementsBlock/LatestAchievements/LatestAchievements.harness';
import { TitleBlockHarness } from '@/components/platform/Learn/CoursePageContent/components/AchievementsBlock/TitleBlock/TitleBlock.harness';
import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export enum AchievementsBlockHarnessSelectors {
  DataQa = 'achievements-block',
}

export class AchievementsBlockHarness extends ComponentHarness {
  dataQa = AchievementsBlockHarnessSelectors.DataQa;

  get titleBlock() {
    return new TitleBlockHarness();
  }

  get latestAchievements() {
    return new LatestAchievementsHarness();
  }

  assertRenderedCorrectly() {
    this.assertExists();
    this.titleBlock.assertRendered();
    this.latestAchievements.assertExists();
    this.latestAchievements.achievementItem.assertExists();
    this.latestAchievements.achievementItem.name.should('not.exist');
    this.latestAchievements.achievementItem.category.should('not.exist');
    this.latestAchievements.achievementItem.achievementTag.assertNotExist();
  }
}
