import React, {
  FC, memo, useCallback, useMemo,
} from 'react';
import { GlobalSearchMatchFragment } from '@/components/platform/GlobalSearch/graphql/generated/globalSearchMatch.fragment.generated';
import { SearchableVideoFragment } from '@/components/platform/GlobalSearch/graphql/generated/searchableVideo.fragment.generated';
import { SearchResultWrapper } from '@/components/platform/GlobalSearch/components/SearchableEntities/SearchResultWrapper';
import { IconPlayFill } from '@/components/ui/icons/IconPlayFill';
import { typography } from '@/components/ui/typography';
import { SearchableEntityTitleMarkdown } from '@/components/platform/GlobalSearch/components/SearchableEntities/SearchableEntityTitleMarkdown';
import { IconLock } from '@/components/ui/icons/IconLock';
import { ToolTip, VerticalPositionMode } from '@/components/ui/ToolTip';
import { useRouter, useTranslation } from '@/middleware/i18n';
import { getTranslateCodeByCategory } from '@/components/platform/GlobalSearch/helpers';
import { I18N_CODES } from '@/lib/constants/general';
import { SearchableEntityType } from '@/controllers/graphql/generated';
import { GlobalSearchEvents } from '@/components/platform/GlobalSearch/analytics/globalSearch.events';
import { TopicSections } from '@/controllers/courseUserTopics/courseUserTopics.typedefs';
import { useTopicUrlForUnknownRedirect } from '@/components/platform/Study/pages/urlTools';
import styles from './SearchableVideo.module.scss';

interface Props {
  matchedVideo: GlobalSearchMatchFragment;
}

export const SearchableVideo: FC<Props> = memo(({ matchedVideo }) => {
  const { t } = useTranslation([I18N_CODES.globalSearch]);
  const router = useRouter();

  const { video } = (matchedVideo.entity as SearchableVideoFragment);

  const videoTitle = useMemo(() => {
    if (matchedVideo.highlights.title?.length) {
      return matchedVideo.highlights.title[0];
    }

    return video?.title;
  }, [matchedVideo.highlights.title, video?.title]);

  const courseTopic = video?.courseTopics[0];

  const courseName = courseTopic?.course?.nameShort;
  const topicName = courseTopic?.name;

  const accessPreferences = courseTopic?.meta.access;

  const link = useTopicUrlForUnknownRedirect({
    courseTopicSlug: courseTopic?.slug,
    moduleSlug: courseTopic?.course?.slug,
    section: TopicSections.Video,
    videoId: video?.id,
  });

  const onClick = useCallback(() => {
    if (!link) {
      return;
    }

    GlobalSearchEvents.sendEvent(
      GlobalSearchEvents.events.searchResultClicked,
      { searchableType: SearchableEntityType.Video },
    );

    router.push(link);
  }, [router, link]);

  if (!video) {
    return null;
  }

  return (
    <SearchResultWrapper
      onClick={onClick}
      disabled={!accessPreferences?.isGranted}
    >
      <div className={styles.wrapper}>
        {accessPreferences?.isGranted
          ? (
            <IconPlayFill className={styles.icon} />
          )
          : (
            <ToolTip
              text={accessPreferences?.deniedReason || 'No access'}
              verticalPosition={VerticalPositionMode.Top}
            >
              <IconLock className={styles.iconLock} />
            </ToolTip>
          )}

        <span className={typography.platformH4}>
          <SearchableEntityTitleMarkdown
            title={`${t(getTranslateCodeByCategory(SearchableEntityType.Video))}/${videoTitle}`}
          />
        </span>

        {Boolean(courseTopic) && (
          <span
            title={`${courseName}/${topicName}`}
            className={styles.videoPath}
          >
            {`- ${courseName}/${topicName}`}
          </span>
        )}
      </div>
    </SearchResultWrapper>
  );
});
