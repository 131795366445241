import {
  useCallback,
  useRef,
  useState,
} from 'react';
import {
  HEARTBEAT_INTERVAL,
  TIMETRACKER_CHANNEL_NAME,
} from '@/components/platform/TimeTracker/TimeTracker.constants';
import { TimeTrackerMessageType } from '@/components/platform/TimeTracker/TimeTracker.typedefs';

type SharedTimerResult = {
  time: number;
  isTimerStarted: boolean;
  setTime: (time: number) => void;
  startTimer: (startAt?: number) => void;
  stopTimer: () => void;
};

export const useSharedTimer = (): SharedTimerResult => {
  const [time, setTime] = useState(0);
  const [isTimerStarted, setIsTimerStarted] = useState(false);
  const timerId = useRef<NodeJS.Timeout>();

  const sendHeartbeat = useCallback(() => {
    if (typeof BroadcastChannel === 'undefined') {
      return;
    }

    if (!isTimerStarted) {
      return;
    }

    const channel = new BroadcastChannel(TIMETRACKER_CHANNEL_NAME);

    channel.postMessage({ type: TimeTrackerMessageType.Heartbeat });
    channel.close();

    setTimeout(() => {
      sendHeartbeat();
    }, HEARTBEAT_INTERVAL);
  }, [
    isTimerStarted,
  ]);

  const startTimer = useCallback((startAt: number = Date.now()) => {
    if (timerId.current) {
      return;
    }

    setTime(Date.now() - startAt);

    timerId.current = setInterval(() => {
      const currentTime = Date.now() - startAt;

      setTime(currentTime);
    }, 1000);

    setIsTimerStarted(true);
    sendHeartbeat();
  }, [
    setTime,
    setIsTimerStarted,
    sendHeartbeat,
  ]);

  const stopTimer = useCallback(() => {
    if (!timerId.current) {
      return;
    }

    clearInterval(timerId.current);
    setTime(0);
    setIsTimerStarted(false);
    timerId.current = undefined;
  }, [
    timerId,
  ]);

  return {
    time,
    isTimerStarted,
    setTime,
    startTimer,
    stopTimer,
  };
};
