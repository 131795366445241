import React, { FC } from 'react';
import { ComponentWrapper } from '@/components/ui/helpers';
import { ToolTip, ToolTipWidthMode } from '@/components/ui/ToolTip';
import { Button, ButtonProps } from '@/components/ui/Button';
import { truthy } from '@/lib/helpers/functional';

interface CustomTooltipProps {
  tooltipText?: string;
  tooltipWidthMode?: ToolTipWidthMode;
}

type Props = ButtonProps & CustomTooltipProps;

export const ButtonWithTooltip: FC<Props> = (props) => {
  const {
    tooltipText,
    tooltipWidthMode = ToolTipWidthMode.Max,
    ...buttonProps
  } = props;

  return (
    <ComponentWrapper
      shouldWrapChildren={truthy(tooltipText)}
      wrapper={(children) => (
        <ToolTip text={tooltipText!} widthMode={tooltipWidthMode}>
          {children}
        </ToolTip>
      )}
    >
      <Button {...buttonProps} />
    </ComponentWrapper>
  );
};
