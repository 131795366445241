export enum UserRole {
  Admin = 'admin',
  Student = 'student',
  Moderator = 'moderator',
  Recruiter = 'recruiter',
  Sales = 'sales',
  ExternalMentor = 'external_mentor',
  EnglishTeacher = 'english_teacher',
  Mentor = 'mentor',
  ExternalCoach = 'external_coach',
  ExternalExpert = 'external_expert',
  ExternalOther = 'external_other',
  Instructor = 'instructor',
}

export enum UserEnglishMateLevel {
  Unspecified = 'Unspecified',
  Crab = 'Crab',
  Fish = 'Fish',
  Turtle = 'Turtle',
  Bird = 'Bird',
}
