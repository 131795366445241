import React, { FC } from 'react';
import { useCopyToClipboard } from '@/components/ui/CopyButton/useCopyToClipboard';
import { IconCheck } from '@/components/ui/icons/IconCheck';
import { IconCopy } from '@/components/ui/icons/IconCopy';
import { ButtonMode } from '@/components/ui/Button';
import { ButtonWithTooltip } from '@/components/ui/ButtonWithTooltip';
import { AnyFunction } from '@/typedefs/common';
import { FCIcon } from '@/components/ui/icons/typedefs';

interface Props {
  className?: string;
  content: string;
  tooltipText?: string;
  mode?: ButtonMode;
  onClick?: AnyFunction;
  DefaultIcon?: FCIcon;
  iconClassName?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

export const CopyButton: FC<Props> = (props) => {
  const {
    className,
    content,
    tooltipText,
    mode: buttonMode,
    onClick,
    DefaultIcon = IconCopy,
    iconClassName,
    disabled = false,
    isLoading = false,
  } = props;

  const [isCopied, handleClick] = useCopyToClipboard(content, onClick);

  const ButtonIcon = isCopied
    ? IconCheck
    : DefaultIcon;

  return (
    <ButtonWithTooltip
      data-qa="copy-link-button"
      className={className}
      tooltipText={tooltipText}
      mode={buttonMode}
      renderLeftIcon={() => <ButtonIcon className={iconClassName} />}
      onClick={handleClick}
      disabled={disabled}
      isLoading={isLoading}
    />
  );
};
