import React, { FC } from 'react';
import { cn } from '@/lib/classNames';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { typography } from '@/components/ui/typography';
import { IconNotifications } from '@/components/ui/icons/IconNotifications';
import styles from './NoNotifications.module.scss';

export const NoNotifications: FC = () => {
  const { t } = useTranslation([I18N_CODES.platformNotifications]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <IconNotifications
          className={styles.icon}
        />
      </div>

      <p
        data-qa='no_notifications_message'
        className={cn(typography.platformTextMain, styles.message)}
      >
        {t(`${I18N_CODES.platformNotifications}:no_notifications`)}
      </p>
    </div>
  );
};
