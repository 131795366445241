import React, { FC } from 'react';
import { useGlobalSearchContext } from '@/components/platform/GlobalSearch/GlobalSearchContext';
import { CategoriesSelector } from '@/components/platform/GlobalSearch/components/CategoriesSelector';
import { BottomBar } from '@/components/platform/GlobalSearch/components/BottomBar';
import { InputSection } from '@/components/platform/GlobalSearch/components/InputSection';
import { useSearchByQuery } from '@/components/platform/GlobalSearch/hooks/useSearchByQuery';
import { ResultsSection } from '@/components/platform/GlobalSearch/components/ResultsSection';
import styles from './GlobalSearch.module.scss';

export const GlobalSearch: FC = () => {
  const { selectedCategory } = useGlobalSearchContext();

  const {
    response,
    search,
    loadMore,
    called,
    hasMore,
    loading,
    moreLoading,
  } = useSearchByQuery();

  return (
    <div className={styles.globalSearch}>
      <InputSection
        searchQuery={search}
      />

      {!selectedCategory && (
        <CategoriesSelector />
      )}

      <ResultsSection
        matches={response.matches}
        loadMore={loadMore}
        called={called}
        hasMore={hasMore}
        loading={loading}
        moreLoading={moreLoading}
      />

      <BottomBar totalCount={response.totalMatches} />
    </div>
  );
};
