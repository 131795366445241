import React, {
  FC, memo, useCallback, useMemo,
} from 'react';
import { useRouter, useTranslation } from '@/middleware/i18n';
import { GlobalSearchMatchFragment } from '@/components/platform/GlobalSearch/graphql/generated/globalSearchMatch.fragment.generated';
import { SearchableTaskFragment } from '@/components/platform/GlobalSearch/graphql/generated/searchableTask.fragment.generated';
import { SearchResultWrapper } from '@/components/platform/GlobalSearch/components/SearchableEntities/SearchResultWrapper';
import { IconPracticeTasks } from '@/components/ui/icons/IconPracticeTasks';
import { ToolTip, VerticalPositionMode } from '@/components/ui/ToolTip';
import { IconLock } from '@/components/ui/icons/IconLock';
import { SearchableEntityTitleMarkdown } from '@/components/platform/GlobalSearch/components/SearchableEntities/SearchableEntityTitleMarkdown';
import { EditableMateMarkdown } from '@/components/platform/Markdown';
import { getTranslateCodeByCategory } from '@/components/platform/GlobalSearch/helpers';
import { SearchableEntityType } from '@/controllers/graphql/generated';
import { I18N_CODES } from '@/lib/constants/general';
import { GlobalSearchEvents } from '@/components/platform/GlobalSearch/analytics/globalSearch.events';
import { TopicSections } from '@/controllers/courseUserTopics/courseUserTopics.typedefs';
import { useTopicUrlForUnknownRedirect } from '@/components/platform/Study/pages/urlTools';
import styles from './SearchableTask.module.scss';

interface Props {
  matchedTask: GlobalSearchMatchFragment;
}

export const SearchableTask: FC<Props> = memo(({ matchedTask }) => {
  const { t } = useTranslation([I18N_CODES.globalSearch]);
  const router = useRouter();

  const { task } = (matchedTask.entity as SearchableTaskFragment);

  const taskDescription = useMemo(() => {
    if (!matchedTask.highlights.body?.length) {
      return null;
    }

    return matchedTask.highlights.body.join('...');
  }, [matchedTask.highlights.body]);

  const taskName = useMemo(() => {
    if (!matchedTask.highlights.title?.length) {
      return task?.name;
    }

    return matchedTask.highlights.title[0];
  }, [matchedTask.highlights.title, task?.name]);

  const courseTopic = task?.courseTopics[0];

  const courseName = courseTopic?.course.nameShort;
  const topicName = courseTopic?.name;

  const accessPreferences = courseTopic?.meta.access;

  const link = useTopicUrlForUnknownRedirect({
    courseTopicSlug: courseTopic?.slug,
    moduleSlug: courseTopic?.course?.slug,
    testTaskSlug: task?.slug,
    section: TopicSections.Practice,
  });

  const onClick = useCallback(() => {
    if (!link) {
      return;
    }

    GlobalSearchEvents.sendEvent(
      GlobalSearchEvents.events.searchResultClicked,
      { searchableType: SearchableEntityType.Task },
    );

    router.push(link);
  }, [router, link]);

  if (!task) {
    return null;
  }

  return (
    <SearchResultWrapper
      onClick={onClick}
      disabled={!accessPreferences?.isGranted}
    >
      <div className={styles.wrapper}>
        <header className={styles.header}>
          {accessPreferences?.isGranted
            ? (
              <IconPracticeTasks className={styles.icon} />
            )
            : (
              <ToolTip
                text={accessPreferences?.deniedReason || 'No access'}
                verticalPosition={VerticalPositionMode.Top}
              >
                <IconLock className={styles.iconLock} />
              </ToolTip>
            )}

          <SearchableEntityTitleMarkdown
            title={`${t(getTranslateCodeByCategory(SearchableEntityType.Task))}/${taskName}`}
          />

          {courseTopic && (
            <span className={styles.taskPath}>
              {` - ${courseName}/${topicName}`}
            </span>
          )}
        </header>

        {taskDescription && (
          <main>
            <EditableMateMarkdown source={taskDescription} />
          </main>
        )}
      </div>
    </SearchResultWrapper>
  );
});
