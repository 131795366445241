import { useMemo, useState } from 'react';
import { LeadType } from '@/controllers/graphql/generated';
import {
  COURSE_ADVANTAGES_PER_STEP,
  FEATURES_PER_LEAD_PER_STEP,
  FT_MODAL_TITLE_PER_STEP,
  MODAL_TITLE_ICON_PER_STEP,
} from '@/components/platform/PlatformConsultation/constants';
import {
  CourseAdvantage,
  ModalStep,
  PlatformConsultationButton,
} from '@/components/platform/PlatformConsultation/typedefs';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { PlatformConsultationHelpers } from '@/components/platform/PlatformConsultation/PlatformConsultation.helpers';
import { FCImage } from '@/components/ui/IconEmojies/typedefs';
import {
  usePlatformConsultationRequestedAt,
} from '@/components/platform/Learn/CoursePageContent/components/LimitedAccessBanner/hooks/usePlatformConsultationRequestedAt.hook';
import { DAY } from '@/constants';
import { useLearnQueryParams } from '@/components/platform/Learn/hooks/useLearnQueryParams';
import {
  FtCourseWithMinimalCourseApplicationInfoFragment,
} from '@/components/platform/PlatformConsultation/graphql/generated/ftCourseWithMinimalCourseApplicationInfo.fragment.generated';
import { SubDomains, SubDomainsWithoutFT } from '@/constants/subdomains';
import { useStudyRouteParams } from '@/components/platform/Study/pages/urlTools';

type Options = {
  leadType: LeadType;
  courses: FtCourseWithMinimalCourseApplicationInfoFragment[];
};

type Result = {
  trackFeatures: string[];
  modalTitle: string;
  TitleIcon: FCImage;
  primaryButton: PlatformConsultationButton;
  secondaryButton: PlatformConsultationButton | null;
  courseAdvantages: CourseAdvantage[];
};

export const usePlatformConsultationModal = (
  options: Options,
): Result => {
  let { leadType } = options;
  const { courses } = options;

  const { subDomain } = useSubDomainContext();

  if (SubDomainsWithoutFT.includes(subDomain as SubDomains)) {
    leadType = LeadType.FlexLead;
  }

  const { course: courseSlug } = useLearnQueryParams();
  const {
    professionSlug: professionSlugStudy,
  } = useStudyRouteParams();
  const professionSlug = professionSlugStudy || courseSlug;

  const {
    platformConsultationRequestedAt,
  } = usePlatformConsultationRequestedAt();

  const activeCourse = useMemo(() => (
    courses.find(
      (course) => course.slug === professionSlug,
    )
  ), [courses, professionSlug]);
  const hasDomainActiveFtCourses = useMemo(() => (
    courses.some(
      (course) => course.isActive,
    ) ?? false
  ), [courses]);

  const hasConsultationBeenRequested = Boolean(
    platformConsultationRequestedAt
    && platformConsultationRequestedAt > (Date.now() - DAY),
  );

  const [
    currentStep,
    setCurrentStep,
  ] = useState<ModalStep>(ModalStep.MainTrack);

  const TitleIcon = MODAL_TITLE_ICON_PER_STEP[leadType][currentStep];

  const buttonsPerLeadAndStep = useMemo(() => (
    PlatformConsultationHelpers.getConsultationModalButtonsWithActions({
      changeStepHandler: setCurrentStep,
      subDomain,
      hasConsultationBeenRequested,
      hasDomainActiveFtCourses,
      activeCourse,
      courseSlug: professionSlug,
    })
  ), [
    activeCourse,
    professionSlug,
    hasConsultationBeenRequested,
    hasDomainActiveFtCourses,
    subDomain,
  ]);

  const {
    PRIMARY,
    SECONDARY,
  } = buttonsPerLeadAndStep[leadType][currentStep];

  const modalTitle = FT_MODAL_TITLE_PER_STEP[leadType][currentStep];
  const trackFeatures = (
    FEATURES_PER_LEAD_PER_STEP[leadType][currentStep]
  );

  const courseAdvantages = COURSE_ADVANTAGES_PER_STEP[leadType][currentStep];

  return {
    trackFeatures,
    modalTitle,
    primaryButton: PRIMARY,
    secondaryButton: SECONDARY,
    courseAdvantages,
    TitleIcon,
  };
};
