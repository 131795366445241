import React, { memo, FC } from 'react';
import { PopUpTypeMode } from '@/components/ui/Modal';
import { ConfirmModal } from '@/components/ui/ConfirmModal';
import { CourseAdvantages } from '@/components/platform/PlatformConsultation/CourseAdvantages';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { TrackDescription } from '@/components/platform/PlatformConsultation/TrackDescription';
import { LeadType } from '@/controllers/graphql/generated';
import { usePlatformConsultationModal } from '@/components/platform/PlatformConsultation/usePlatformConsultationModal';
import { Button } from '@/components/ui/Button';
import { emptyFunction } from '@/lib/helpers/functional';
import {
  ButtonTooltipWrapper,
} from '@/components/platform/PlatformConsultation/PlatformConsultationModal/ButtonTooltipWrapper';
import { CONSULTATION_MODAL_DATA_QA } from '@/components/platform/PlatformConsultation/constants';
import {
  FtCourseWithMinimalCourseApplicationInfoFragment,
} from '@/components/platform/PlatformConsultation/graphql/generated/ftCourseWithMinimalCourseApplicationInfo.fragment.generated';
import styles from './PlatformConsultationModal.module.scss';

type Props = {
  isOpen: boolean;
  leadType: LeadType;
  courses: FtCourseWithMinimalCourseApplicationInfoFragment[];
  closeModal: () => void;
};

export const PlatformConsultationModal: FC<Props> = memo(({
  isOpen,
  leadType,
  courses,
  closeModal,
}) => {
  const { t } = useTranslation([I18N_CODES.common]);

  const {
    modalTitle,
    primaryButton,
    secondaryButton,
    trackFeatures,
    TitleIcon,
    courseAdvantages,
  } = usePlatformConsultationModal({
    leadType,
    courses,
  });

  return (
    <ConfirmModal
      isOpen={isOpen}
      dataQa={CONSULTATION_MODAL_DATA_QA.MODAL}
      title={t(`${I18N_CODES.common}:${modalTitle}`)}
      renderTitleIcon={() => <TitleIcon size={32} />}
      type={PopUpTypeMode.Default}
      onConfirm={emptyFunction}
      onCancel={emptyFunction}
      renderCloseButton
      onRequestClose={closeModal}
      renderConfirmButton={() => (
        <ButtonTooltipWrapper tooltipText={primaryButton.tooltipTranslate}>
          <Button
            mode={Button.mode.Primary}
            size={Button.size.Large}
            text={t(`${I18N_CODES.common}:${primaryButton.translate}`)}
            dataQa={primaryButton.dataQa}
            href={primaryButton.href}
            disabled={primaryButton.disabled}
            onClick={primaryButton.onClick}
          />
        </ButtonTooltipWrapper>
      )}
      renderCancelButton={() => {
        if (!secondaryButton) {
          return <></>;
        }

        return (
          <ButtonTooltipWrapper tooltipText={secondaryButton.tooltipTranslate}>
            <Button
              mode={Button.mode.TransparentLight}
              size={Button.size.Large}
              text={t(`${I18N_CODES.common}:${secondaryButton.translate}`)}
              href={secondaryButton.href}
              disabled={secondaryButton.disabled}
              onClick={secondaryButton.onClick}
              dataQa={secondaryButton.dataQa}
            />
          </ButtonTooltipWrapper>
        );
      }}
    >
      <div className={styles.container}>
        <TrackDescription trackFeatures={trackFeatures} />
        <CourseAdvantages advantages={courseAdvantages} />
      </div>
    </ConfirmModal>
  );
});
