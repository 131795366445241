import React, { useCallback, useMemo, useRef } from 'react';
import { cn } from '@/lib/classNames';
import { DropdownMenuItemProps } from '@/components/ui/DrowdownMenu/DropdownMenu.typedefs';
import { DropdownMenu } from '@/components/ui/DrowdownMenu';
import { useIsOpenStateDeprecated } from '@/hooks/useIsOpenStateDeprecated';
import { PopUpPlacement, PopUpPositioningContext } from '@/components/ui/PopUp/PopUp.helpers';
import styles from './DropdownMenuItem.module.scss';

type Props = DropdownMenuItemProps & { isLast?: boolean };

const NESTED_DROPDOWN_HORIZONTAL_OFFSET = -4;

export const DropdownMenuItem: React.FC<Props> = ({
  text,
  onClick,
  items,
  nestedDropdownPopUpProps,
  renderLeftIcon,
  withoutUnderline = false,
  isHighlighted,
  isDisabled,
  skip,
  isLast,
  dataQA,
}) => {
  const ref = useRef<HTMLLIElement | null>(null);

  const [
    isNestedDropdownOpen,
    closeNestedDropdown,
    openNestedDropdown,
  ] = useIsOpenStateDeprecated();

  const isNestedDropdown = items && items.length > 0;

  const nestedPopUpProps = useMemo(() => ({
    preferredPlacement: PopUpPlacement.Right,
    popupIndent: NESTED_DROPDOWN_HORIZONTAL_OFFSET,
    positioningContext: PopUpPositioningContext.Parent,
    ...nestedDropdownPopUpProps,
  }), [nestedDropdownPopUpProps]);

  const onClickHandler = useCallback((
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();

    if (isNestedDropdown) {
      openNestedDropdown();
    }

    onClick(event);
  }, [onClick, openNestedDropdown, isNestedDropdown]);

  if (skip) {
    return null;
  }

  return (
    <li ref={ref} className={styles.itemWrapper}>
      <button
        className={cn(styles.button, { [styles.disabled]: isDisabled })}
        onClick={onClickHandler}
        data-qa={dataQA}
        disabled={isDisabled}
      >
        <p
          className={
            cn(
              styles.content,
              { [styles.withoutBorder]: Boolean(isLast || withoutUnderline) },
            )
          }
        >
          {renderLeftIcon && (
            <span
              className={
                  cn(styles.icon, { [styles.highlighted]: isHighlighted })
                }
            >
              {renderLeftIcon()}
            </span>
          )}

          <span
            className={cn(
              styles.text,
              { [styles.highlighted]: isHighlighted },
            )}
            data-qa={`dropdown-item-${text}`}
          >
            {text}
          </span>
        </p>
      </button>

      {isNestedDropdownOpen && items && (
        <DropdownMenu
          items={items}
          onClose={closeNestedDropdown}
          parentNode={ref.current}
          portalNode={ref.current || undefined}
          popUpProps={nestedPopUpProps}
        />
      )}
    </li>
  );
};
