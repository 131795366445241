import { SELECTED_ALL_COURSES_LEARN_TAB } from '@/controllers/learn/learn.constants';
import { SubDomains, SubDomainsWithoutFT } from '@/constants/subdomains';
import { ROUTES } from '@/controllers/router/router.contants';
import {
  GetModalButtonOptions,
  ModalStep,
  ModalStepButtons, PlatformConsultationButton,
  PlatformConsultationSource,
} from '@/components/platform/PlatformConsultation/typedefs';
import { LeadType } from '@/controllers/graphql/generated';
import {
  NO_ACTIVE_COURSES_TRANSLATE,
  PLATFORM_CONSULTATION_BUTTONS,
} from '@/components/platform/PlatformConsultation/constants';
import { analyticsSDK } from '@/controllers/analytics/analyticsSDK';
import {
  getApplicationButtonTextCode,
} from '@/components/platform/Learn/CoursePageContent/components/LearnHead/utils/getApplicationButtonText';
import { getApplicationLink } from '@/components/platform/Learn/helpers';
import { ConsultationCourses } from '@/components/courseLanding/CourseLanding.constants';
import { allRoutes, renderRoute } from '@/controllers/router/allRoutes';

export class PlatformConsultationHelpers {
  private static goModalNextStep(changeStepHandler: GetModalButtonOptions['changeStepHandler']): void {
    changeStepHandler(ModalStep.AlternativeTrack);
  }

  private static goModalBackStep(changeStepHandler: GetModalButtonOptions['changeStepHandler']): void {
    changeStepHandler(ModalStep.MainTrack);
  }

  static getConsultationHref(options: {
    source: string;
    courseSlug?: string;
  }): string {
    const {
      courseSlug,
      source,
    } = options;

    const searchParams = new URLSearchParams();

    if (courseSlug) {
      searchParams.append(
        'courseSlug',
        courseSlug === SELECTED_ALL_COURSES_LEARN_TAB
          ? ConsultationCourses.GeneralConsultation
          : courseSlug,
      );
    }

    searchParams.append('source', source);

    return `${ROUTES.consultation.index}?${searchParams.toString()}`;
  }

  static getConsultationModalButtonsWithActions(
    options: GetModalButtonOptions,
  ): Record<LeadType, ModalStepButtons> {
    const {
      changeStepHandler,
      courseSlug,
      hasDomainActiveFtCourses,
      subDomain,
    } = options;

    return {
      [LeadType.FtLead]: {
        [ModalStep.MainTrack]: {
          PRIMARY: PlatformConsultationHelpers.getFtLeadApplicationButton(
            options,
          ),
          SECONDARY: {
            ...PLATFORM_CONSULTATION_BUTTONS.checkFlex,
            onClick: () => (
              PlatformConsultationHelpers.goModalNextStep(changeStepHandler)
            ),
          },
        },
        [ModalStep.AlternativeTrack]: {
          PRIMARY: PlatformConsultationHelpers.getConsultationButton(options),
          SECONDARY: {
            ...PLATFORM_CONSULTATION_BUTTONS.backFt,
            onClick: () => (
              PlatformConsultationHelpers.goModalBackStep(changeStepHandler)
            ),
          },
        },
      },
      [LeadType.FlexLead]: {
        [ModalStep.MainTrack]: {
          PRIMARY: PlatformConsultationHelpers.getConsultationButton(options),
          SECONDARY: hasDomainActiveFtCourses
            && !SubDomainsWithoutFT.includes(subDomain as SubDomains)
            ? {
              ...PLATFORM_CONSULTATION_BUTTONS.checkFt,
              onClick: () => (
                PlatformConsultationHelpers.goModalNextStep(changeStepHandler)
              ),
            }
            : null,
        },
        [ModalStep.AlternativeTrack]: {
          PRIMARY: {
            ...PLATFORM_CONSULTATION_BUTTONS.switchFt,
            onClick: () => {
              analyticsSDK.platformConsultation
                .sendLeadRedirectedToFtApplicationEvent({});
            },
            disabled: !hasDomainActiveFtCourses,
            href: getApplicationLink({
              course: options.activeCourse,
              courseSlug,
              source: PlatformConsultationSource.Platform,
            }),
          },
          SECONDARY: {
            ...PLATFORM_CONSULTATION_BUTTONS.backFlex,
            onClick: () => (
              PlatformConsultationHelpers.goModalBackStep(changeStepHandler)
            ),
          },
        },
      },
    };
  }

  private static getFtLeadApplicationButton(
    options: GetModalButtonOptions,
  ): PlatformConsultationButton {
    const {
      activeCourse,
      courseSlug,
      hasDomainActiveFtCourses,
    } = options;

    const sendAnalytics = () => {
      analyticsSDK.platformConsultation
        .sendLeadRedirectedToFtApplicationEvent({});
    };

    if (!courseSlug) {
      return {
        ...PLATFORM_CONSULTATION_BUTTONS.newApplication,
        onClick: sendAnalytics,
        href: renderRoute(
          allRoutes.learn.application.new,
          {
            source: PlatformConsultationSource.Platform,
          },
        ),
        disabled: !hasDomainActiveFtCourses,
        tooltipTranslate: !hasDomainActiveFtCourses
          ? NO_ACTIVE_COURSES_TRANSLATE
          : undefined,
      };
    }

    const applicationTextCode = getApplicationButtonTextCode(
      activeCourse?.courseUser?.status,
    );
    const applicationHref = getApplicationLink({
      course: activeCourse,
      courseSlug,
      source: PlatformConsultationSource.Platform,
    });

    if (!activeCourse) {
      return {
        ...PLATFORM_CONSULTATION_BUTTONS.newApplication,
        translate: applicationTextCode,
        onClick: sendAnalytics,
        href: applicationHref,
        disabled: !hasDomainActiveFtCourses,
        tooltipTranslate: !hasDomainActiveFtCourses
          ? NO_ACTIVE_COURSES_TRANSLATE
          : undefined,
      };
    }

    const isCourseApplicationClosed = (
      activeCourse.isActive === false
    );

    const tooltipTranslate = isCourseApplicationClosed
      ? 'platform_consultation.course_application_closed'
      : undefined;

    return {
      ...PLATFORM_CONSULTATION_BUTTONS.newApplication,
      translate: applicationTextCode,
      onClick: sendAnalytics,
      href: applicationHref,
      disabled: isCourseApplicationClosed,
      tooltipTranslate,
    };
  }

  private static getConsultationButton(
    options: GetModalButtonOptions,
  ): PlatformConsultationButton {
    const {
      subDomain,
      courseSlug,
      hasConsultationBeenRequested,
    } = options;

    const consultationOptions = {
      source: PlatformConsultationSource.Platform,
      subDomain,
      courseSlug,
    };

    const requestConsultationButton = {
      ...PLATFORM_CONSULTATION_BUTTONS.requestConsultation,
      onClick: () => {
        analyticsSDK.platformConsultation
          .sendGetConsultationButtonClickedEvent({});
      },
      href: PlatformConsultationHelpers
        .getConsultationHref(consultationOptions),
    };
    const consultationRequestedButton = {
      ...PLATFORM_CONSULTATION_BUTTONS.consultationRequested,
      disabled: true,
    };

    return hasConsultationBeenRequested
      ? consultationRequestedButton
      : requestConsultationButton;
  }
}
