/**
 * Get the Intl.Locale object from a string with fallback to 'default' in case of error
 * @param locale - The locale string representation
 * @returns The Intl.Locale object
 */
export const getIntlLocale = (locale: string): Intl.Locale => {
  const defaultLocale = 'default';

  try {
    return new Intl.Locale(locale);
  } catch {
    return new Intl.Locale(defaultLocale);
  }
};
