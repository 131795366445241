import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconCamera: FCIcon = (props) => (
  <BaseIcon
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6.99986 9.94002C8.23697 9.94002 9.23986 8.93714 9.23986 7.70002C9.23986 6.4629 8.23697 5.46002 6.99986 5.46002C5.76274 5.46002 4.75986 6.4629 4.75986 7.70002C4.75986 8.93714 5.76274 9.94002 6.99986 9.94002Z" fill="currentColor" />
    <path d="M4.9 0.700012L3.619 2.10001H1.4C0.63 2.10001 0 2.73001 0 3.50001V11.9C0 12.67 0.63 13.3 1.4 13.3H12.6C13.37 13.3 14 12.67 14 11.9V3.50001C14 2.73001 13.37 2.10001 12.6 2.10001H10.381L9.1 0.700012H4.9ZM7 11.2C5.068 11.2 3.5 9.63201 3.5 7.70001C3.5 5.76801 5.068 4.20001 7 4.20001C8.932 4.20001 10.5 5.76801 10.5 7.70001C10.5 9.63201 8.932 11.2 7 11.2Z" fill="currentColor" />
  </BaseIcon>
);
