import { useCallback, useMemo } from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import {
  RatingsFeedbackType,
} from '@/controllers/graphql/generated';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { IconLearnTheory } from '@/components/ui/icons/IconLearnTheory';
import { IconQaBug } from '@/components/ui/icons/IconQaBug';
import { IconBulb } from '@/components/ui/icons/IconBulb';
import { MATE_CANNY_PATH, RedirectToCannySource } from '@/components/platform/LikeRating/ReportProblem.constants';
import { IconChevronRight } from '@/components/ui/icons/IconChevronRight';
import { IconExternalLink } from '@/components/ui/icons/IconExternalLink';
import { analyticsSDK } from '@/controllers/analytics';
import { useCourseTopicContext } from '@/components/platform/Learn/pages/CourseTopic/containers/CourseTopicContext';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { arrangeIf } from '@/lib/helpers/functional';

export interface FeedbackCardsOptions {
  title: string;
  subtitle: string;
  type: RatingsFeedbackType;
  onClick: () => void;
  leftIcon: FCIcon;
  rightIcon: FCIcon;
  dataQa?: string;
}

export const useReportProblemCardsOptions = (
  onClick: () => void,
):(
  FeedbackCardsOptions
)[] => {
  const { t } = useTranslation(I18N_CODES.likeRating);
  const { parentCourseSlug: courseSlug = '' } = useCourseTopicContext();

  const { isKnowelyProduct } = useSubDomainContext();

  const sentRedirectToCannyEvent = useCallback(() => {
    analyticsSDK.reportProblem.sendRedirectToCannyEvent({
      courseSlug,
      source: RedirectToCannySource.Learn,
    });
  }, [courseSlug]);

  const redirect = useCallback((url: string) => window.open(url, '_blank'), []);

  const onCannyOptionClick = useCallback(() => {
    sentRedirectToCannyEvent();
    redirect(MATE_CANNY_PATH);
  }, [redirect, sentRedirectToCannyEvent]);

  return useMemo(() => ([
    {
      title: t(`${I18N_CODES.likeRating}:report_problem_content_title`),
      subtitle: t(`${I18N_CODES.likeRating}:report_problem_content_subtitle`),
      type: RatingsFeedbackType.Content,
      leftIcon: IconLearnTheory,
      rightIcon: IconChevronRight,
      dataQa: 'report-content-problem-card',
      onClick,
    },
    {
      title: t(`${I18N_CODES.likeRating}:report_problem_bug_title`),
      subtitle: t(`${I18N_CODES.likeRating}:report_problem_bug_subtitle`),
      type: RatingsFeedbackType.Performance,
      leftIcon: IconQaBug,
      rightIcon: IconChevronRight,
      dataQa: 'report-bug-card',
      onClick,
    },
    ...arrangeIf(!isKnowelyProduct, {
      title: t(`${I18N_CODES.likeRating}:report_problem_canny_title`),
      subtitle: t(`${I18N_CODES.likeRating}:report_problem_canny_subtitle`),
      type: RatingsFeedbackType.NotSelected,
      leftIcon: IconBulb,
      rightIcon: IconExternalLink,
      dataQa: 'feature-request-card',
      onClick: onCannyOptionClick,
    }),
  ]), [onCannyOptionClick, onClick, t, isKnowelyProduct]);
};
