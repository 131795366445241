import React, { FC, memo, useCallback } from 'react';
import { ToolTip, ToolTipWidthMode, VerticalPositionMode } from '@/components/ui/ToolTip';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';
import { IconSearch } from '@/components/ui/icons/IconSearch';
import { LMS_SEARCH_BUTTON_ONBOARDING_ATTRIBUTE } from '@/components/platform/Onboarding/onboarding.constants';
import { useGlobalSearchContext } from '@/components/platform/GlobalSearch/GlobalSearchContext';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { Divider, DividerReferrals } from '@/components/ui/Divider/Divider';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import styles from './GlobalSearchButton.module.scss';

export const GlobalSearchButton: FC = memo(() => {
  const { brandName } = useSubDomainContext();

  const { t } = useTranslation([I18N_CODES.learn]);

  const { openModal } = useGlobalSearchContext();

  const onClick = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <div className={styles.buttonWrapper}>
      <ToolTip
        text={t(`${I18N_CODES.learn}:lms-search.tooltip-text-with-product`, {
          brandName,
        })}
        verticalPosition={VerticalPositionMode.Bottom}
        widthMode={ToolTipWidthMode.Max}
        messageClassName={styles.tooltipMessage}
      >
        <Button
          data-qa='global_search_button'
          data-onboarding={LMS_SEARCH_BUTTON_ONBOARDING_ATTRIBUTE}
          mode={ButtonMode.TransparentLight}
          size={ButtonSize.Small}
          LeftIcon={IconSearch}
          onClick={onClick}
          className={styles.globalSearchButton}
        />
      </ToolTip>

      <Divider referral={DividerReferrals.Vertical} />
    </div>
  );
});
