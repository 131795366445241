import React, { FC, memo } from 'react';
import { ProfileDropdownRedirectItemI } from '@/components/platform/ProfileDropdown/typedefs';
import { ActiveLink } from '@/components/common/ActiveLink';
import {
  ProfileDropdownItemWrapper,
} from '@/components/platform/ProfileDropdown/ProfileDropdownItem/components/ProfileDropdownItemWrapper';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import styles from '../ProfileDropdownItem.module.scss';

type Props = {
  item: ProfileDropdownRedirectItemI;
};

export const ProfileDropdownRedirectItem: FC<Props> = memo(
  ({ item }) => {
    const {
      route,
      icon,
      text,
      isHiddenOnMobile,
      dataQA,
    } = item;

    return (
      <ProfileDropdownItemWrapper
        dataQA={dataQA}
        isHiddenOnMobile={isHiddenOnMobile}
      >
        <ActiveLink href={route} passHref>
          <a className={styles.itemWrapper}>
            <span className={styles.itemIcon}>
              {icon}
            </span>

            <span
              className={cn(
                styles.itemText,
                typography.platformTextSecondary,
              )}
            >
              {text}
            </span>
          </a>
        </ActiveLink>
      </ProfileDropdownItemWrapper>
    );
  },
);
