import {
  FAVICON_UNREAD_PATH,
  FAVICON_UNREAD_PATH_KNOWELY,
  TAB_FAVICON_PATH,
  TAB_FAVICON_PATH_KNOWELY,
} from '@/components/common/NotificationsIndications/constants';
import { Products } from '@/constants/products';
import { productNameVar } from '@/controllers/subDomain/withSubDomainApp';

export const getFormattedNotificationsCount = (
  notificationsCount: number,
) => (
  notificationsCount >= 100
    ? '99'
    : String(notificationsCount)
);

export const getProductFaviconsPaths = () => {
  const productName = productNameVar();

  return {
    faviconPath: productName === Products.Knowely
      ? TAB_FAVICON_PATH_KNOWELY
      : TAB_FAVICON_PATH,
    faviconUnreadPath: productName === Products.Knowely
      ? FAVICON_UNREAD_PATH_KNOWELY
      : FAVICON_UNREAD_PATH,
  };
};
