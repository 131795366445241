import React, { FC } from 'react';
import { v4 as uuid4 } from 'uuid';

interface Props {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttonName?: string;
  children: React.ReactNode;
  className?: string;
  dataQa?: string;
}

export const OnClick: FC<Props> = React.memo(({
  onClick,
  buttonName,
  children,
  className,
  dataQa,
}) => {
  const [id] = React.useState(uuid4);

  return onClick
    ? (
      <label htmlFor={id} className={className} data-qa={dataQa}>
        {children}
        <button
          style={{ display: 'none' }}
          onClick={onClick}
          id={id}
        >
          {buttonName}
        </button>
      </label>
    )
    : <>{children}</>;
});
