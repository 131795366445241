import React, { FC } from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { SvgProps } from '@/components/ui/icons/typedefs';

export const IconFireFrozen: FC<SvgProps> = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.57812 16.7152L12.0938 9.24951L17.4844 13.1447L18.2031 16.7152L15.6875 19.312H11.0156L9.57812 16.7152Z"
      fill="transparent"
    />

    <path
      d="M18.7582 12.6571C17.3021 8.71869 12.1178 8.50632 13.3698 2.78208C13.4626 2.35735 13.0267 2.02915 12.6743 2.25116C9.3077 4.31691 6.88712 8.45806 8.91818 13.8831C9.08512 14.3271 8.58431 14.7422 8.22261 14.4526C6.54397 13.1301 6.36776 11.2285 6.51615 9.8674C6.57179 9.36544 5.94114 9.12412 5.67219 9.5392C5.04154 10.5431 4.40161 12.1648 4.40161 14.607C4.75403 20.0127 9.14076 21.6731 10.7174 21.8854C12.971 22.1847 15.4102 21.7503 17.163 20.0803C19.0921 18.2173 19.7969 15.2441 18.7582 12.6571ZM10.1517 17.5126C11.4872 17.1747 12.1734 16.1708 12.3589 15.2827C12.665 13.9024 11.4686 12.5509 12.2755 10.3694C12.5815 12.1745 15.3082 13.3039 15.3082 15.2731C15.3823 17.7153 12.8412 19.81 10.1517 17.5126Z"
      fill="#05A8FF"
    />

    <path
      d="M3.35938 8.06215V17.262C3.35938 18.1525 4.37787 18.6591 5.08802 18.1217L16.4197 9.54773C16.7781 9.27652 16.9307 8.81138 16.8025 8.38055L14.8308 1.75283C14.5982 0.97107 13.6153 0.720692 13.0371 1.2959L6.28228 8.01514C5.86734 8.42789 5.16627 8.28763 4.94208 7.747C4.5973 6.91558 3.35938 7.16208 3.35938 8.06215Z"
      fill="#93E5FD"
      fillOpacity="0.5"
    />
  </BaseIcon>
);
