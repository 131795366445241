export function getDateByDays(
  startDate: Date | number,
  daysToIterate: number,
): Date {
  const castedStartDate = new Date(startDate);

  return new Date(
    castedStartDate.setDate(
      castedStartDate.getDate() + daysToIterate,
    ),
  );
}
