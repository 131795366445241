import { sendAmplitudeData } from '@/controllers/amplitude/amplitude.helpers';
import { GoogleAnalyticsClient } from '@/controllers/analytics/analytics.client/GoogleAnalytics.client';

export const GlobalSearchEvents = {
  sendEvent: (event: string, data?: Record<string, any>) => {
    const eventName = `global_search_${event}`;

    sendAmplitudeData(eventName, data);
    GoogleAnalyticsClient.getInstance().sendEvent(eventName, data);
  },
  events: {
    searchModalOpened: 'search_modal_opened',
    searchableTypeSelected: 'searchable_type_selected',
    searchResultClicked: 'search_result_clicked',
    sortOptionChanged: 'sort_option_changed',
  },
};
