import { CreateKnowelySubscriptionPaymentMutation } from '@/components/paymentProviders/graphql/generated/createKnowelySubscrpitionPayment.mutation.generated';
import { ProcessKnowelySubscriptionFreeTrialMutation } from '@/components/paymentProviders/graphql/generated/processKnowelySubscriptionFreeTrial.mutation.generated';
import { SubscriptionPaymentProcessingResult } from '@/components/platform/SubscriptionProduct/components/SubscriptionPlan/typedefs';

export const mapSubscriptionPaymentResult = (
  data: CreateKnowelySubscriptionPaymentMutation
    | ProcessKnowelySubscriptionFreeTrialMutation
    | undefined
    | null,
  isFreeTrialPlan: boolean,
): SubscriptionPaymentProcessingResult | null => {
  let result: SubscriptionPaymentProcessingResult;

  if (!data) {
    return null;
  }

  if (isFreeTrialPlan) {
    const verifiedData = data as ProcessKnowelySubscriptionFreeTrialMutation;

    const mutationResult = verifiedData?.processKnowelySubscriptionFreeTrial;

    result = {
      redirect: mutationResult?.redirectUrl,
      clientSecret: mutationResult?.clientSecret,
    };
  } else {
    const verifiedData = data as CreateKnowelySubscriptionPaymentMutation;

    const mutationResult = verifiedData?.processKnowelySubscriptionPayment;

    result = {
      redirect: mutationResult?.redirect,
      clientSecret: mutationResult?.clientSecret,
    };
  }

  return result;
};
