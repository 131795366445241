import { SearchableEntityType } from '@/controllers/graphql/generated';
import { I18N_CODES } from '@/lib/constants/general';

export const getDeduplicationKey = (type: string, id: number) => `${type}::${id}`;

export const getTranslateCodeByCategory = (category: SearchableEntityType) => {
  switch (category) {
    case SearchableEntityType.Theory:
      return `${I18N_CODES.globalSearch}:category_name.theory`;
    case SearchableEntityType.Task:
      return `${I18N_CODES.globalSearch}:category_name.task`;
    case SearchableEntityType.Video:
      return `${I18N_CODES.globalSearch}:category_name.video`;
    case SearchableEntityType.Chat:
      return `${I18N_CODES.globalSearch}:category_name.chat`;
    case SearchableEntityType.ChatMessage:
      return `${I18N_CODES.globalSearch}:category_name.chat_message`;
    default:
      return '';
  }
};
