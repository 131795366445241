import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconTaskAIReview: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    dataQa='task-on-ai-review-icon'
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15 16.0141V8H16.5138V16.0141H15ZM8.94876 8L6.5 16.0141H8.0583L8.56575 14.3445H11.5834L12.0987 16.0141H13.657L11.2083 8H8.94876ZM11.1471 12.9309L10.0629 9.41852L8.9954 12.9309H11.1471Z"
    />
  </BaseIcon>
);
