import { useEffect, useRef } from 'react';
import { useIntersectionObserver } from '@/hooks/useIntersectionObserver';

export const useNotificationsInfiniteScroll = (
  hasMoreOlderNotifications: boolean,
  loadOlderNotifications: () => void,
) => {
  const notificationListRef = useRef<HTMLDivElement | null>(null);

  const {
    inView: newerMessagesTriggerInView,
    setInView: setNewerMessagesTriggerInView,
    setElementRef: newerNotificationsTriggerRef,
  } = useIntersectionObserver({
    rootMargin: '0px 0px 100px 0px',
    noUnobserve: true,
    root: notificationListRef.current,
    initialState: false,
  });

  useEffect(() => {
    if (newerMessagesTriggerInView && hasMoreOlderNotifications) {
      loadOlderNotifications();
      setNewerMessagesTriggerInView(false);
    }
  }, [
    newerMessagesTriggerInView,
    hasMoreOlderNotifications,
    loadOlderNotifications,
    setNewerMessagesTriggerInView,
  ]);

  return {
    listRef: notificationListRef,
    newerNotificationsTriggerRef,
  };
};
