import React, { FC, useCallback } from 'react';
import { MESSAGES } from '@/lib/constants/messages';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import { SignedAsUser } from '@/components/platform/SignedAsUser/SignedAsUser';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import {
  useSignOutFromUserMutation,
} from '@/components/platform/SignedAsUser/generated/SignOutFromUser.mutation.generated';
import {
  useAdminUserQuery,
} from '@/components/platform/SignedAsUser/generated/AdminUser.query.generated';
import { useLearningRootForRedirect } from '@/components/platform/Study/pages/urlTools';

export const SignedAsUserContainer: FC = React.memo(() => {
  const [authUser] = useAuthUser({ ssr: true });
  const { showMessage } = useFlashMessage();
  const [signOut] = useSignOutFromUserMutation();
  const { data, error, loading } = useAdminUserQuery();
  const learningRoot = useLearningRootForRedirect();

  const handleSignOut = useCallback(async () => {
    try {
      await signOut();

      if (!authUser) {
        return;
      }

      window.location.href = learningRoot;
    } catch (e) {
      showMessage(MESSAGES.general.unexpectedError);
    }
  }, [signOut, authUser, showMessage, learningRoot]);

  if (!data || !data.adminUser || error || loading || !authUser) {
    return null;
  }

  return (
    <SignedAsUser
      user={authUser}
      adminUser={data.adminUser}
      onSignOut={handleSignOut}
    />
  );
});
