import { useCallback, useEffect } from 'react';

export const useShowWarningOnTabClose = (isTimerStarted: boolean): void => {
  const showWarning = useCallback((event: BeforeUnloadEvent) => {
    if (!isTimerStarted) {
      return;
    }

    event.preventDefault();

    Object.assign(event, {
      returnValue: '',
    });
  }, [
    isTimerStarted,
  ]);

  useEffect(() => {
    window.addEventListener('beforeunload', showWarning);

    return () => {
      window.removeEventListener('beforeunload', showWarning);
    };
  }, [
    showWarning,
  ]);
};
