import React, { FC } from 'react';
import { AbstractNotificationFragment } from '@/components/platform/Notifications/graphql/generated/AbstractNotification.fragment.generated';
import { NotificationsItem } from '../NotificationsItem';
import styles from './NotificationsList.module.scss';

interface Props {
  notifications: AbstractNotificationFragment[];
}

export const NotificationsList: FC<Props> = ({
  notifications,
}) => (
  <ul>
    {notifications.map((notification) => (
      <li
        key={notification.id}
        data-qa={`notification_${notification.status}`}
        className={styles.notification}
      >
        <NotificationsItem
          notification={notification}
        />
      </li>
    ))}
  </ul>
);
