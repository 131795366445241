import { leadZero } from '@/lib/leadZero';

export const formatTime = (miliseconds: number, hoursLeadZeros?: number) => {
  const secondsTotal = Math.floor(miliseconds / 1000);
  const hours = Math.floor(secondsTotal / 3600);
  const minutes = Math.floor((secondsTotal - (hours * 3600)) / 60);
  const seconds = secondsTotal - (hours * 3600) - (minutes * 60);

  const hoursString = hours > 99
    ? hours
    : leadZero(hours, hoursLeadZeros ?? 2);

  return `${hoursString}:${leadZero(minutes, 2)}:${leadZero(seconds, 2)}`;
};
