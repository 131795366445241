import type { L10nsStrings } from '@/components/platform/common/HumanReadableDate/HumanReadableDate.typedefs';

const strings: L10nsStrings = {
  prefixAgo: null,
  prefixFromNow: 'em',
  suffixAgo: 'atrás',
  suffixFromNow: null,
  seconds: 'agora mesmo',
  minute: '1min',
  minutes: `%dmin`,
  hour: '1h',
  hours: `%dh`,
  day: '1d',
  days: `%dd`,
  month: '1M',
  months: `%dM`,
  year: '1a',
  years: `%da`,
};

export default strings;
