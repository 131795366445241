import { useUserNotificationsQuery } from '@/components/platform/Notifications/graphql/generated/AuthUserNotifications.query.generated';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useLoadOlderNotifications, USER_NOTIFICATIONS_LIMIT } from '@/components/platform/Notifications/hooks/useLoadOlderNotifications';

export const useNotifications = () => {
  const { language } = useSubDomainContext();

  const {
    data, loading, fetchMore,
  } = useUserNotificationsQuery({
    variables: {
      limit: USER_NOTIFICATIONS_LIMIT,
      lang: language,
    },
  });

  const notifications = data?.authUser?.notifications.edges || [];
  const unreadNotificationsCount = data?.authUser?.notifications.unreadCount;

  const {
    load: loadOlderNotifications,
    loading: olderNotificationsLoading,
    hasMore: hasMoreOlderNotifications,
  } = useLoadOlderNotifications(fetchMore);

  return {
    notifications,
    unreadNotificationsCount,
    loading,
    loadOlderNotifications,
    olderNotificationsLoading,
    hasMoreOlderNotifications,
  };
};
