import { useCallback } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';

export const useDayName = () => {
  const { t } = useTranslation([I18N_CODES.chat]);

  return useCallback((dayOfWeek) => {
    switch (dayOfWeek) {
      case 0:
        return t(`${I18N_CODES.chat}:day_of_week.sunday`);
      case 1:
        return t(`${I18N_CODES.chat}:day_of_week.monday`);
      case 2:
        return t(`${I18N_CODES.chat}:day_of_week.tuesday`);
      case 3:
        return t(`${I18N_CODES.chat}:day_of_week.wednesday`);
      case 4:
        return t(`${I18N_CODES.chat}:day_of_week.thursday`);
      case 5:
        return t(`${I18N_CODES.chat}:day_of_week.friday`);
      case 6:
        return t(`${I18N_CODES.chat}:day_of_week.saturday`);
      default:
        return '';
    }
  }, [t]);
};
