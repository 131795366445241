import React, { FC, useCallback, useEffect } from 'react';
import { cn } from '@/lib/classNames';
import { NotificationsDropdownContent } from '@/components/platform/Notifications/components/NotificationsContent/NotificationsDropdownContent/NotificationsDropDownContent';
import { Dropdown } from '@/components/common/Dropdown';
import { PlatformEvents } from '@/controllers/platform/platform.events';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import { MESSAGE_TYPES } from '@/lib/constants/messages';
import { useSessionStorage } from '@/hooks/useLocalStorage';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation, useRouter } from '@/middleware/i18n';
import { typography } from '@/components/ui/typography';
import { SESSION_STORAGE_KEYS } from '@/constants/sessionStorage';
import { ROUTES } from '@/controllers/router/router.contants';
import { HumanReadableDate } from '@/components/platform/common/HumanReadableDate';
import { useLastUnreadNotification } from '@/components/platform/Notifications/hooks/useUnreadNotificationsCount';
import styles from '@/components/platform/Notifications/Notifications.module.scss';
import { NotificationsButton } from '@/components/platform/Notifications/components/NotificationsButton';
import { Selectors } from '@/lib/selectors';

interface Props {
  isMobilePageOpened: boolean;
}

export const Notifications: FC<Props> = ({
  isMobilePageOpened,
}) => {
  const {
    unreadNotificationsCount,
    lastUnreadNotification,
    loading,
  } = useLastUnreadNotification();

  const { showMessage } = useFlashMessage();
  const { language } = useSubDomainContext();
  const { t } = useTranslation([I18N_CODES.common]);

  const router = useRouter();

  const [
    initNotificationMessageWasShown,
    setInitNotificationMessageWasShown,
  ] = useSessionStorage<boolean>(
    SESSION_STORAGE_KEYS.initNotificationMessageWasShown,
    false,
  );

  useEffect(() => {
    if (loading) {
      return;
    }

    if (unreadNotificationsCount && !initNotificationMessageWasShown) {
      const renderDescription = () => (
        <>
          <p className={cn(typography.platformTextSecondary, 'mb-8')}>
            {lastUnreadNotification?.message}
          </p>
          <HumanReadableDate
            language={language}
            time={lastUnreadNotification?.createdAt}
          />
        </>
      );

      showMessage({
        type: MESSAGE_TYPES.info,
        renderDescription,
      });

      setInitNotificationMessageWasShown(true);
    }

    if (unreadNotificationsCount === 0 && !initNotificationMessageWasShown) {
      setInitNotificationMessageWasShown(true);
    }
  }, [
    unreadNotificationsCount,
    initNotificationMessageWasShown,
    language,
    loading,
    lastUnreadNotification,
    setInitNotificationMessageWasShown,
    showMessage,
    t,
  ]);

  const dropdownRenderTrigger = useCallback((onClick) => (
    <NotificationsButton
      unreadNotificationsCount={unreadNotificationsCount}
      onClick={onClick}
    />
  ), [unreadNotificationsCount]);

  const dropdownRenderContent = useCallback(({ isActive }) => (
    <div className={cn(
      styles.dropdownContent,
      { [Selectors.DropdownOpened]: isActive },
    )}
    >
      {isActive && (
        <NotificationsDropdownContent />
      )}
    </div>
  ), []);

  const sendOpenNotificationsEvent = useCallback(() => {
    PlatformEvents.sendEvent(
      PlatformEvents.events.notificationsOpened,
    );
  }, []);

  const redirectToNotificationsPage = useCallback(() => (
    router.push(ROUTES.user.notifications)
  ), [router]);

  return (
    <>
      <div className={styles.notificationsBlock} data-qa="notifications-web-block">
        <Dropdown
          isFullWidth
          renderTrigger={dropdownRenderTrigger}
          renderContent={dropdownRenderContent}
          sendEvent={sendOpenNotificationsEvent}
        />
      </div>

      <div className={styles.notificationsMobileBlock} data-qa="notifications-mobile-block">
        <NotificationsButton
          unreadNotificationsCount={unreadNotificationsCount}
          onClick={redirectToNotificationsPage}
          mobilePageOpened={isMobilePageOpened}
        />
      </div>
    </>
  );
};
