import { CourseUserStatus } from '@/controllers/graphql/generated';

export const getApplicationButtonTextCode = (
  status?: CourseUserStatus | null,
) => {
  switch (status) {
    case CourseUserStatus.New:
      return 'continue_application';

    case CourseUserStatus.Rejected:
      return 're_apply';

    default:
      return 'new_application';
  }
};
