import { ReactChild } from 'react';

export enum ProfileDropdownItemType {
  Redirect = 'redirect',
  Action = 'action',
}

type BaseProps = {
  id: number;
  text: string;
  icon: ReactChild;
  condition?: boolean;
  isHiddenOnMobile?: boolean;
  dataQA?: string;
  iconOnHover?: ReactChild;
};

export type ProfileDropdownRedirectItemI = BaseProps & {
  type: ProfileDropdownItemType.Redirect;
  route: string;
};

export type ProfileDropdownActionItemI = BaseProps & {
  type: ProfileDropdownItemType.Action;
  onAction: () => void;
};

export type ProfileDropdownItemI = ProfileDropdownRedirectItemI
  | ProfileDropdownActionItemI;
