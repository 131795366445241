import { useUnreadChatMessagesCountQuery } from '@/components/platform/Chat/graphql/generated/unreadChatMessagesCount.query.generated';

export const useUnreadChatMessagesCount = () => {
  const { data } = useUnreadChatMessagesCountQuery();

  return data || {
    hasUnreadChatMessages: false,
    unreadThreadRepliesCount: 0,
  };
};
