import React, {
  FC, memo, useState, useCallback,
} from 'react';
import { useIsOpenStateDeprecated } from '@/hooks/useIsOpenStateDeprecated';
import { SearchableEntityType } from '@/controllers/graphql/generated';
import { GlobalSearchContextProvider } from '@/components/platform/GlobalSearch/GlobalSearchContext';
import { GlobalSearchEvents } from '@/components/platform/GlobalSearch/analytics/globalSearch.events';

export const GlobalSearchProvider: FC = memo(({ children }) => {
  const [
    isSearchModalOpened,
    closeSearchModal,
    openSearchModal,
  ] = useIsOpenStateDeprecated();

  const [
    selectedCategory,
    setSelectedCategory,
  ] = useState<SearchableEntityType | null>(null);

  const handleSelectedCategoryChange = useCallback(
    (type: SearchableEntityType | null) => {
      setSelectedCategory(type);

      if (type) {
        GlobalSearchEvents.sendEvent(
          GlobalSearchEvents.events.searchableTypeSelected,
          { searchableType: type },
        );
      }
    },
    [],
  );

  const handleModalOpen = useCallback(
    (initiallySelectedCategory?: SearchableEntityType) => {
      handleSelectedCategoryChange(initiallySelectedCategory || null);

      openSearchModal();

      GlobalSearchEvents.sendEvent(
        GlobalSearchEvents.events.searchModalOpened,
        { searchableType: initiallySelectedCategory || null },
      );
    },
    [openSearchModal, handleSelectedCategoryChange],
  );

  return (
    <GlobalSearchContextProvider
      isSearchModalOpened={isSearchModalOpened}
      openModal={handleModalOpen}
      closeModal={closeSearchModal}
      selectedCategory={selectedCategory}
      setSelectedCategory={handleSelectedCategoryChange}
    >
      {children}
    </GlobalSearchContextProvider>
  );
});
