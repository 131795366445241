import React, { FC } from 'react';
import { GlobalSearchMatchFragment } from '@/components/platform/GlobalSearch/graphql/generated/globalSearchMatch.fragment.generated';
import { useGlobalSearchContext } from '@/components/platform/GlobalSearch/GlobalSearchContext';
import { SearchResult } from '@/components/platform/GlobalSearch/components/SearchableEntities/SearchResult';
import { PaginationTrigger } from '@/components/platform/GlobalSearch/components/ResultsSection/PaginationTrigger';
import { typography } from '@/components/ui/typography';
import { Loader } from '@/components/ui/Loader';
import { getDeduplicationKey } from '@/components/platform/GlobalSearch/helpers';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { IconSearch } from '@/components/ui/icons/IconSearch';
import styles from './ResultsSection.module.scss';

interface Props {
  matches: GlobalSearchMatchFragment[];
  loadMore: (onCompleted?: () => void) => Promise<void>;
  called?: boolean;
  hasMore?: boolean;
  loading?: boolean;
  moreLoading?: boolean;
}

export const ResultsSection: FC<Props> = ({
  matches,
  loadMore,
  called,
  hasMore,
  loading,
  moreLoading,
}) => {
  const { t } = useTranslation([I18N_CODES.globalSearch]);

  const { selectedCategory } = useGlobalSearchContext();

  if (loading) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader size={32} loading />
      </div>
    );
  }

  if (called && !matches.length) {
    return (
      <div
        className={styles.noResultsBlock}
        data-qa="no-results-message"
      >
        <p className={typography.platformH4}>
          {t(`${I18N_CODES.globalSearch}:results_block.no_results`)}
        </p>

        <p className={typography.platformTextSecondary}>
          {t(`${I18N_CODES.globalSearch}:results_block.try_something_else`)}
        </p>
      </div>
    );
  }

  if (!matches.length && Boolean(selectedCategory)) {
    return (
      <div className={styles.startTypingBlock}>
        <IconSearch />

        <p>
          {t(`${I18N_CODES.globalSearch}:results_block.start_typing`)}
        </p>
      </div>
    );
  }

  return (
    <ul className={styles.list}>
      {matches.map((match) => (
        <li
          data-qa="search-result-item"
          data-entity-type={match.type}
          key={getDeduplicationKey(match.type, match.id)}
        >
          <SearchResult match={match} />
        </li>
      ))}

      {hasMore && (
        <li>
          <PaginationTrigger
            onTrigger={loadMore}
            loading={moreLoading}
          />
        </li>
      )}
    </ul>
  );
};
