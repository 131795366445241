import { StudentStatus, UserEnglishLevel } from '@/controllers/graphql/generated';

export const PAYMENT_FORBIDDEN_STATUSES = [
  StudentStatus.New,
  StudentStatus.PreCourse,
  StudentStatus.Studying,
  StudentStatus.Employment,
  StudentStatus.FTerminatedContract,
];

export const ACTIVE_STUDENT_STATUSES = [
  StudentStatus.CareerBreak,
  StudentStatus.Employment,
  StudentStatus.JobChange,
  StudentStatus.PreCourse,
  StudentStatus.Studying,
  StudentStatus.Working,
  StudentStatus.UnpaidInternship,
  StudentStatus.PreEmployment,
  StudentStatus.CourseCompleted,
];

export const FORMER_STUDENT_STATUSES = [
  StudentStatus.FQuitItAfterJobOffer,
  StudentStatus.FStoppedPaying,
  StudentStatus.FTerminatedContract,
  StudentStatus.FUnEmployed,
  StudentStatus.FDisappearedWhileStudying,
];

export const INACTIVE_STUDENT_STATUSES = Object.values(StudentStatus)
  .filter((status) => !ACTIVE_STUDENT_STATUSES.includes(status));

export const STUDENT_EMPLOYMENT_STATUSES = [
  StudentStatus.Employment,
  StudentStatus.JobChange,
  StudentStatus.Working,
  StudentStatus.CareerBreak,
  StudentStatus.UnpaidInternship,
  StudentStatus.Graduated,
];

export const STUDENT_CAREER_STATUSES = [
  StudentStatus.Employment,
  StudentStatus.JobChange,
  StudentStatus.Working,
  StudentStatus.UnpaidInternship,
  StudentStatus.CareerBreak,
  StudentStatus.CareerBreakArmedForces,
  StudentStatus.Graduated,
  StudentStatus.FQuitItAfterJobOffer,
  StudentStatus.FStoppedPaying,
  StudentStatus.FUnEmployed,
];

export const STUDENT_NORMALIZE_ENGLISH_LEVEL: {
  [key in UserEnglishLevel]: string;
} = {
  [UserEnglishLevel.Beginner]: 'Beginner',
  [UserEnglishLevel.LowElementary]: 'Low Elementary',
  [UserEnglishLevel.HighElementary]: 'High Elementary',
  [UserEnglishLevel.LowPreIntermediate]: 'Low Pre-Intermediate',
  [UserEnglishLevel.HighPreIntermediate]: 'High Pre-Intermediate',
  [UserEnglishLevel.LowIntermediate]: 'Low Intermediate',
  [UserEnglishLevel.HighIntermediate]: 'High Intermediate',
  [UserEnglishLevel.UpperIntermediate]: 'Upper Intermediate',
  [UserEnglishLevel.Advanced]: 'Advanced',
  [UserEnglishLevel.Unspecified]: 'Unspecified',
};

export enum CRLFEnglishLevels {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  Unspecified = '',
}

export const STUDENT_NORMALIZE_CEFR_ENGLISH_LEVEL: {
  [key in UserEnglishLevel]: CRLFEnglishLevels;
} = {
  [UserEnglishLevel.Beginner]: CRLFEnglishLevels.A1,
  [UserEnglishLevel.LowElementary]: CRLFEnglishLevels.A1,
  [UserEnglishLevel.HighElementary]: CRLFEnglishLevels.A2,
  [UserEnglishLevel.LowPreIntermediate]: CRLFEnglishLevels.A2,
  [UserEnglishLevel.HighPreIntermediate]: CRLFEnglishLevels.A2,
  [UserEnglishLevel.LowIntermediate]: CRLFEnglishLevels.B1,
  [UserEnglishLevel.HighIntermediate]: CRLFEnglishLevels.B1,
  [UserEnglishLevel.UpperIntermediate]: CRLFEnglishLevels.B2,
  [UserEnglishLevel.Advanced]: CRLFEnglishLevels.C1,
  [UserEnglishLevel.Unspecified]: CRLFEnglishLevels.Unspecified,
};
