import React, {
  FC, useCallback, useMemo, useState,
} from 'react';
import { cn } from '@/lib';
import { Button } from '@/components/ui/Button';
import { IconEdit } from '@/components/ui/icons/IconEdit';
import { IconTimer } from '@/components/ui/icons/IconTimer';
import { typography } from '@/components/ui/typography';
import { IconCross } from '@/components/ui/icons/IconCross';
import { IconCheck } from '@/components/ui/icons/IconCheck';
import { IconSize } from '@/components/ui/icons/BaseIcon';
import { IconInfo } from '@/components/ui/icons/IconInfo';
import { InputTimeDurationUI } from '@/components/ui/FormElements/FormInputs/InputTimeDuration';
import { WorkingTimeBaseFragment } from '@/components/platform/TimeTracker/graphql/generated/WorkingTimeBase.fragment.generated';
import { useReduceWorkingTimeMutation } from '@/components/platform/TimeTracker/graphql/generated/reduceWorkingTime.mutation.generated';
import { formatTime } from '@/components/platform/TimeTracker/TimeTracker.helpers';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { TimeTrackerHarness } from '@/components/platform/TimeTracker/TimeTracker.harness';
import styles from './LastTrackedTime.module.scss';

type Props = {
  lastTrackedTime?: WorkingTimeBaseFragment | null;
  onRefetchTime: () => Promise<void>;
  isButtonDisabled?: boolean;
};

export const LastTrackedTime: FC<Props> = ({
  lastTrackedTime,
  onRefetchTime,
  isButtonDisabled,
}) => {
  const { t } = useTranslation([I18N_CODES.common]);
  const [reduceWorkingTime, { loading }] = useReduceWorkingTimeMutation();

  const lastTrackedDurationReadable = useMemo(() => (
    lastTrackedTime?.finishedAt && lastTrackedTime?.startedAt
      ? formatTime(lastTrackedTime.finishedAt - lastTrackedTime.startedAt)
      : 'N/A'
  ), [lastTrackedTime]);

  const lastTrackedDuration = useMemo(() => (
    lastTrackedTime?.finishedAt && lastTrackedTime?.startedAt
      ? lastTrackedTime.finishedAt - lastTrackedTime.startedAt
      : 0
  ), [lastTrackedTime]);

  const [
    newDuration,
    setNewDuration,
  ] = useState(lastTrackedDuration);

  const [isEditEnabled, setIsEditEnabled] = useState(false);

  const hasValidationError = useMemo(() => (
    lastTrackedDuration < newDuration
  ), [lastTrackedDuration, newDuration]);

  const shouldButtonsBeDisabled
    = hasValidationError
    || loading
    || lastTrackedDuration === newDuration
    || lastTrackedDuration - (lastTrackedDuration % 1000) === newDuration;

  const handleCancel = useCallback(() => {
    setIsEditEnabled(false);
  }, [setIsEditEnabled]);

  const handleEdit = useCallback(() => {
    setIsEditEnabled(true);
    setNewDuration(lastTrackedDuration);
  }, [lastTrackedDuration]);

  const reduceLastWorkingTime = async () => {
    if (!lastTrackedTime) {
      return;
    }

    await reduceWorkingTime({
      variables: {
        id: lastTrackedTime.id,
        newDuration,
      },
    });

    onRefetchTime();

    setIsEditEnabled(false);
  };

  if (!lastTrackedTime) {
    return null;
  }

  const {
    lastTrackedTimeDataQa,
    editLastTrackedTimeButtonDataQa,
  } = new TimeTrackerHarness();

  return (
    <div
      data-qa={lastTrackedTimeDataQa}
      className={styles.wrapper}
    >
      <div className={cn(typography.platformH5, styles.title)}>
        {t(`${I18N_CODES.common}:time_tracker_last_tracked_time`)}
      </div>

      <div className={styles.row}>
        {isEditEnabled
          ? (
            <div>
              <div className={cn(styles.row, 'mb-8')}>
                <span className={styles.timerIcon}>
                  <IconTimer size={IconSize.Small} />
                </span>

                <div className={styles.form}>
                  <InputTimeDurationUI
                    setNewDuration={setNewDuration}
                    value={newDuration}
                    twoCharactersInHours
                  />
                </div>

                <Button
                  mode={Button.mode.Secondary}
                  LeftIcon={IconCross}
                  onClick={handleCancel}
                  disabled={loading}
                  data-qa="cancel-reduce-time-button"
                />

                <Button
                  LeftIcon={IconCheck}
                  onClick={reduceLastWorkingTime}
                  disabled={shouldButtonsBeDisabled}
                  data-qa="approve-reduce-time-button"
                />
              </div>

              <div className={cn(
                styles.info,
                typography.platformTextSmall,
                { [styles.red]: hasValidationError },
              )}
              >
                {hasValidationError && (
                  <IconInfo size={IconSize.Small} className='mr-4' />
                )}

                <span data-qa="time_tracker_reduce_time_info">
                  {t(`${I18N_CODES.common}:time_tracker_reduce_time_info`)}
                </span>
              </div>
            </div>
          )
          : (
            <>
              <div className={styles.recentTrackedTime}>
                <span className={cn(styles.timerIcon, 'mr-8')}>
                  <IconTimer size={IconSize.Small} />
                </span>

                {lastTrackedDurationReadable}
              </div>

              <Button
                dataQa={editLastTrackedTimeButtonDataQa}
                mode={Button.mode.TransparentLight}
                LeftIcon={IconEdit}
                onClick={handleEdit}
                disabled={isButtonDisabled}
              />
            </>
          )}
      </div>
    </div>
  );
};
