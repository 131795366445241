import { useCallback, useState } from 'react';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import { MESSAGE_TYPES } from '@/lib/constants/messages';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import {
  UserNotificationsQuery,
  useUserNotificationsQuery,
} from '@/components/platform/Notifications/graphql/generated/AuthUserNotifications.query.generated';

export const USER_NOTIFICATIONS_LIMIT = 24;

export const useLoadOlderNotifications = (
  fetchMoreNotifications: ReturnType<typeof useUserNotificationsQuery>['fetchMore'],
) => {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const { t } = useTranslation([I18N_CODES.platformNotifications]);

  const { language } = useSubDomainContext();

  const flashMessage = useFlashMessage();

  const load = useCallback(async (
    onCompleted = () => { /* empty */ },
  ) => {
    if (loading || !hasMore) {
      return;
    }

    setLoading(true);
    setPage((prevPage) => prevPage + 1);

    try {
      await fetchMoreNotifications({
        variables: {
          limit: USER_NOTIFICATIONS_LIMIT,
          offset: USER_NOTIFICATIONS_LIMIT * page,
          lang: language,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          const prevNotifications = prev.authUser?.notifications;
          const olderNotifications = fetchMoreResult.authUser?.notifications;

          if (olderNotifications?.edges.length === 0) {
            setHasMore(false);

            return prev;
          }

          onCompleted();

          return {
            ...prev,
            authUser: {
              ...prev.authUser,
              notifications: {
                ...prevNotifications,
                edges: [
                  ...(prevNotifications?.edges || []),
                  ...(olderNotifications?.edges || []),
                ],
              },
            },
          } as UserNotificationsQuery;
        },
      });
    } catch (e) {
      flashMessage.showMessage({
        type: MESSAGE_TYPES.error,
        heading: t((`${I18N_CODES.platformNotifications}:load_notifications_error_heading`)),
        text: t((`${I18N_CODES.platformNotifications}:load_notifications_error_text`)),
      });
    } finally {
      setLoading(false);
    }
  }, [
    loading,
    hasMore,
    flashMessage,
    fetchMoreNotifications,
    language,
    page,
    t,
  ]);

  return {
    load,
    loading,
    hasMore,
    setHasMore,
  };
};
