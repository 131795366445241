import { AnyFunction } from '@mate-academy/react-di';
import { RefObject, useCallback, useEffect } from 'react';
import { DOMEvent } from '@/constants/dom.events';

export const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  callback: AnyFunction,
  predicate = true,
) => {
  const handleClickOutside = useCallback((event: Event) => {
    const isClickedOutside = ref.current && (
      !ref.current.contains(event.target as Node)
    );

    if (isClickedOutside) {
      callback();
    }
  }, [callback, ref]);

  useEffect(() => {
    if (predicate) {
      document.addEventListener(DOMEvent.Click, handleClickOutside, true);
    }

    return () => {
      document.removeEventListener(DOMEvent.Click, handleClickOutside, true);
    };
  }, [handleClickOutside, predicate]);
};
