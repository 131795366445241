import { StudentStatus } from '@/controllers/graphql/generated';
import {
  ACTIVE_STUDENT_STATUSES, FORMER_STUDENT_STATUSES,
  PAYMENT_FORBIDDEN_STATUSES,
  STUDENT_CAREER_STATUSES,
  STUDENT_EMPLOYMENT_STATUSES,
} from '@/controllers/user/user.constants';

export const isUserLookingForJob = (studentStatus?: StudentStatus | null) => (
  studentStatus && STUDENT_EMPLOYMENT_STATUSES.includes(studentStatus)
);

export const shouldUserPay = (studentStatus?: StudentStatus | null) => (
  !!studentStatus && !PAYMENT_FORBIDDEN_STATUSES.includes(studentStatus)
);

export const isUserHaveAccessToCareer = (
  studentStatus?: StudentStatus | null,
): boolean => (
  Boolean(studentStatus && STUDENT_CAREER_STATUSES.includes(studentStatus))
);

export const isActiveUser = (studentStatus?: StudentStatus | null): boolean => (
  Boolean(studentStatus && ACTIVE_STUDENT_STATUSES.includes(studentStatus))
);

export const isFormerStudent = (studentStatus?: StudentStatus | null) => (
  studentStatus && FORMER_STUDENT_STATUSES.includes(studentStatus)
);
