import React, { memo } from 'react';
import { useRouter } from '@/middleware/i18n';
import { Button } from '@/components/ui/Button';
import { IconClose } from '@/components/ui/icons/IconClose';
import styles from '@/components/platform/Header/components/CloseMobileViewButton/CloseMobileViewButton.module.scss';

export const CloseMobileViewButton = memo(() => {
  const router = useRouter();

  return (
    <Button
      className={styles.button}
      mode={Button.mode.TransparentLight}
      size={Button.size.Large}
      LeftIcon={IconClose}
      onClick={() => router.back()}
      dataQa="close-mobile-view-button"
    />
  );
});
