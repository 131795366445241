import React, { FC } from 'react';
import { GamificationActions } from '@/hooks/useGamificationModalStore';
import {
  AchievementGamificationModalContent,
} from '@/components/platform/Achievements/components/AchievementGamificationModalContent';
import {
  StreaksGamificationModalContent,
} from '@/components/platform/Streaks/components/StreaksGamificationModalContent';
import { MessageTypes } from '@/lib/constants/messages';

type Props = {
  modalToShow?: GamificationActions;
  onClose: () => void;
};

export const GamificationModalContent: FC<Props> = ({
  modalToShow,
  onClose,
}) => {
  if (!modalToShow) {
    return null;
  }

  const { type, payload } = modalToShow;

  switch (type) {
    case MessageTypes.Achievement:
      return (
        <AchievementGamificationModalContent
          userAchievement={payload}
          onClose={onClose}
        />
      );
    case MessageTypes.Streak:
      return (
        <StreaksGamificationModalContent
          streak={payload}
          onClose={onClose}
        />
      );
    default:
      return null;
  }
};
