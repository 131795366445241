import React, { FC } from 'react';
import { SearchableEntityType } from '@/controllers/graphql/generated';
import { SearchableMessage } from '@/components/platform/GlobalSearch/components/SearchableEntities/SearchableMessage';
import { GlobalSearchMatchFragment } from '@/components/platform/GlobalSearch/graphql/generated/globalSearchMatch.fragment.generated';
import { SearchableChat } from '@/components/platform/GlobalSearch/components/SearchableEntities/SearchableChat';
import { SearchableVideo } from '@/components/platform/GlobalSearch/components/SearchableEntities/SearchableVideo';
import { SearchableTopicDeprecated } from '@/components/platform/GlobalSearch/components/SearchableEntities/SearchableTopicDeprecated';
import { SearchableTask } from '@/components/platform/GlobalSearch/components/SearchableEntities/SearchableTask';
import { SearchableTheory } from '@/components/platform/GlobalSearch/components/SearchableEntities/SearchableTheory';

interface Props {
  match: GlobalSearchMatchFragment;
}

export const SearchResult: FC<Props> = ({ match }) => {
  switch (match.type) {
    case SearchableEntityType.Chat:
      return (
        <SearchableChat matchedChat={match} />
      );

    case SearchableEntityType.ChatMessage:
      return (
        <SearchableMessage matchedMessage={match} />
      );

    case SearchableEntityType.Video:
      return (
        <SearchableVideo matchedVideo={match} />
      );

    case SearchableEntityType.Theory:
      return (
        <SearchableTheory matchedTheory={match} />
      );

    case SearchableEntityType.Topic:
      return (
        <SearchableTopicDeprecated matchedTopic={match} />
      );

    case SearchableEntityType.Task:
      return (
        <SearchableTask matchedTask={match} />
      );

    default:
      return null;
  }
};
