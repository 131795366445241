import { UserEnglishLevel } from '@/controllers/graphql/generated';
import { UserEnglishMateLevel } from '@/controllers/user/user.typedefs';

export const getUserMateEnglishLevel = (
  englishLevel?: UserEnglishLevel | null,
): UserEnglishMateLevel => {
  switch (englishLevel) {
    case UserEnglishLevel.Beginner:
    case UserEnglishLevel.LowElementary:
      return UserEnglishMateLevel.Crab;
    case UserEnglishLevel.HighElementary:
    case UserEnglishLevel.LowPreIntermediate:
      return UserEnglishMateLevel.Fish;
    case UserEnglishLevel.HighPreIntermediate:
    case UserEnglishLevel.LowIntermediate:
      return UserEnglishMateLevel.Turtle;
    case UserEnglishLevel.HighIntermediate:
    case UserEnglishLevel.UpperIntermediate:
    case UserEnglishLevel.Advanced:
      return UserEnglishMateLevel.Bird;
    default:
      return UserEnglishMateLevel.Unspecified;
  }
};
