import ukStrings from 'react-timeago/lib/language-strings/uk';
import ruStrings from 'react-timeago/lib/language-strings/ru';
import enStrings from 'react-timeago/lib/language-strings/en';
import plStrings from 'react-timeago/lib/language-strings/pl';
import ptBrStrings from 'react-timeago/lib/language-strings/pt-br';
import ukShortStrings from '@/components/platform/common/HumanReadableDate/customLanguageStrings/ukShort';
import enShortStrings from '@/components/platform/common/HumanReadableDate/customLanguageStrings/enShort';
import plShortStrings from '@/components/platform/common/HumanReadableDate/customLanguageStrings/plShort';
import ptBrShortStrings from '@/components/platform/common/HumanReadableDate/customLanguageStrings/ptBrShort';
import { L10nsStrings } from '@/components/platform/common/HumanReadableDate/HumanReadableDate.typedefs';

const localeStrings: Record<
  'default' | 'short',
  Record<'uk' | 'ru' | 'en' | 'pl' | 'pt', L10nsStrings>
> = {
  default: {
    uk: ukStrings,
    ru: ruStrings,
    en: enStrings,
    pl: plStrings,
    pt: ptBrStrings,
  },
  short: {
    uk: ukShortStrings,
    ru: ruStrings,
    en: enShortStrings,
    pl: plShortStrings,
    pt: ptBrShortStrings,
  },
};

export const getLocaleStrings = (
  lang: string,
  isShort?: boolean,
): L10nsStrings | null => {
  switch (lang) {
    case 'uk':
    case 'ru':
    case 'en':
    case 'pl':
    case 'pt':
      return localeStrings[
        isShort
          ? 'short'
          : 'default'
      ][lang];
    default: return null;
  }
};
