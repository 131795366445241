import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconDislike: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.07921 4.61376L5.07926 4.61378L5.08308 4.60462C5.23115 4.24923 5.58233 4 6 4H15C15.5477 4 16 4.45228 16 5V15C16 15.2791 15.8894 15.5264 15.7129 15.7029L9.82666 21.5891L9.47711 21.2429C9.47671 21.2425 9.47631 21.2421 9.47591 21.2417C9.39359 21.1588 9.33979 21.0444 9.33121 20.9239L9.35042 20.7189L10.2891 16.2035L10.5393 15H9.31H3C2.45228 15 2 14.5477 2 14V12C2 11.8783 2.02162 11.7652 2.06638 11.647L5.07921 4.61376ZM22 14H20V4H22V14Z" strokeWidth="2" />
  </BaseIcon>
);
