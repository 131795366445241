import React, { Dispatch, FC, memo } from 'react';
import { cn } from '@/lib/classNames';
import { Selectors } from '@/lib/selectors';
import { typography } from '@/components/ui/typography';
import { InputRadioMode, InputRadioUI } from '@/components/ui/FormElements/FormInputs/InputRadio';
import { IconFireEmpty } from '@/components/ui/icons/IconFireEmpty';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { PaywallLocations } from '@/controllers/analytics/generated';
import { PricingOptionPaymentType } from '@/controllers/graphql/generated';
import { useSubscriptionDetails } from '@/components/paymentProviders/Stripe/StripeCheckoutForm/hooks/useSubscriptionDetails';
import { SubscriptionPlanPricingOptionBaseFragment } from '@/components/platform/SubscriptionPlanPricingOptions/graphql/generated/subcsriptionPlanPricingOptionBase.fragment.generated';
import { PaywallPaymentSubscriptionPlanFragment } from '@/components/platform/SubscriptionProduct/components/SubscriptionPlansList/graphql/generated/PaywallPaymentSubscriptionPlan.generated';
import { PaywallPricingOptions } from '@/components/platform/SubscriptionProduct/components/SubscriptionPlansList/constants';
import styles from './HorizontalSubscriptionPlan.module.scss';

interface Props {
  selectedOption: SubscriptionPlanPricingOptionBaseFragment | null;
  plan: PaywallPaymentSubscriptionPlanFragment;
  option: SubscriptionPlanPricingOptionBaseFragment;
  paywallLocation: PaywallLocations;
  setSelectedOption: Dispatch<SubscriptionPlanPricingOptionBaseFragment | null>;
}

export const HorizontalSubscriptionPlan: FC<Props> = memo(({
  plan,
  option,
  setSelectedOption,
  selectedOption,
}) => {
  const { t } = useTranslation([I18N_CODES.common]);

  const { userFriendlyName } = plan;

  const {
    paymentType,
    referencePrice,
    salePrice,
    uuid,
  } = option;

  const withAccent = uuid === PaywallPricingOptions.Annual;

  const subcriptionDetails = useSubscriptionDetails(
    plan,
    option,
  );

  const onSale = (
    paymentType === PricingOptionPaymentType.IntroOffer
    || referencePrice !== salePrice
  );

  return (
    <div
      data-qa='subscription-plan-block'
      className={cn(styles.subscriptionPlanWrapper, {
        [Selectors.Accent]: withAccent,
        [Selectors.Active]: selectedOption?.id === option.id,
      })}
      aria-hidden='true'
      onClick={() => setSelectedOption(option)}
    >
      {withAccent && (
        <div
          data-qa='most-popular-badge'
          className={cn(styles.badge, typography.platformTextSmall)}
        >
          <IconFireEmpty />
          {t(`${I18N_CODES.common}:paywall.best_value_badge`)}
        </div>
      )}

      <div className={styles.inputRadioWrapper}>
        <InputRadioUI
          name='subscription-plan-checkbox'
          color={InputRadioMode.Green}
          checked={selectedOption?.id === option.id}
        />
      </div>

      <div className={styles.subscriptionPlanMainInfo}>
        <div className={styles.titleContainer}>
          <h4
            data-qa='subscription-plan-title'
            className={cn(styles.subscriptionPlanName, typography.platformH4, {
              [Selectors.Accent]: withAccent,
            })}
          >
            {userFriendlyName}
          </h4>

          {onSale && subcriptionDetails?.savings && (
            <span
              data-qa='plan-saving-description'
              className={cn(
                styles.savingsDescription,
                typography.platformTextSmall,
                {
                  [Selectors.Accent]: withAccent,
                },
              )}
            >
              Save&nbsp;
              {subcriptionDetails.savings}
            </span>
          )}
        </div>

        {onSale && (
          <p
            data-qa='subscription-plan-description'
            className={cn(
              styles.subscriptionPlanDescription,
              typography.platformTextSecondary,
              typography.paragraph,
              {
                [Selectors.Accent]: withAccent,
              },
            )}
          >
            <span
              data-qa='default-price-description'
              className={styles.defaultPriceDescription}
            >
              {subcriptionDetails?.defaultPrice}
            </span>

            <span className='ml-4' data-qa='saved-amount-title'>
              {
                subcriptionDetails?.subscriptionPeriodDuration === 1
                  ? subcriptionDetails?.totalPrice
                  : t(`${I18N_CODES.common}:paywall.plan_sale_description_billed_upfront`, {
                    salePrice: subcriptionDetails?.totalPrice,
                  })
              }
            </span>
          </p>
        )}
      </div>

      <div className={styles.subscriptionPlanPriceBlock}>
        <em
          data-qa='subscription-plan-price'
          className={cn(styles.subscriptionPlanPrice, typography.platformH1, {
            [Selectors.Accent]: withAccent,
          })}
        >
          {subcriptionDetails?.pricePerPeriod}
        </em>

        <span className={cn(typography.platformTextSmall, styles.perMonth)}>
          {t(`${I18N_CODES.common}:paywall.per_month_price_label`)}
        </span>
      </div>
    </div>
  );
});
