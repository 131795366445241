import React, { FC, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  RatingItemType,
  RatingWorkspace,
} from '@/controllers/graphql/generated';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { IconSize, LikeRatingUI } from '@/components/platform/Learn/pages/CourseTopic/components/LikeRating/LikeRatingUI';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import { IconStyling } from '@/components/platform/Learn/pages/CourseTopic/components/LikeRating/LikeRatingContext';
import {
  cacheUpdaterItemTypes as likeRatingCacheUpdaters,
} from '@/components/platform/Learn/pages/CourseTopic/components/LikeRating/cacheUpdater';
import { PlatformEvents } from '@/controllers/platform/platform.events';
import { emptyFunction } from '@/lib/helpers/functional';
import { useRateEntityMutation } from '@/controllers/graphql/generated/rateEntity.mutation.generated';

interface Props {
  notificationId: number;
  relatedId: number;
  relatedType: RatingItemType;
  userRating: number;
  buttonClassName?: string;
}

export const NotificationLikeRating: FC<Props> = (props) => {
  const {
    notificationId,
    relatedId,
    relatedType,
    userRating,
    buttonClassName,
  } = props;

  const [authUser] = useAuthUser({ ssr: true });
  const client = useApolloClient();
  const { t } = useTranslation([I18N_CODES.platformNotifications]);
  const { showMessage } = useFlashMessage();

  const [
    rateEntity,
    { loading: isRatingLoading },
  ] = useRateEntityMutation({
    onCompleted(data) {
      const cacheUpdater = likeRatingCacheUpdaters[relatedType];

      if (cacheUpdater) {
        cacheUpdater(client, data, notificationId);
      }

      PlatformEvents.sendEvent(
        PlatformEvents.events.notificationsRatingSend,
        {
          rating: data.upsertRating.rating,
          notificationId,
        },
      );
    },
    onError() {
      showMessage({
        text: t(`${I18N_CODES.platformNotifications}:rating_submitted_with_error`),
        type: 'error',
      });
    },
  });

  const rate = useCallback(async (newRating: number, comment?: string) => {
    if (newRating === userRating) {
      return;
    }

    await rateEntity({
      optimisticResponse: {
        upsertRating: {
          id: 0,
          rating: newRating,
          positiveRating: Math.ceil((newRating) / 2),
          negativeRating: Math.ceil((newRating) / 2),
        },
      },
      variables: {
        comment,
        rating: newRating,
        relatedId,
        userId: authUser?.id || 0,
        relatedType,
        workspace: RatingWorkspace.Notifications,
      },
    });
  }, [
    rateEntity,
    relatedId,
    userRating,
    authUser?.id,
    relatedType,
  ]);

  const like = useCallback(async () => rate(1), [
    rate,
  ]);

  const dislike = useCallback(async () => rate(-1), [
    rate,
  ]);

  return (
    <LikeRatingUI
      rating={userRating}
      onLike={like}
      onDisLike={dislike}
      isModalOpen={false}
      onCloseModal={emptyFunction}
      onCommentUpdate={emptyFunction}
      isCountersVisible={false}
      iconStyling={IconStyling.DarkGray}
      size={IconSize.Regular}
      shouldShowCommentModal={false}
      loading={isRatingLoading}
      buttonClassName={buttonClassName}
    />
  );
};
