import { useCallback, useState } from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { WAIT_FOR_ACTION_DURATION } from '@/components/ui/CopyButton/CopyButton.constants';
import { MESSAGE_TYPES } from '@/lib/constants/messages';
import { useFlashMessage } from '@/hooks/useFlashMessage';

export const useCopyToClipboard = (content: string, onClick?: () => void) => {
  const { showMessage } = useFlashMessage();
  const { t } = useTranslation([I18N_CODES.common]);

  const [isCopied, setIsCopied] = useState(false);

  const handleClick = useCallback(() => {
    try {
      navigator.clipboard.writeText(content);
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, WAIT_FOR_ACTION_DURATION);

      onClick?.();
    } catch {
      showMessage({
        type: MESSAGE_TYPES.error,
        text: t(`${I18N_CODES.common}.messages.error.copy_to_clipboard`),
      });
    }
  }, [content, onClick, showMessage, t]);

  return [isCopied, handleClick] as const;
};
