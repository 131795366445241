export enum KeyboardKey {
  Enter = 'Enter',
  Escape = 'Escape',
  Meta = 'Meta',
  Ctrl = 'Ctrl',
  Shift = 'Shift',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  Period = '.',
  S = 's',
}
