import React, { useCallback, useMemo } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { ProfileDropdownActionItemI, ProfileDropdownItemType } from '@/components/platform/ProfileDropdown/typedefs';
import { redirect } from '@/lib/redirect';
import { ROUTES } from '@/controllers/router/router.contants';
import { IconLogOut } from '@/components/ui/icons/IconLogOut';
import { colorTokens } from '@/components/ui/colorTokens';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import { MESSAGE_TYPES } from '@/lib/constants/messages';
import { IconSize } from '@/components/ui/icons/BaseIcon';
import { useLogOut } from '@/controllers/user/user.hooks/useLogOut';

export const useLogoutDropdownItem = (): ProfileDropdownActionItemI => {
  const { t } = useTranslation([I18N_CODES.profile]);
  const { showMessage } = useFlashMessage();

  const { logOut } = useLogOut({
    onCompleted: () => {
      redirect({}, ROUTES.auth.signIn);
    },
  });

  const onAction = useCallback(async () => {
    try {
      await logOut();
    } catch {
      showMessage({
        type: MESSAGE_TYPES.error,
        heading: t(`${I18N_CODES.profile}:logout_failed`),
      });
    }
  }, [logOut, showMessage, t]);

  const logOutTitle = t(`${I18N_CODES.platformSidebar}:sidebar_log_out_link`);

  return useMemo(() => ({
    id: 100,
    type: ProfileDropdownItemType.Action,
    text: logOutTitle,
    onAction,
    icon: <IconLogOut size={IconSize.Small} color={colorTokens['icon-danger']} />,
    condition: true,
    dataQA: 'log-out-link',
  }), [logOutTitle, onAction]);
};
