import type { L10nsStrings } from '@/components/platform/common/HumanReadableDate/HumanReadableDate.typedefs';

const strings: L10nsStrings = {
  prefixAgo: null,
  prefixFromNow: 'za',
  suffixAgo: 'temu',
  suffixFromNow: null,
  seconds: 'chwila',
  minute: '1 min',
  minutes: `%d min`,
  hour: '1h',
  hours: `%dh`,
  day: '1d',
  days: `%dd`,
  month: '1m',
  months: `%dm`,
  year: '1r',
  years: `%dr`,
};

export default strings;
