import React, { FC } from 'react';
import {
  TitleBlock,
  LatestAchievements,
} from '@/components/platform/Learn/CoursePageContent/components/AchievementsBlock';
import { AchievementsBlockHarnessSelectors } from '@/components/platform/Learn/CoursePageContent/components/AchievementsBlock/AchievementsBlock.harness';
import styles from './AchievementsBlock.module.scss';

export const AchievementsBlock: FC = () => (
  <div
    data-qa={AchievementsBlockHarnessSelectors.DataQa}
    className={styles.wrapper}
  >
    <TitleBlock />
    <LatestAchievements />
  </div>
);
