import { useMemo, useCallback } from 'react';
import { AdminToolType } from '@/controllers/graphql/generated';
import { useAdminToolsQuery } from '@/controllers/graphql/generated/adminTools.query.generated';

interface HookResult {
  hasAccessToAdminTools: boolean;
  hasAccessToAdminTool: (tool: AdminToolType) => boolean;
}

export const useCheckAccessToAdminTools = (): HookResult => {
  const { data } = useAdminToolsQuery();
  const adminTools = data?.adminTools?.tools;

  const hasAccessToAdminTools = useMemo(
    () => adminTools?.some((tool) => tool.isAvailable) || false,
    [adminTools],
  );

  const hasAccessToAdminTool = useCallback((tool: AdminToolType) => {
    const hasAccess = adminTools?.find((item) => item.id === tool);

    return hasAccess?.isAvailable || false;
  }, [adminTools]);

  return { hasAccessToAdminTools, hasAccessToAdminTool };
};
