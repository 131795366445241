import { useCallback } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';

export const useLocaleDayAndMonth = () => {
  const { t } = useTranslation([I18N_CODES.chat]);

  return useCallback((day: number, month: number) => {
    switch (month) {
      case 0:
        return t(`${I18N_CODES.chat}:day_and_month.january`, { day });
      case 1:
        return t(`${I18N_CODES.chat}:day_and_month.february`, { day });
      case 2:
        return t(`${I18N_CODES.chat}:day_and_month.march`, { day });
      case 3:
        return t(`${I18N_CODES.chat}:day_and_month.april`, { day });
      case 4:
        return t(`${I18N_CODES.chat}:day_and_month.may`, { day });
      case 5:
        return t(`${I18N_CODES.chat}:day_and_month.june`, { day });
      case 6:
        return t(`${I18N_CODES.chat}:day_and_month.july`, { day });
      case 7:
        return t(`${I18N_CODES.chat}:day_and_month.august`, { day });
      case 8:
        return t(`${I18N_CODES.chat}:day_and_month.september`, { day });
      case 9:
        return t(`${I18N_CODES.chat}:day_and_month.october`, { day });
      case 10:
        return t(`${I18N_CODES.chat}:day_and_month.november`, { day });
      case 11:
        return t(`${I18N_CODES.chat}:day_and_month.december`, { day });
      default:
        return '';
    }
  }, [t]);
};
