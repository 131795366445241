import React, { FC } from 'react';
import { cn } from '@/lib/classNames';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { UserAvatar } from '@/components/ui/UserAvatar';
import {
  UpdateAvatarButton,
} from '@/components/platform/ProfileAvatar/UpdateAvatarButton';
import styles from './ProfileAvatar.module.scss';

interface Props {
  large?: boolean;
  size?: number;
  withBadge?: boolean;
}

export const ProfileAvatar: FC<Props> = ({
  large = false,
  size = 32,
  withBadge = true,
}) => {
  const [authUser] = useAuthUser({ ssr: true });

  const badgeType = authUser?.badgeType;

  if (!authUser) {
    return null;
  }

  const { avatar, fullName, id } = authUser;

  const avatarSize = large
    ? 128
    : size;

  const shouldAddBadge = !large && withBadge;

  return (
    <div className={styles.avatarWrapper}>
      <div
        className={cn(
          styles.iconWrapper,
          { [styles.iconWrapper__isLarge]: large },
        )}
      >
        <UserAvatar
          shouldOpenProfile={false}
          fullName={fullName ?? ''}
          userId={id}
          avatar={avatar}
          size={avatarSize}
          badgeType={shouldAddBadge
            ? badgeType
            : undefined}
        />

        {large && (
          <UpdateAvatarButton avatarSize={avatarSize} />
        )}
      </div>
    </div>
  );
};
