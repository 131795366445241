import { useCallback } from 'react';
import { GroupMentions } from '@/components/ui/ChatInput/ParticipantMention/ParticipantMention.typedefs';
import { useLoadMentionedParticipants } from '@/components/platform/Chat/components/ChatMarkdown/hooks/useLoadMentionedParticipants';

export const useGetMentionedParticipantName = (text: string) => {
  const { participantsMap } = useLoadMentionedParticipants(text);

  return useCallback((participantId: string): string => {
    if (participantId === GroupMentions.All) {
      return `@${GroupMentions.All}`;
    }

    const presentParticipant = (
      participantsMap.get(Number(participantId))
    );

    if (!presentParticipant) {
      return '@Loading...';
    }

    return `@${presentParticipant.username}`;
  }, [participantsMap]);
};
