import type { L10nsStrings } from '@/components/platform/common/HumanReadableDate/HumanReadableDate.typedefs';

const strings: L10nsStrings = {
  prefixAgo: null,
  prefixFromNow: 'через',
  suffixAgo: 'тому',
  suffixFromNow: null,
  seconds: '<1 хв',
  minute: '1 хв',
  minutes: `%d хв`,
  hour: '1 год',
  hours: `%d год`,
  day: '1 д',
  days: `%d д`,
  month: '1 м',
  months: `%d м`,
  year: '1 р',
  years: `%d р`,
};

export default strings;
