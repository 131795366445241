import React, { memo } from 'react';
import { IconChevronDown } from '@/components/ui/icons/IconChevronDown';
import { IconChevronRight } from '@/components/ui/icons/IconChevronRight';
import styles from '@/components/platform/Markdown/components/common.module.scss';

export const Summary = memo(({ children }) => (
  <summary className={styles.summary}>
    <IconChevronDown className={styles.openIcon} />

    <IconChevronRight className={styles.closeIcon} />

    {children}
  </summary>
));
