import React, { FC } from 'react';
import { Alert, AlertMode } from '@/components/ui/Alert';
import { cn } from '@/lib';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { typography } from '@/components/ui/typography';

interface Props {
  lastPaymentErrorMessage: string;
}

export const RenewIssueAlert: FC<Props> = ({ lastPaymentErrorMessage }) => {
  const { t } = useTranslation(I18N_CODES.subscriptions);

  return (
    <Alert
      mode={AlertMode.Error}
      className='mb-12'
    >
      <h4 className={cn(typography.platformH4, 'mb-4')}>
        {t(`${I18N_CODES.subscriptions}:renew_issue_alert_title`)}
      </h4>
      <p className={typography.platformTextSecondary}>
        {lastPaymentErrorMessage}
      </p>
    </Alert>
  );
};
