import React, {
  useCallback,
  useMemo,
} from 'react';
import {
  PlatformConsultationModal,
} from '@/components/platform/PlatformConsultation/PlatformConsultationModal';
import {
  useSubDomainContext,
} from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import {
  FtCourseWithMinimalCourseApplicationInfoFragment,
} from '@/components/platform/PlatformConsultation/graphql/generated/ftCourseWithMinimalCourseApplicationInfo.fragment.generated';
import { useGetFtCoursesWithTestInfoQuery } from '@/components/platform/PlatformConsultation/graphql/generated/getFtCoursesWithTestInfo.query.generated';
import { useBecomeStudentButton } from '@/components/platform/PlatformConsultation/useBecomeStudentButton';
import { useIsOpenStateWithBlockPageScroll } from '@/hooks/useIsOpenStateWithBlockPageScroll';
import { EMPTY_ARRAY } from '@/constants';

type HookResult = {
  isModalAvailable: boolean;
  isModalOpened: boolean;
  closeModal: () => void;
  openModal: () => void;
  BecomeStudentModal: () => JSX.Element | null;
};

export const useBecomeStudentModal = (): HookResult => {
  const {
    isOpen: isModalOpened,
    close: closeModal,
    open: openModal,
  } = useIsOpenStateWithBlockPageScroll({
    initialState: false,
  });

  const {
    canShowBecomeStudentButton,
    leadType,
  } = useBecomeStudentButton();

  const { subDomain } = useSubDomainContext();

  const { data } = useGetFtCoursesWithTestInfoQuery({
    variables: {
      domain: subDomain,
    },
    skip: !leadType,
  });

  const courses: FtCourseWithMinimalCourseApplicationInfoFragment[] = useMemo(
    () => data?.coursesWithTestInfo || EMPTY_ARRAY,
    [data],
  );

  const isModalAvailable = (
    canShowBecomeStudentButton
    && leadType
  );

  const BecomeStudentModal = useCallback(() => {
    if (!isModalAvailable) {
      return null;
    }

    return (
      <PlatformConsultationModal
        courses={courses}
        isOpen={isModalOpened}
        leadType={leadType}
        closeModal={closeModal}
      />
    );
  }, [
    isModalAvailable,
    isModalOpened,
    courses,
    leadType,
    closeModal,
  ]);

  return {
    isModalAvailable: Boolean(isModalAvailable),
    isModalOpened,
    closeModal,
    openModal,
    BecomeStudentModal,
  };
};
