import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconTimer: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 1H9.5V3H15.5V1ZM11.5 14H13.5V8H11.5V14ZM19.53 7.39L20.95 5.97C20.52 5.46 20.05 4.98 19.54 4.56L18.12 5.98C16.57 4.74 14.62 4 12.5 4C7.53 4 3.5 8.03 3.5 13C3.5 17.97 7.52 22 12.5 22C17.48 22 21.5 17.97 21.5 13C21.5 10.88 20.76 8.93 19.53 7.39ZM12.5 20C8.63 20 5.5 16.87 5.5 13C5.5 9.13 8.63 6 12.5 6C16.37 6 19.5 9.13 19.5 13C19.5 16.87 16.37 20 12.5 20Z"
      fill="currentColor"
    />
  </BaseIcon>
);
