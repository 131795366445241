import React, { FC } from 'react';
import { MateMarkdown } from '@/components/platform/Markdown';
import styles from './SearchableEntityTitleMarkdown.module.scss';

interface Props {
  title: string;
}

const allowedElements = ['p', 'mark'];

export const SearchableEntityTitleMarkdown: FC<Props> = ({ title }) => (
  <MateMarkdown
    source={title}
    allowedElements={allowedElements}
    className={styles.title}
  />
);
