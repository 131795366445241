import { useState, useEffect, useMemo } from 'react';
import { PaywallPricingOptions } from '@/components/platform/SubscriptionProduct/components/SubscriptionPlansList/constants';
import { useSubscriptionPlanPricingOptionsByUuiDsQuery } from '@/components/platform/SubscriptionProduct/components/SubscriptionPlansList/graphql/generated/subscriptionPlanPricingOptionsByUUIDs.query.generated';
import { SubscriptionPlanPricingOptionBaseFragment } from '@/components/platform/SubscriptionPlanPricingOptions/graphql/generated/subcsriptionPlanPricingOptionBase.fragment.generated';
import { exists } from '@/lib/helpers/functional';

export const usePaywallPricingOptions = () => {
  const [selectedOption, setSelectedOption] = useState<
    SubscriptionPlanPricingOptionBaseFragment | null
  >(null);

  const { data, loading } = useSubscriptionPlanPricingOptionsByUuiDsQuery({
    variables: {
      uuids: Object.values(PaywallPricingOptions),
    },
  });

  const pricingOptions = useMemo(() => {
    const options = data?.subscriptionPlanPricingOptionsByUUIDs;

    if (!options) {
      return [];
    }

    const optionsMap = options.reduce((acc, cur) => {
      acc.set(cur.uuid, cur);

      return acc;
    }, new Map());

    return [
      optionsMap.get(PaywallPricingOptions.Annual),
      optionsMap.get(PaywallPricingOptions.Monthly),
    ].filter(exists);
  }, [data]);

  const subscriptionPlans = useMemo(() => (
    pricingOptions.map(({ subscriptionPlan }) => subscriptionPlan)
  ), [pricingOptions]);

  useEffect(() => {
    if (pricingOptions && pricingOptions.length > 0) {
      const [firstOption] = pricingOptions;

      if (firstOption) {
        setSelectedOption(firstOption);
      }
    }
  }, [pricingOptions]);

  return {
    subscriptionPlans,
    pricingOptions,
    selectedOption,
    setSelectedOption,
    loading,
  };
};
