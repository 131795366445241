import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconLike: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M13.7109 8.79647L13.4607 10H14.69H21C21.5477 10 22 10.4523 22 11V13C22 13.1218 21.9784 13.2348 21.9336 13.353L18.9208 20.3862L18.9207 20.3862L18.9169 20.3954C18.7688 20.7508 18.4177 21 18 21H9C8.45228 21 8 20.5477 8 20V10C8 9.7222 8.10924 9.48003 8.28736 9.30673L8.29254 9.30168L8.29764 9.29657L14.1739 3.4114L14.5229 3.75711C14.5233 3.7575 14.5237 3.75789 14.5241 3.75828C14.6064 3.84118 14.6602 3.95559 14.6688 4.07612L14.6496 4.2811L13.7109 8.79647ZM4 11V21H2V11H4Z" strokeWidth="2" />
  </BaseIcon>
);
