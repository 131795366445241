import { TopicSections } from '@/controllers/courseUserTopics/courseUserTopics.typedefs';
import { ROUTES } from '@/controllers/router/router.contants';

export const topicSectionByURL: Record<string, TopicSections> = {
  [ROUTES.learn.practice]: TopicSections.Practice,
  [ROUTES.learn.theory]: TopicSections.Theory,
  [ROUTES.learn.video]: TopicSections.Video,
};

export const URLByTopicSection: Record<TopicSections, string> = {
  [TopicSections.Practice]: ROUTES.learn.practice,
  [TopicSections.Theory]: ROUTES.learn.theory,
  [TopicSections.Video]: ROUTES.learn.video,
};
