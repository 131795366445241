import { useMemo } from 'react';
import { PaymentStatus } from '@/controllers/graphql/generated';
import { PaymentIssueComponent, StripePaymentErrorMessages } from '@/components/platform/SubscriptionProduct/typedefs';
import { RenewIssueAlert } from '@/components/platform/SubscriptionProduct/components/Subscription/components/RenewIssueAlert';
import { useUserCards } from '@/controllers/userCards/userCards.hooks/useUserCards';
import { FAIL_PAYMENT_STATUSES } from '@/components/platform/SubscriptionProduct/constants';
import { useUserSubscriptionsQuery } from '@/components/platform/Subscription/graphql/generated/userSubscriptions.query.generated';

interface Options {
  component?: PaymentIssueComponent;
}

export const useSubscriptionProductPayment = (options?: Options) => {
  const { data, loading } = useUserSubscriptionsQuery({
    variables: {
      filter: {
        started: true,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const [userCards] = useUserCards();

  const cardData = useMemo(() => {
    const primaryCard = userCards.find((card) => card.primary);

    if (!primaryCard) {
      return null;
    }

    return primaryCard;
  }, [userCards]);

  const lastUserSubscription = useMemo(() => {
    const userSubscriptions = data?.userSubscriptions ?? [];

    if (!userSubscriptions.length) {
      return null;
    }

    const lastSubscription = userSubscriptions.reduce((latest, current) => {
      if (latest.id < current.id) {
        return current;
      }

      return latest;
    });

    if (!lastSubscription) {
      return null;
    }

    return lastSubscription;
  }, [data?.userSubscriptions]);

  if (!lastUserSubscription) {
    return {
      lastUserSubscription,
      loading,
      cardData,
      shouldRenderPaymentIssue: false,
      RenewIssueAlertComponent: null,
      errorMessagePlacement: PaymentIssueComponent.None,
    };
  }

  const nextPaymentDateString = lastUserSubscription.dateNext
    && new Date(lastUserSubscription.dateNext)
      .toLocaleDateString(
        undefined, // take user locale
        {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        },
      );

  const acceptedPaymentsCount = lastUserSubscription.payments
    .reduce(
      (paymentsCount, payment) => (payment.status === PaymentStatus.Accepted
        ? paymentsCount + 1
        : paymentsCount),
      0,
    );

  if (!lastUserSubscription.payments.length) {
    return {
      lastUserSubscription,
      loading,
      nextPaymentDateString,
      acceptedPaymentsCount,
      cardData,
      shouldRenderPaymentIssue: false,
      RenewIssueAlertComponent: null,
      errorMessagePlacement: PaymentIssueComponent.None,
    };
  }

  const lastPayment = lastUserSubscription?.payments
    .reduce((latest, current) => {
      if (latest.createdAt < current.createdAt) {
        return current;
      }

      return latest;
    });

  const lastPaymentErrorMessage = lastPayment.errorMessage;

  const shouldRenderPaymentIssue = (() => {
    if (!lastPayment || !lastPayment.status) {
      return false;
    }

    const isLastPaymentFailed = FAIL_PAYMENT_STATUSES
      .includes(lastPayment.status);

    const today = new Date();

    return (
      isLastPaymentFailed
      && !!lastUserSubscription.active
      && !lastUserSubscription.expired
      && lastUserSubscription.dateNext > today
    );
  })();

  let errorMessagePlacement = PaymentIssueComponent.None;

  switch (lastPaymentErrorMessage) {
    case null:
      break;

    case StripePaymentErrorMessages.CardExpired:
    case StripePaymentErrorMessages.IncorrectCVC:
      errorMessagePlacement = PaymentIssueComponent.PaymentMethod;
      break;

    default:
      errorMessagePlacement = PaymentIssueComponent.CurrentPlan;
  }

  const RenewIssueAlertComponent = (
    shouldRenderPaymentIssue
      && options?.component === errorMessagePlacement
      ? RenewIssueAlert
      : null
  );

  return {
    lastUserSubscription,
    lastPayment,
    loading,
    nextPaymentDateString,
    acceptedPaymentsCount,
    cardData,
    shouldRenderPaymentIssue,
    errorMessagePlacement,
    lastPaymentErrorMessage,
    RenewIssueAlertComponent,
  };
};
