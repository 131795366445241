import {
  TeacherWorkingTimesResult,
  WorkingTime,
} from '@/controllers/graphql/generated';
import { WorkingTimeBaseFragment } from '@/components/platform/TimeTracker/graphql/generated/WorkingTimeBase.fragment.generated';

export type WorkingTimeResult = Omit<TeacherWorkingTimesResult, 'rows'> & {
  rows: WorkingTimeBaseFragment[];
};

export enum TimeTrackerMessageType {
  CurrentWorkingTimeSync = 'currentWorkingTimeSync',
  LastWorkingTimeSync = 'lastWorkingTimeSync',
  TeacherWorkingTimesSync = 'teacherWorkingTimesSync',
  Heartbeat = 'heartbeat',
}

export type BroadcastMessage = {
  type: TimeTrackerMessageType.CurrentWorkingTimeSync;
  currentWorkingTime?: WorkingTime | null;
} | {
  type: TimeTrackerMessageType.LastWorkingTimeSync;
  lastWorkingTime?: WorkingTime;
} | {
  type: TimeTrackerMessageType.TeacherWorkingTimesSync;
  teacherWorkingTimes?: TeacherWorkingTimesResult;
} | {
  type: TimeTrackerMessageType.Heartbeat;
};
