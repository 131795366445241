import { useEffect } from 'react';
import { useNotificationsIndicator } from '@/components/common/NotificationsIndications/useNotificationsIndicator';
import { useRouteChangeCallback } from '@/controllers/router/router.hooks/useRouteChangeCallback';

export const TitleHandler = () => {
  const { updateTitle } = useNotificationsIndicator();

  useRouteChangeCallback(updateTitle);

  useEffect(() => {
    updateTitle();
  }, [updateTitle]);

  return null;
};
