import React, { FC, memo } from 'react';
import { FormField } from '@/components/ui/FormElements/FormField';
import { InputTextArea } from '@/components/ui/FormElements/FormInputs/InputTextArea';
import { I18N_CODES } from '@/lib/constants/general';
import { makeValidator } from '@/controllers/forms/forms.validator';
import { isNotEmpty } from '@/controllers/forms/forms.validator/validationRules';
import { useTranslation } from '@/middleware/i18n';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';
import styles from '@/components/platform/LikeRating/ReportProblem.module.scss';

interface Props {
  disabled: boolean;
  loading: boolean;
}

export const CommentFormField: FC<Props> = memo(({
  disabled,
  loading,
}) => {
  const { t } = useTranslation(I18N_CODES.likeRating);

  return (
    <>
      <FormField
        renderInput={(inputProps) => (
          <div className='pb-24'>
            <InputTextArea
              {...inputProps}
              placeholder={t(`${I18N_CODES.likeRating}:report_problem_comment_placeholder`)}
              dataQa='feedback-comment-field'
              config={{
                validate: makeValidator(
                  isNotEmpty,
                  t(`${I18N_CODES.likeRating}:rating_comment_is_required`),
                ),
              }}
              rows={4}
              required
              name='comment'
            />
          </div>
        )}
        name='comment'
      />
      <div className={styles.buttonWrapper}>
        <Button
          mode={ButtonMode.Primary}
          size={ButtonSize.Large}
          text={t(`${I18N_CODES.likeRating}:rating_button_sent_feedback`)}
          className={styles.submitButton}
          disabled={disabled}
          isLoading={loading}
          type='submit'
          dataQa='send-feedback-button'
        />
      </div>
    </>
  );
});
