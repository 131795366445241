import React, { FC, memo } from 'react';
import { cn } from '@/lib';
import styles from './Editable.module.scss';

type Props = {
  dataQa?: string;
};

export const Editable: FC<Props> = memo((props) => {
  const {
    children,
    dataQa,
  } = props;

  return (
    <div
      className={cn(styles.editable, 'notranslate')}
      translate="no"
      data-qa={dataQa}
    >
      {children}
    </div>
  );
});
