import React, { FC } from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const EnglishIcon: FC<any> = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.5 16.88L1.5 1.38H17.5V15.38H5.85078C5.39664 15.38 4.95602 15.5346 4.60139 15.8183L1.5 18.2994L1.5 16.88Z" stroke="currentColor" strokeWidth="2" />
    <path d="M7.38331 12.38L8.00025 10.5558H10.9997L11.6167 12.38H13.5L10.6344 4.38H8.36961L5.5 12.38H7.38331ZM8.44673 9.23547L9.46956 6.20812H9.5345L10.5573 9.23547H8.44673Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.5 5.38H22.5C23.0523 5.38 23.5 5.82771 23.5 6.38V21.7436V22.6138C23.5 23.3911 22.652 23.8712 21.9855 23.4713L18.7375 21.5225C18.5821 21.4293 18.4043 21.38 18.223 21.38H9.5C8.94771 21.38 8.5 20.9323 8.5 20.38V18.38H10.5V19.38H18.223C18.7667 19.38 19.3003 19.5278 19.7665 19.8075L21.5 20.8476V7.38H20.5V5.38Z" fill="currentColor" />
  </BaseIcon>
);
