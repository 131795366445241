import React, { FC, useEffect } from 'react';
import { useIntersectionObserver } from '@/hooks/useIntersectionObserver';
import { Loader } from '@/components/ui/Loader';
import styles from './PaginationTrigger.module.scss';

interface Props {
  onTrigger: (onCompleted?: () => void) => Promise<void>;
  loading?: boolean;
}

export const PaginationTrigger: FC<Props> = ({
  onTrigger,
  loading,
}) => {
  const {
    inView,
    setInView,
    setElementRef,
  } = useIntersectionObserver({
    root: null,
    noUnobserve: true,
    initialState: false,
    rootMargin: '0px 0px 100px 0px',
  });

  useEffect(() => {
    if (inView && !loading) {
      onTrigger();

      setInView(false);
    }
  }, [inView, onTrigger, setInView, loading]);

  return (
    <div className={styles.container}>
      <div
        ref={setElementRef}
        className={styles.triggerElement}
      />

      <Loader
        loading={loading}
        className={styles.loader}
      />
    </div>
  );
};
