import React, { FC, Ref } from 'react';
import { NoNotifications } from '@/components/platform/Notifications/components/NoNotifications';
import { Loader } from '@/components/ui/Loader';
import { AbstractNotificationFragment } from '@/components/platform/Notifications/graphql/generated/AbstractNotification.fragment.generated';
import styles from './NotificationsContent.module.scss';
import { NotificationsList } from '../NotificationsList';

interface Props {
  loading: boolean;
  notifications: AbstractNotificationFragment[];
  newerNotificationsTriggerRef: Ref<HTMLDivElement>;
}

export const NotificationsContentBody: FC<Props> = ({
  notifications,
  loading,
  newerNotificationsTriggerRef,
}) => {
  if (!notifications.length) {
    return loading
      ? (
        <Loader loading className={styles.loader} />
      )
      : (
        <NoNotifications />
      );
  }

  return (
    <>
      <NotificationsList notifications={notifications} />

      <div
        className={styles.loadMessageTrigger}
        ref={newerNotificationsTriggerRef}
      />

      {loading && (
        <Loader loading className={styles.loader} />
      )}
    </>
  );
};
