import React from 'react';
import { Button, ButtonMode, ButtonStyling } from '@/components/ui/Button';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { useReadAllNotifications } from '@/components/platform/Notifications/hooks/useReadAllNotifications';
import styles from '@/components/platform/Notifications/components/NotificationsContent/NotificationsContent.module.scss';

export const MarkNotificationsAsReadButton = () => {
  const { t } = useTranslation();

  const {
    readAllUserNotifications,
    readAllNotificationsLoading,
  } = useReadAllNotifications();

  return (
    <Button
      data-qa='notifications-mark-all-as-read-button'
      text={t((`${I18N_CODES.platformNotifications}:mark_all_as_read_button`))}
      mode={ButtonMode.TransparentLight}
      styling={ButtonStyling.Gray}
      onClick={readAllUserNotifications}
      isLoading={readAllNotificationsLoading}
      className={styles.resolveAllButton}
    />
  );
};
