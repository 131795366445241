import { UserNotificationsStatuses } from '@/controllers/graphql/generated';
import { isBrowser } from '@/middleware/helpers/isBrowser';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import { MESSAGE_TYPES } from '@/lib/constants/messages';
import { useNewNotificationSubscription } from '@/components/platform/Notifications/graphql/generated/newNotification.subscription.generated';

export const useNewNotification = () => {
  const [user] = useAuthUser({ ssr: true });
  const { language } = useSubDomainContext();
  const { showMessage } = useFlashMessage();

  useNewNotificationSubscription({
    skip: !isBrowser,
    variables: {
      userId: user?.id || 0,
      lang: language,
    },
    onData: ({
      client: { cache },
      data: { data },
    }) => {
      const receivedNotification = data?.newNotification;

      if (!receivedNotification) {
        return;
      }

      const cachedUserId = cache.identify({
        __typename: 'User',
        id: user?.id,
      });

      cache.modify({
        id: cachedUserId,
        fields: {
          notifications: (prevNotifications) => ({
            totalCount: prevNotifications.totalCount + 1,
            unreadCount: (
              receivedNotification.status === UserNotificationsStatuses.Unread
                ? prevNotifications.unreadCount + 1
                : prevNotifications.unreadCount
            ),
            edges: [receivedNotification, ...prevNotifications.edges],
          }),
        },
      });

      showMessage({
        type: MESSAGE_TYPES.info,
        text: data?.newNotification?.message,
      });
    },
  });
};
