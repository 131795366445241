export enum DOMEvent {
  Click = 'click',
  Resize = 'resize',
  Scroll = 'scroll',
  ContextMenu = 'contextmenu',
  KeyDown = 'keydown',
  Paste = 'paste',
  Focus = 'focus',
  Blur = 'blur',
}
