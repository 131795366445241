import { useCallback } from 'react';
import { useLocaleTimeAndDateString } from '@/hooks/useLocaleDateTimeString';
import { useLocaleDayAndMonth } from '@/hooks/useLocaleDayAndMonth';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';

export const useMessageSendingTimeRenderer = () => {
  const { t } = useTranslation([I18N_CODES.chat]);

  const getLocaleDayAndMonth = useLocaleDayAndMonth();
  const toLocaleTimeAndDate = useLocaleTimeAndDateString();

  const renderMessageSendingTime = useCallback((millis: number) => {
    const sendingTime = new Date(millis);

    const { time } = toLocaleTimeAndDate(sendingTime);

    const dayAndMonth = getLocaleDayAndMonth(
      sendingTime.getDate(),
      sendingTime.getMonth(),
    );

    return `${dayAndMonth} ${t(`${I18N_CODES.chat}:message_creating.at`)} ${time}`;
  }, [getLocaleDayAndMonth, toLocaleTimeAndDate, t]);

  return renderMessageSendingTime;
};
