import { AchievementIconLocked } from '@/components/platform/Achievements/constants';
import { LockedIconName } from '@/components/platform/Achievements/typedefs';
import { AchievementToDisplay } from '../typedefs';

export const pickRandomLockedAchievements = (
  count: number,
): AchievementToDisplay[] => (
  Object.values(LockedIconName)
    .filter((name) => name !== LockedIconName.Unknown)
    .sort(() => 0.5 - Math.random())
    .slice(0, count)
    .map((name) => ({
      customIcon: AchievementIconLocked[name],
      nameVisible: false,
    }))
);
