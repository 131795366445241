import { useUnreadChatMessagesCount } from '@/components/platform/Chat/hooks/useUnreadChatMessagesCount';
import { useChatMentionCountersQuery } from '@/components/platform/Chat/graphql/generated/chatMentionCounters.query.generated';

export const useChatUnreadsCounterData = () => {
  const { data: chatMentionCountersData } = useChatMentionCountersQuery({
    ssr: false,
  });

  const unreadMentionsCount = chatMentionCountersData?.chatMentionCounters
    .reduce((totalCount, { count }) => (
      totalCount + count
    ), 0) || 0;

  const {
    hasUnreadChatMessages,
    unreadThreadRepliesCount,
  } = useUnreadChatMessagesCount();

  const hasAnyUnreadMessages = (
    hasUnreadChatMessages || (unreadThreadRepliesCount > 0)
  );

  const notificationsCount = unreadMentionsCount + unreadThreadRepliesCount;

  return {
    hasAnyUnreadMessages,
    notificationsCount,
  };
};
