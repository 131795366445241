import {
  AttachmentTabs,
} from '@/components/platform/TestTasks/components/AttachmentFlow/MobileAttachmentFlowTabs/AttachmentTabs.constants';

export const attachmentTabURLToAttachmentTabs = (
  attachmentTab: string,
): AttachmentTabs => {
  switch (attachmentTab) {
    case AttachmentTabs.Solution:
      return AttachmentTabs.Solution;

    case AttachmentTabs.TaskDescription:
    default:
      return AttachmentTabs.TaskDescription;
  }
};
