import type { L10nsStrings } from '@/components/platform/common/HumanReadableDate/HumanReadableDate.typedefs';

const strings: L10nsStrings = {
  prefixAgo: null,
  prefixFromNow: 'in',
  suffixAgo: 'ago',
  suffixFromNow: null,
  seconds: '<1min',
  minute: '1min',
  minutes: `%dmin`,
  hour: '1h',
  hours: `%dh`,
  day: '1 day',
  days: `%d day`,
  month: '1mo',
  months: `%dmo`,
  year: '1y',
  years: `%dy`,
};

export default strings;
