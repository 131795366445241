import { AchievementItemHarness } from '@/components/platform/Achievements/AchievementItem.harness';
import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export enum LatestAchievementsHarnessSelectors {
  DataQa = 'achievements-block_latest-achievements',
}

export class LatestAchievementsHarness extends ComponentHarness {
  dataQa = LatestAchievementsHarnessSelectors.DataQa;

  get achievementItem() {
    return new AchievementItemHarness();
  }
}
