import React, { FC, useCallback, useEffect } from 'react';
import { Modal, PopUpTypeMode } from '@/components/ui/Modal';
import { useIsMobileWidth } from '@/hooks/useIsMobileWidth';
import { useGamificationModalStore } from '@/hooks/useGamificationModalStore';
import { useIsFeaturesEnabled } from '@/controllers/features/features.hooks/useIsFeaturesEnabled';
import { Features } from '@/controllers/features/features.constants';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { GamificationModalContent } from '@/components/ui/GamificationModal/components/GamificationModalContent';

export const GamificationModal: FC = () => {
  const {
    modals,
    removeModal,
    shouldRenderModals,
    setShouldRenderModals,
  } = useGamificationModalStore();

  const isFeatureEnabled = useIsFeaturesEnabled(
    Features.GamificationNotifyingMobileModals,
  );

  useTranslation([I18N_CODES.common, I18N_CODES.achievements]);

  const isMobile = useIsMobileWidth();

  useEffect(() => {
    setShouldRenderModals(isMobile);
  }, [isMobile, setShouldRenderModals]);

  const modalToShow = modals[0];

  const closeModal = useCallback(() => (
    modalToShow && removeModal(modalToShow)
  ), [modalToShow, removeModal]);

  if (!shouldRenderModals || !isFeatureEnabled) {
    return null;
  }

  return (
    <Modal
      isOpen={!!modalToShow}
      onRequestClose={closeModal}
      type={PopUpTypeMode.Default}
      paddingless
      overlayDisabled
      isFullScreen
      dataQa={`${modalToShow?.type}-modal`}
    >
      <GamificationModalContent
        modalToShow={modalToShow}
        onClose={closeModal}
      />
    </Modal>
  );
};
