import React from 'react';
import { FCIcon } from './typedefs';
import { BaseIcon } from './BaseIcon';

export const IconChevronUp: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.41 16.0001L12 11.4201L16.59 16.0001L18 14.5901L12 8.59009L6 14.5901L7.41 16.0001Z"
      fill="currentColor"
    />
  </BaseIcon>
);
