/* eslint-disable react/forbid-elements */
import React, { FC } from 'react';
import { cn } from '@/lib/classNames';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import styles from '../SidebarHeader.module.scss';

interface Props {
  url: string;
  size: number;
}

export const CurrentThingPlatformLogo: FC<Props> = ({ url, size }) => (
  <BaseIcon
    className={cn(styles.logoIcon)}
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <image href={url} width={size} height={size} />
  </BaseIcon>
);
