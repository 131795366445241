import React, { memo, useContext } from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { IconLike } from '@/components/ui/icons/IconLike';
import { LikeRatingContext } from '@/components/platform/Learn/pages/CourseTopic/components/LikeRating/LikeRatingContext';
import { RatingIcon } from '@/components/platform/Learn/pages/CourseTopic/components/LikeRating/RatingIcon';

export const LikeIcon: FCIcon = memo((props) => {
  const { rating } = useContext(LikeRatingContext);
  const isActive = !!rating && rating > 0;

  const dataQaAttribute = isActive
    ? 'like-icon-active'
    : 'like-icon';

  return (
    <RatingIcon
      {...props}
      Icon={IconLike}
      isActive={isActive}
      data-qa={dataQaAttribute}
    />
  );
});
