import { IconError } from '@/components/ui/icons/IconError';
import { IconStudentReview } from '@/components/ui/icons/IconStudentReview';
import { IconSuccess } from '@/components/ui/icons/IconSuccess';
import { IconTaskAIReview } from '@/components/ui/icons/IconTaskAIReview';
import { TaskReviewStatus } from '@/controllers/graphql/generated';

export const getReviewNotificationIcon = (
  taskReviewStatus: TaskReviewStatus,
) => {
  switch (taskReviewStatus) {
    case TaskReviewStatus.Rejected:
      return IconError;

    case TaskReviewStatus.ApprovedPeer:
    case TaskReviewStatus.RejectedPeer:
      return IconStudentReview;

    case TaskReviewStatus.RejectedAi:
    case TaskReviewStatus.ApprovedAi:
      return IconTaskAIReview;

    default:
      return IconSuccess;
  }
};
